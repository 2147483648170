import React from 'react';
import { useNavigate } from 'react-router';

const UserNotLoggedIn = () => {
	const navigate = useNavigate();
	return (
		<div className="ml-[32px] w-[503px] flex flex-col">
			<div className="mb-[20px]">
				<h1 className="mb-[20px] text-3xl font-bold leading-9 text-left font-noto-sans">To see and save all the information you will need to sign in/up</h1>

				<span className="text-base font-semibold leading-6 text-left font-noto-sans">We are happy to see you at our platform</span>
			</div>
			<div className="flex flex-col">
				<button className="max-w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px] p-[10px, 64px, 10px, 64px]" onClick={() => navigate('/login')}>
					Log In
				</button>

				<button className="max-w-[343px] h-[44px] border rounded-[30px] text-laykaPurple text-base font-bold leading-5 text-center font-noto-sans" onClick={() => navigate('/signup')}>
					Sign Up
				</button>
			</div>
		</div>
	);
};

export default UserNotLoggedIn;
