import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getImage } from '../../server/server';
import { useCart } from '../../hooks/useCart';
import { ReactComponent as TrashIcon } from '../../assets/icons/TrashIcon.svg';

interface SingleCartItemProps {
	item: {
		product: any;
		quantity: number;
		usedImages: any[];
		imagePreviewId: string;
		chosenDesign: any;
		design: any[];
	};
	index: number;
}

function SingleCartItem({ item, index }: SingleCartItemProps) {
	const { changeQuantity, removeFromCart } = useCart();
	const navigate = useNavigate();
	const product = item?.product ?? {};
	const soldIndividually = product?.commercialOptions?.soldIndividually;

	const openProduct = () => {
		if (!!product?.optionIds) navigate(`/product/${product.urlSlug}--${product?.optionIds?.join(',')}`);
		else navigate(`/product/${product.urlSlug}--${product?.id}?standalone=true&editCart=${index}`);
	};

	const designPrice = item?.chosenDesign?.price ?? 0;
	const disabledLowerQuantity = item.quantity == 1;
	const disabledHigherQuantity = soldIndividually ? item.quantity === 1 : item.quantity === product.commercialOptions.quantity;
	const manageStock = product?.commercialOptions?.manageStock;
	return (
		<div className="flex flex-row justify-between  bg-white p-10 rounded-[12px] mb-5">
			<div className="flex flex-row gap-5">
				<div className="w-[147px] h-[147px] bg-[#F1F1FE] rounded-[6px]">
					<img src={getImage(item?.imagePreviewId) ?? product.thumbnailId} className="w-[147px] h-[147px] object-cover rounded-md" alt={product?.name} title={product?.name} />
				</div>
				<div className="flex flex-col justify-center">
					<p className="text-[24px] leading-[30px] font-normal text-black ">{product?.name}</p>
					<div className="flex flex-row">
						<div className="flex flex-col">
							{!!product?.mappedGroups?.length && (
								<div className="mt-[10px]">
									{product?.mappedGroups?.map((group: any) => (
										<p className="text-[16px] leading-[24px] font-semibold text-black">
											{group.name}: {group.options[0].name}
										</p>
									))}
								</div>
							)}
							<div className="flex flex-row items-center gap-[20px] mt-[10px]">
								<p className="text-[16px] leading-[24px] font-bold text-black ">
									{(parseFloat(product?.commercialOptions?.price) + designPrice).toFixed(2)} <span className="lowercase">{product?.zone?.currency?.symbol}</span>
								</p>
								<div className="flex flex-row items-center gap-[5px]">
									<span className="text-[16px]">Qty:</span>
									<div className="flex flex-row gap-[10px] px-[14px] h-[40px] bg-[#F1F1FE] items-center rounded-full">
										<button onClick={() => changeQuantity(index, -1)} disabled={disabledLowerQuantity}>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_2717_2039)">
													<path d="M5 12H19" stroke={disabledLowerQuantity ? '#E5DDF6' : '#F89DBD'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												</g>
												<defs>
													<clipPath id="clip0_2717_2039">
														<rect width="24" height="24" fill="white" />
													</clipPath>
												</defs>
											</svg>
										</button>
										<span>{item.quantity}</span>
										<button onClick={() => changeQuantity(index, 1)} disabled={disabledHigherQuantity && manageStock}>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_2717_2043)">
													<path d="M12 5V19M5 12H19" stroke={disabledHigherQuantity ? '#E5DDF6' : '#F89DBD'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												</g>
												<defs>
													<clipPath id="clip0_2717_2043">
														<rect width="24" height="24" fill="white" />
													</clipPath>
												</defs>
											</svg>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-1"></div>
					<button className="text-[16px] leading-[20px] font-semibold text-[#21187F] mt-[20px] text-left" onClick={openProduct}>
						Edit
					</button>
				</div>
			</div>
			<div className="flex flex-col items-center justify-center">
				<div className="p-[13px] cursor-pointer hover:bg-pink rounded-full duration-[230ms] ease-in-out transition-all" onClick={() => removeFromCart(index)}>
					<TrashIcon />
				</div>
			</div>
		</div>
	);
}

export default SingleCartItem;
