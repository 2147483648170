import React from 'react';
import { ReactComponent as DraftRocket } from '../../assets/icons/DraftRocket.svg';
import { Link } from 'react-router-dom';

function EmptyDraft() {
	return (
		<div className="w-full flex flex-col items-center mt-[16px] mb-[230px]">
			<div className="w-[420px] flex flex-col items-center ">
				<DraftRocket width={250} height={218} />
				<p className="text-[30px] leading-[36px] font-bold text-black mt-10 text-center px-5">There is no saved products yet</p>
				<p className="text-[16px] leading-[24px] font-normal text-black text-center mt-5 max-w-[348px]">How about starting to create your masterpiece?</p>
				<Link to={'/'} className="w-full max-w-[343px] h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple text-[16px] text-white font-bold mt-[30px] uppercase">
					Explore products
				</Link>
			</div>
		</div>
	);
}

export default EmptyDraft;
