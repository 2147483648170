import React from 'react';
import Loading from '../../../common/Loading';
import { ReactComponent as SaveToDraft } from '../../../assets/icons/saveToDraft.svg';

function SaveDraftButton({ onClick = () => {}, disabled = false, inProgress = false }: { onClick: () => void; disabled: boolean; inProgress?: boolean }) {
	return (
		<button className="absolute bg-[#FFFFFFA6] px-[20px] h-[40px] flex flex-row items-center justify-center gap-[8px] rounded-full bottom-[30px] right-[30px] z-[2]" disabled={disabled || inProgress} onClick={onClick}>
			{inProgress ? <Loading height={20} color="#0F2765" secondaryColor="#f89dbd" /> : <SaveToDraft />}
			<span className="font-[400] text-[14px]">Save to My Draft</span>
		</button>
	);
}

export default SaveDraftButton;
