import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ReactComponent as SmallStarLayka } from '../../../assets/icons/SmallStarLayka.svg';
import { ReactComponent as GiftIcon } from '../../../assets/icons/GiftIcon.svg';
import NasDomInstruction from '../../../assets/images/NasDomInstruction.webp';
import CasaInstruction from '../../../assets/images/CasaInstruction.webp';
import NewYearInstruction from '../../../assets/images/NewYearInstruction.webp';
import { useNavigate } from 'react-router-dom';

function Instructions({ closeModal }: { closeModal: any }) {
	const [selectedInstruction, setSelectedInstruction] = useState(0);
	const navigation = useNavigate();
	const handleNext = () => {
		setSelectedInstruction((prev) => prev + 1);
	};
	const handleBack = () => {
		setSelectedInstruction((prev) => prev - 1);
	};

	const exploreLayka = () => {
		navigation('/');
	};

	const isLastInstruction = instructions.length - 1 == selectedInstruction;
	return (
		<div className="flex items-center justify-center absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-[#21187F22]" onClick={() => closeModal()}>
			<div
				className="bg-laykaDarkPurple rounded-[12px] w-[1062px] h-[676px]"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div className="flex flex-col items-center justify-end w-full h-full rounded-xl p-[60px] pt-[30px]">
					<div className="w-[326px] h-[368px] flex items-center justify-center  mb-8 transition-all ease-in-out duration-[230ms]">{instructions[selectedInstruction].logo}</div>
					{!isLastInstruction && (
						<div className="w-full max-w-[430px] flex flex-col items-center gap-5 mb-[60px]">
							<p className="text-[30px] leading-[36px] text-white  font-bold  text-center transition-all ease-in-out duration-[230ms]">{instructions[selectedInstruction].title}</p>
							<p className="text-[16px] leading-[24px] text-white  font-normal text-center transition-all ease-in-out duration-[230ms]">{instructions[selectedInstruction].subtitle}</p>
						</div>
					)}
					{isLastInstruction && (
						<div className="mb-[60px] -mt-[60]">
							<button className="h-[44px] rounded-full items-center flex justify-center px-[108px] border border-white text-white font-bold" onClick={exploreLayka}>
								EXPLORE LAYKA
							</button>
						</div>
					)}
					<div className="w-full flex flex-row items-center justify-between">
						<p className={`text-[16px] leading-[24px] text-pink  font-medium uppercase cursor-pointer ${selectedInstruction === 0 ? 'invisible' : ''}`} onClick={() => handleBack()}>
							Back
						</p>
						<div className="flex flex-row items-center gap-[10px]">
							{instructions.map((item: any, index: number) => (
								<div className={`transition-all ease-in-out duration-[230ms] ${selectedInstruction === index ? 'scale-125' : ''} `} key={index}>
									<SmallStarLayka fill={selectedInstruction === index ? ' #F89DBD' : '#E5DDF6'} />
								</div>
							))}
						</div>
						<p className={`text-[16px] leading-[24px] text-white  font-medium uppercase cursor-pointer ${selectedInstruction === instructions.length - 1 ? 'invisible' : ''}`} onClick={() => handleNext()}>
							Next
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Instructions;

const instructions = [
	{
		logo: <Logo height={103} width={260} />,
		title: 'Dobrodošli u svet Layke!',
		subtitle: 'Radujemo se zajedničkoj saradnji na pravljenju proizvoda za vas i vama dragim ljudima!',
	},
	{
		logo: <img src={NasDomInstruction} alt="" />,
		title: 'Dobrodošli u svet Layke!',
		subtitle: 'Radujemo se zajedničkoj saradnji na pravljenju proizvoda za vas i vama dragim ljudima!',
	},
	{
		logo: <img src={CasaInstruction} alt="" />,
		title: 'Dobrodošli u svet Layke!',
		subtitle: 'Radujemo se zajedničkoj saradnji na pravljenju proizvoda za vas i vama dragim ljudima!',
	},
	{
		logo: <img src={NewYearInstruction} alt="" />,
		title: 'Dobrodošli u svet Layke!',
		subtitle: 'Radujemo se zajedničkoj saradnji na pravljenju proizvoda za vas i vama dragim ljudima!',
	},
	{
		logo: <GiftIcon />,
		title: '',
		subtitle: '',
	},
];
