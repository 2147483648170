import React from 'react';
import { ReactComponent as BackIcon } from '../../../assets/icons/backIcon.svg';

function BackButton({ goBack }: { goBack: () => void }) {
	return (
		<button className="absolute bg-[#FFFFFFA6] w-[92px] h-[44px] flex flex-row items-center justify-center gap-[10px] rounded-full top-[40px] left-[30px] z-[2]" onClick={goBack}>
			<BackIcon />
			<span className="font-semibold text-[16px]">Back</span>
		</button>
	);
}

export default BackButton;
