import React, { useLayoutEffect } from 'react';
import { ReactComponent as DecorationLeft } from '../../assets/icons/AboutDecorationLeft.svg';
import { ReactComponent as DecorationRight } from '../../assets/icons/AboutDecorationRight.svg';
import '../../assets/style/About.css';
import { Link } from 'react-router-dom';
import SEO from '../seo/seo';

function AboutUs() {
	useLayoutEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
	});

	return (
		<div>
			<SEO title="O Nama - Layka" />
			<div className="h-[240px] bg-[#F1F1FE] flex items-center ">
				<div className="justify-between items-center flex flex-row max-w-[1280px] w-full px-[20px] mx-auto">
					<DecorationLeft />
					<p className="self-center text-[40px] sm:text-[70px] font-bold text-center transition-all ease-in-out">O Nama</p>
					<DecorationRight />
				</div>
			</div>
			<div>
				<div className="max-w-[1280px] w-full px-[20px] mx-auto py-[80px] terms-of-use">
					<h2 className="subtitle">KONTAKT</h2>
					<p className="paragraph">
						AIO PRO d.o.o.,
						<br /> Beograd Despotovacka 56b <br />
						Mat.br. 21623652
						<br />
						PIB: 112186734
						<br />
						Adresa za izjavljivanje reklamacije je: <a href="mailto:office@laykagift.com">office@laykagift.com</a>
					</p>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;
