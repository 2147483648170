import React, { useEffect, useState } from 'react';
import InitButtons from './MakeYourOwnComponents/InitButtons';
import EditText from './MakeYourOwnComponents/EditText';
import { getFontsForProduct } from '../../server/server';
import { FONTS } from '../../Constants';
import AddSymbols from './MakeYourOwnComponents/AddSymbols';

type MakeYourOwnType = {
	addText: any;
	addSymbol?: any;
	selectedObject: any;
	canvas: fabric.Canvas;
	productId?: string;
	fitText: any;
	loading?: boolean;
	renderAll: () => void;
	removeObject: (object?: any) => void;
	addPhoto: (id: string) => void;
	engravingColor: string | null;
	allowedCustomization: {
		text: boolean;
		image: boolean;
		symbol: boolean;
		allowPersonalization: boolean;
	};
};

function MakeYourOwn({ addText, selectedObject, canvas, fitText, productId, renderAll, loading, removeObject, addSymbol, engravingColor, addPhoto, allowedCustomization }: MakeYourOwnType) {
	const [selectedState, setSelectedState] = useState('init');
	const [allowedFonts, setAllowedFonts] = useState<any[]>([]);

	useEffect(() => {
		if (!!selectedObject && selectedObject.type === 'textbox') {
			setSelectedState('editText');
		} else if (!selectedObject) {
			setSelectedState('init');
		}
	}, [selectedObject]);

	useEffect(() => {
		if (!loading) loadAllowedFonts();
	}, [loading]);

	const loadAllowedFonts = async () => {
		const resp = await getFontsForProduct(productId);
		if (resp.status === 200) {
			setAllowedFonts(resp.data);
		}
	};

	const openInit = () => {
		setSelectedState('init');
		canvas.discardActiveObject();
		canvas.renderAll();
	};

	const onAddText = () => addText({ fontFamily: allowedFonts?.length > 0 ? allowedFonts[0].name : FONTS[0] });
	const openSymbolsSection = () => setSelectedState(selectedState === 'addSymbol' ? 'init' : 'addSymbol');
	const onAddSymbol = (value: any) => {
		addSymbol(value);
		setSelectedState('init');
	};

	return (
		<div>
			{['init', 'addSymbol'].includes(selectedState) && <InitButtons addText={onAddText} selectedState={selectedState} openSymbols={openSymbolsSection} addPhoto={addPhoto} allowedCustomization={allowedCustomization} />}
			{selectedState === 'editText' && <EditText addText={onAddText} onBack={openInit} fitText={fitText} renderAll={renderAll} selectedObject={selectedObject} productId={productId} allowedFonts={allowedFonts} removeObject={removeObject} engravingColor={engravingColor} />}
			{selectedState === 'addSymbol' && <AddSymbols addSymbol={onAddSymbol} productId={productId} />}
		</div>
	);
}

export default MakeYourOwn;
