import { Autocomplete, MenuItem, TextField, Typography, createFilterOptions } from '@mui/material';
import IosSwitchMaterialUi from 'ios-switch-material-ui';
import React, { useEffect, useState } from 'react';
import { createAddress, getAddressById, getCountries, getCustomerByUserId } from '../../../../server/server';
import { Address, BillingAddress, City, Country, Municipality, Region } from '../orders/OrderInterfaces';
import { ListboxComponent, StyledPopper } from '../../utils/ListBoxUtils';
import { useNavigate } from 'react-router';
import { useUser } from '../../../../auth/UseUser';
import { useSearchParams } from 'react-router-dom';
import { validatePhoneNumber } from '../../../../util/util';
import { useAppContext } from '../../../../hooks/useAppContext';

const AddNewAddress = () => {
	const [searchParams] = useSearchParams();
	const [address, setAddress] = useState<Address>();
	const [editedAddress, setEditedAddress] = useState<Address | undefined>({} as Address);
	const [knobPosition, setKnobPosition] = useState(false);
	const { zone } = useAppContext();
	const [countries, setCountries] = useState<Country[]>(zone.countries);
	const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
	const searchId = searchParams.get('id');

	const navigate = useNavigate();

	const { user, setUser, isSignedIn } = useUser();

	const [formValid, setFormValid] = useState({
		firstNameValid: true,
		lastNameValid: true,
		streetValid: true,
		countryValid: true,
		cityValid: true,
		phoneValid: true,
		phonePatternValid: true,
		zipValid: true,
	});

	const [isLoading, setIsLoading] = useState(false);
	const populateAddress = async (id: any) => {
		const resp = await getAddressById(id);
		if (resp.status == 200) {
			setEditedAddress(resp.data);
			setSelectedCountry(resp.data.country);
		}
	};

	useEffect(() => {
		setIsLoading(true);

		if (searchParams.get('id')) {
			populateAddress(searchParams.get('id'));
		}
	}, []);

	const handleSaveClick = async () => {
		const validations = validateForm();
		setFormValid(validations);

		if (isFormValid(validations)) {
			let editedAddressForDatabase: any = {
				firstName: editedAddress?.firstName,
				lastName: editedAddress?.lastName,
				street: editedAddress?.street,
				countryId: editedAddress?.country?.id,
				city: editedAddress?.city,
				zip: editedAddress?.zip,
				phone: editedAddress?.phone,
			};
			const id = searchParams.get('id');
			if (id) {
				editedAddressForDatabase.id = id;
			}

			const resp = await createAddress(editedAddressForDatabase);

			if (resp.status === 201) {
				alert(`Address ${id ? 'edited' : 'created'} successfully`);
				navigate('/profile/addresses');
			} else {
				alert(`Error updating address: ${resp.status}`);
			}
		}
	};

	const validateForm = () => {
		return {
			firstNameValid: !!editedAddress?.firstName,
			lastNameValid: !!editedAddress?.lastName,
			streetValid: !!editedAddress?.street,
			countryValid: !!editedAddress?.country?.id,
			cityValid: !!editedAddress?.city,
			phoneValid: !!editedAddress?.phone,
			zipValid: !!editedAddress?.zip,
			phonePatternValid: validatePhoneNumber(editedAddress?.phone),
		};
	};

	const isFormValid = (validations: any) => {
		return Object.values(validations).every(Boolean);
	};

	const handleTextFieldChange = (field: keyof Address, value: string) => {
		setEditedAddress((prevAddress: Address | undefined) => ({
			...prevAddress!,
			[field]: value,
		}));
	};

	const handleCountryChange = (event: any, value: any) => {
		if (value) {
			const selectedCountry = countries.find((country) => country.id === value.id);
			if (selectedCountry) {
				setSelectedCountry(selectedCountry);
				setEditedAddress((prevData) => ({
					...prevData!,
					country: selectedCountry,
				}));
			}
		}
	};

	const getPhoneErrorMessage = (formValid: any) => {
		if (!formValid.phoneValid) {
			return 'Your phone number is required';
		} else if (!formValid.phonePatternValid) {
			return 'Your phone number is not valid';
		}
		return '';
	};

	return (
		<div>
			<div>
				<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px] w-[522px]">{searchId ? 'Edit' : 'Add new'} address</h4>
			</div>
			<div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="First name *"
						name="firstName"
						value={editedAddress?.firstName}
						onChange={(e) => handleTextFieldChange('firstName', e.target.value)}
						error={!formValid.firstNameValid}
						helperText={!formValid.firstNameValid && 'Your first name is required'}
						InputLabelProps={{ shrink: !!editedAddress?.firstName }}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="Last name *"
						name="lastName"
						value={editedAddress?.lastName}
						onChange={(e) => handleTextFieldChange('lastName', e.target.value)}
						error={!formValid.lastNameValid}
						helperText={!formValid.lastNameValid && 'Your last name is required'}
						InputLabelProps={{ shrink: !!editedAddress?.lastName }}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="Address *"
						name="address"
						value={editedAddress?.street}
						onChange={(e) => handleTextFieldChange('street', e.target.value)}
						error={!formValid.streetValid}
						helperText={!formValid.streetValid && 'Your address is required'}
						InputLabelProps={{ shrink: !!editedAddress?.street }}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<Autocomplete
						className="w-[522px]"
						value={selectedCountry}
						onChange={handleCountryChange}
						id="country-select"
						options={countries || []}
						getOptionLabel={(option) => option?.name || ''}
						renderInput={(params) => <TextField {...params} label="Select country *" error={!formValid.countryValid} helperText={!formValid.countryValid && 'Country is required'} variant="standard" />}
					/>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="City *"
						name="city"
						value={editedAddress?.city}
						onChange={(e) => handleTextFieldChange('city', e.target.value)}
						error={!formValid.cityValid}
						helperText={!formValid.cityValid && 'City is required'}
						InputLabelProps={{ shrink: !!editedAddress?.city }}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="Zip code *"
						name="zipCode"
						value={editedAddress?.zip}
						onChange={(e) => handleTextFieldChange('zip', e.target.value)}
						error={!formValid.zipValid}
						helperText={!formValid.zipValid && 'Zip is required'}
						InputLabelProps={{ shrink: !!editedAddress?.zip }}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="w-[522px]"
						type="text"
						label="Phone number *"
						name="phone"
						value={editedAddress?.phone}
						onChange={(e) => handleTextFieldChange('phone', e.target.value)}
						error={!formValid.phoneValid || !formValid.phonePatternValid}
						helperText={getPhoneErrorMessage(formValid)}
						InputLabelProps={{ shrink: !!editedAddress?.phone }}
						variant="standard"
					></TextField>
				</div>
				<div>
					<button className="w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px]" onClick={handleSaveClick}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddNewAddress;
