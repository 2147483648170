import React, { useEffect, useState } from 'react';
import ScrollingBanner from '../../common/ScrollingBanner';
import FooterBanner from '../../common/FooterBanner';
import EmptyCart from './EmptyCart';
import CartList from './CartList';
import GreetingModal from './GreetingModal';
import { useUser } from '../../auth/UseUser';
import { useCart } from '../../hooks/useCart';
import { useNavigate } from 'react-router-dom';

function CartSuccess() {
	const { isSignedIn } = useUser();
	const navigate = useNavigate();
	const pathname = window.location.pathname.slice(1);
	const [modalOpen, setOpenModal] = useState(false);
	const { emptyCart } = useCart();

	useEffect(() => {
		setOpenModal(true);
		setTimeout(() => {
			closeModal();
		}, 2000);
	}, [])


	const closeModal = () => {
		setOpenModal(false);
		emptyCart();
		navigate('/');
	};
	return (
		<div className=" w-full  flex flex-col bg-[#F1F1FE]">
			<EmptyCart />
			<GreetingModal modalIsOpen={modalOpen} closeModal={closeModal} />
		</div>
	);
}

export default CartSuccess;
