import { Dialog, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CloseIcon } from '../../SVG/SelectedIcons';

interface HelpModalProps {
	open: boolean;
	onClose: () => void;
}

const MessageModal: React.FC<HelpModalProps> = ({ open, onClose }) => {
	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		reason: '',
		message: '',
	});

	const [validation, setValidation] = useState({
		fullName: true,
		email: true,
		message: true,
	});

	const handleClose = () => {
		setValidation({
			fullName: true,
			email: true,
			message: true,
		});

		setFormData({
			fullName: '',
			email: '',
			reason: '',
			message: '',
		});
		onClose();
	};

	const isEmailValid = (email: any) => {
		// Email format validation using a regular expression
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSend = async () => {

		if (!formData.fullName.trim()) {
			setValidation((prevValidation) => ({ ...prevValidation, fullName: false }));
			return;
		}

		if (!formData.email.trim() || !isEmailValid(formData.email.trim())) {
			setValidation((prevValidation) => ({ ...prevValidation, email: false }));
			return;
		}

		if (!formData.message.trim()) {
			setValidation((prevValidation) => ({ ...prevValidation, message: false }));
			return;
		}

		setValidation({
			fullName: true,
			email: true,
			message: true,
		});

		// Make a request to your backend to send the email
		try {
			const response = await fetch('/api/send-email', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				console.log('Email sent successfully');
			} else {
				console.error('Failed to send email');
			}
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};

	const handleFieldChange = (fieldName: any, value: any) => {
		// Reset validation for the specific field on change
		setValidation((prevValidation) => ({
			...prevValidation,
			[fieldName]: true,
		}));

		// Update form data
		setFormData((prevData) => ({
			...prevData,
			[fieldName]: value,
		}));
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<div className="py-[60px] px-[40px]">
				<div className="flex justify-end">
					<CloseIcon onClick={handleClose} />
				</div>
				<div className="flex items-center justify-center">
					<h4 className="text-center w-full font-extrabold text-4xl">Tell us more</h4>
				</div>

				<div className="my-[20px]">
					<TextField
						className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
						type="text"
						label="Full name *"
						value={formData.fullName}
						helperText={!validation.fullName && 'Your full name is required.'}
						InputLabelProps={{ shrink: !!formData.fullName }}
						error={!validation.fullName}
						onChange={(e) => handleFieldChange('fullName', e.target.value)}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
						type="text"
						label="Email *"
						error={!validation.email}
						value={formData.email}
						helperText={!validation.email && 'Email is required and must be in correct format.'}
						InputLabelProps={{ shrink: !!formData.email }}
						onChange={(e) => handleFieldChange('email', e.target.value)}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
						type="text"
						label="Select a reason"
						value={formData.reason}
						InputLabelProps={{ shrink: !!formData.reason }}
						onChange={(e) =>
							setFormData((prevData) => ({
								...prevData,
								reason: e.target.value,
							}))
						}
						variant="standard"
					></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField
						className="xxs:w-[120px] xs:w-[260px] md:w-[522px] "
						multiline
						rows={5}
						maxRows={10}
						error={!validation.message}
						helperText={!validation.message && 'Message is required'}
						type="text"
						label="Your Message *"
						value={formData.message}
						InputLabelProps={{ shrink: !!formData.message }}
						onChange={(e) => handleFieldChange('message', e.target.value)}
						variant="standard"
					></TextField>
				</div>
				<div className="mt-[40px]  md:w-[522px] flex items-center justify-center">
					<button className="w-[130px] md:w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px]" onClick={handleSend}>
						Send
					</button>
				</div>
			</div>
		</Dialog>
	);
};

export default MessageModal;
