import React, { useState } from 'react';
import { SelectedAddressesIcon, SelectedHelpIcon, SelectedOrdersIcon, SelectedPaymentMethodIcon, SelectedProfileIcon } from './SVG/SelectedIcons';
import { HeartIcon, HelpIcon, MyAddressesIcon, MyOrdersIcon, MyPaymentMethodsIcon, MyProfileIcon, SignOut } from './SVG/UnselectedIcons';
import { useUser } from '../../auth/UseUser';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

const ProfileNavigation = () => {
	const [selectedLink, setSelectedLink] = useState('/profile/data');
	const { isSignedIn, setUser } = useUser();
	const navigate = useNavigate();
	const location = useLocation();

	const logout = () => {
		localStorage.clear();
		setUser({});
		navigate('/login');
	};
	return (
		<div>
			<div className="bg-[#F1F1FE]">
				<div className="max-w-[1280px] h-full py-[60px] flex flex-col">
					<div className="text-[14px] mb-[30px] lg:ml-[40px] uppercase">Home / My profile</div>
					<div className="flex flex-row items-start">
						{/* <div className="rounded-[12px] xxs:max-h-[370px] xs:max-h-[375px] mb:max-h-[344px] py-[20px] px-[10px] gap-[16px] bg-white xxs:max-w-[150px] xs:max-w-[260px] md:max-w-[306px]"> */}
						<div className="rounded-[12px] py-[10px] px-[20px] gap-[16px] bg-white  min-w-[306px] max-w-[306px] lg:ml-[40px]">
							<nav>
								<ul>
									<li className=" h-[56px] flex flex-row  border-b border-laykaLight cursor-pointer items-center" onClick={() => navigate('/profile/user')}>
										{location.pathname === '/profile/user' || location.pathname === '/profile/passwordchange' ? <SelectedProfileIcon /> : <MyProfileIcon />}
										<p className={`ml-[8px] font-normal  text-base leading-6 text-left no-underline ${location.pathname === '/profile/user' || location.pathname === '/profile/passwordchange' ? 'text-laykaDark' : 'text-black'}`}>My Data</p>
									</li>

									<li className=" h-[56px] flex flex-row  border-b border-laykaLight cursor-pointer items-center" onClick={() => navigate('/profile/orders')}>
										{location.pathname === '/profile/orders' || location.pathname === '/profile/order' ? <SelectedOrdersIcon /> : <MyOrdersIcon />}
										<p className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${location.pathname === '/profile/orders' || location.pathname === '/profile/order' ? 'text-laykaDark' : 'text-black'}`}>My Orders</p>
									</li>
									<li className="  h-[56px] flex flex-row  border-b border-laykaLight cursor-pointer items-center" onClick={() => navigate('/profile/addresses')}>
										{location.pathname === '/profile/addresses' || location.pathname === '/profile/newaddress' || location.pathname === '/profile/editaddress' ? <SelectedAddressesIcon /> : <MyAddressesIcon />}
										<p className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${location.pathname === '/profile/addresses' || location.pathname === '/profile/newaddress' || location.pathname === '/profile/editaddress' ? 'text-laykaDark' : 'text-black'}`}>
											My Addresses
										</p>
									</li>
									<li className="  h-[56px]  flex flex-row   border-b border-laykaLight cursor-pointer items-center" onClick={() => navigate('/profile/help')}>
										{location.pathname === '/profile/help' ? <SelectedHelpIcon /> : <HelpIcon />}
										<p className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${location.pathname === '/profile/help' ? 'text-laykaDark' : 'text-black'}`}>Help & Contact</p>
									</li>
									{/* <li className="  h-[56px]  flex flex-row   border-b border-laykaLight cursor-pointer items-center" onClick={() => navigate('/profile/paymentmethods')}>
										{location.pathname === '/profile/paymentmethods' || location.pathname === '/profile/newpaymentmethod' || location.pathname === '/profile/editpaymentmethod' ? <SelectedPaymentMethodIcon /> : <MyPaymentMethodsIcon />}
										<p
											className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${
												location.pathname === '/profile/paymentmethods' || location.pathname === '/profile/newpaymentmethod' || location.pathname === '/profile/editpaymentmethod' ? 'text-laykaDark' : 'text-black'
											}`}
										>
											My Payment Methods
										</p>
									</li> */}
									<li className="  h-[56px]  flex flex-row  cursor-pointer items-center" onClick={() => setSelectedLink('/profile/layka')}>
										<HeartIcon />
										<p className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${selectedLink.includes('profile/layka') ? 'text-laykaDark' : 'text-black'}`}>#LAYKAFAM</p>
									</li>
									{isSignedIn && (
										<li className="  h-[56px]  flex flex-row  cursor-pointer border-t border-laykaLight items-center" onClick={logout}>
											<SignOut />
											<p className={`ml-[8px]  font-normal text-base leading-6 text-left no-underline ${selectedLink.includes('profile/layka') ? 'text-laykaDark' : 'text-black'}`}>Sign out</p>
										</li>
									)}
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileNavigation;
