import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import SignInPhoto from '../../assets/images/SignIn.webp';
import SignUpPhoto from '../../assets/images/SignUp.webp';
import { useState } from 'react';
import Country from './login-screens/Country';
import InstructionsModal from './login-screens/InstructionsModal';
import Instructions from './login-screens/Instructions';
import { getShippingZones } from '../../server/server';

export default function CountryScreen() {
	let navigate = useNavigate();
	const { setUser } = useUser();
	const [selectedMode, setSelectedMode] = useState('login');
	const [selectedImage, setSelectedImage] = useState(SignInPhoto);
	const [modalOpen, setModalOpen] = useState(false);
	const [zones, setZones] = useState<any>({});

	const handleImage = (mode: string) => {
		setSelectedMode(mode);
		if (mode === 'login' || mode === 'country') setSelectedImage(SignInPhoto);
		else if (mode === 'registration') setSelectedImage(SignUpPhoto);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	const openModal = () => {
		setModalOpen(true);
	};

	// React.useEffect(() => {
	// 	// containerRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
	// }, [selectedMode]);

	return (
		<div className=" w-full h-[100dvh]   grid grid-cols-1 xl:grid-cols-2   bg-white relative">
			<div className=" bg-white hidden xl:flex">
				<img src={SignInPhoto} alt="" className="w-full  object-cover h-full " />
			</div>
			<div className=" flex items-stretch min-h-0 py-10 xl:py-0">
				<Country setSelectedMode={handleImage} openModal={openModal} />
			</div>

			{modalOpen && <Instructions closeModal={closeModal} />}
		</div>
	);
}
