import React from 'react';
import { FONTS } from '../Constants';

function FontLoader() {
	return (
		<span className="opacity-0 h-0 w-0">
			{FONTS.map((e) => (
				<span style={{ fontFamily: e }} key={e} />
			))}
		</span>
	);
}

export default FontLoader;
