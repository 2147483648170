import React, { useLayoutEffect } from 'react';
import { ReactComponent as DecorationLeft } from '../../assets/icons/AboutDecorationLeft.svg';
import { ReactComponent as DecorationRight } from '../../assets/icons/AboutDecorationRight.svg';
import '../../assets/style/About.css';
import { Link } from 'react-router-dom';
import SEO from '../seo/seo';
const Obrazac = require('../../assets/files/Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf');

function Refund() {
	useLayoutEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
	});

	return (
		<div>
			<SEO title="Povraćaj sredstava - Layka" />
			<div className="h-[240px] bg-[#F1F1FE] flex items-center ">
				<div className="justify-between items-center flex flex-row max-w-[1280px] w-full px-[20px] mx-auto">
					<DecorationLeft />
					<p className="self-center text-[40px] sm:text-[70px] font-bold text-center transition-all ease-in-out">Povraćaj sredstava</p>
					<DecorationRight />
				</div>
			</div>
			<div>
				<div className="max-w-[1280px] w-full px-[20px] mx-auto py-[80px] terms-of-use">
					<p className="paragraph">
						Prilikom prijema pošiljke, molimo vas da pregledate vaš paket u prisustvu kurira. Ako postoje vidljivi znaci oštećenja (pocepani delovi ili udubljenja), ne bi trebalo da prihvatite paket. U tom slučaju, pošaljite nam e-mail sa vašim detaljima (ime, prezime, broj telefona) na{' '}
						<a href="mailto:office@laykagift.com">office@laykagift.com</a> и navedite razlog zašto ste odbili isporuku. Uskoro ćemo vas kontaktirati sa daljim instrukcijama.
						<br />
						<br />
						Ukoliko ste primili pošiljku i otkrili, po otvaranju paketa, da se isporučena roba ne podudara sa onima koji su poručeni ili da su detalji na fakturi/racunu netačni, molimo vas da nas kontaktirate u roku od 24 sata od prijema paketa na e-mail sa vašim detaljima (ime, prezime,
						broj telefona) na <a href="mailto:office@laykagift.com">office@laykagift.com</a> i opišu problem. Odgovorićemo poštom ili mejlom u najkraćem mogućem roku, u svakom slučaju najkasnije u roku od 8 dana od prijema reklamacije, i obavestiti vas o daljim koracima. Reklamacije
						treba da se reše u roku od 14 dana od dana prijema.
						<br />
						<br />
						Ukoliko se neusklađinost pojavljuje u smislu odredbi Zakona o zaštiti potrošača o kupljenom proizvodu, molimo vas da nas obavestitena <a href="mailto:office@laykagift.com">office@laykagift.com</a> ili kontakt telefonom <a href="tel:+381116355200">011 6355200</a>
						<br />
						<br />U slučaju vraćanja robe kupcu koji je predhodno robu platio kuriru tj. pouzećem, troškove vraćanja robe ne snosi AIO PRO d.o.o. već trošak pada na teret kupca.
						<br />
						<br />
						<b>NAPOMENA</b>: Potvrdom porudžbine, klikom na dugme ZAVRŠITE KUPOVINU, saglasni ste sa uslovima reklamacije.
					</p>
					<h2 className="subtitle">Pravo na odustajanje / povraćaj robe</h2>
					<p className="paragraph">
						Poštovani kupci, Obaveštavamo Vas da se prema Zakonu o zaštiti potrošača (Službeni glasnik RS, br. 62/2014), kupovina preko naše prodajne internet stranice www.laykagift.com smatra prodajom na daljinu.
						<br />
						<br />
						Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se smatra potrošačem (fizičko lice koje proizvod kupuje radi namirenja svojih individualnih potreba, a ne radi obavljanja profesionalne delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu je
						proizvod predat. Prilikom odustanka kupac može, ali ne mora da navede razloge zbog kojih odustaje.
						<br />
						<br />
						Obrazac/Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata trgovcu.
						<br />
						<br />
						Obrazac/izjavu o odustanku možete preuzeti
						<a href={Obrazac} target="_blank" rel="noreferrer" download={'Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf'}>
							<b>ovde</b>
						</a>
						. Nakon popunjavanja obrasca, isti je potrebno poslati skeniran na <a href="mailto:office@laykagift.com">office@laykagift.com</a>.
						<br />
						<br />
						U slučaju odustaka od ugovora, potrošač ima pravo na povraćaj novca ili na zamenu za drugi proizvod. Cena se kupcu vraća po prijemu proizvoda, i nakon što se utvrdi da je proizvod neoštećen i ispravan.
						<br />
						<br />
						Kupac je dužan da proizvod vrati bez odlaganja, a najkasnije u roku od <b>14 dana</b> od dana kada je poslao obrazac/izjavu o odustanku. Po isteku roka od 14 dana od dana kada je poslao odustanak, proizvod se više ne moze vratiti.
						<br />
						<br />
						Prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju, pri čemu se mora priložiti i račun-otpremnica koju je kupac prethodno dobio uz porudžbinu.
						<br />
						<br />
						Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Kupac odgovara za neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja proizvodom, tj. kupac je isključivo odgovoran za umanjenu vrednost proizvoda koja nastane kao posledica
						rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono što je neophodno da bi se ustanovili njegova priroda, karakteristike i funkcionalnost. Ukoliko se utvrdi da je nastupila neispravnost ili oštećenje proizvoda krivicom kupca, odbiće se vraćanje cene i
						proizvod će mu biti vraćen na njegov trošak.
						<br />
						<br />
						Trgovac je dužan da potrošaču bez odlaganja vrati iznos koji je potrošač platio po osnovu ugovora, a najkasnije u roku od 14 dana od dana prijema obrasca/izjave o odustanku, a nakon prijema proizvoda.
						<br />
						<br />
						Troškove vraćanja robe i novca – direktni troškovi, snosi kupac, sem u slučajevima kada kupac dobije neispravan ili pogrešan artikal.
						<br />
						<br />U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, laykagift.com je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači
						da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Refund;
