import React, { useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../../assets/icons/expandIcon.svg';
type Type = {
	title: string;
	subtitle?: string;
	initValue?: boolean;
	children?: React.ReactNode;
};

function ExpandedModule({ title, subtitle, initValue = false, children }: Type) {
	const [expanded, setExpanded] = useState(initValue);

	useEffect(() => {
		console.log(`change init value: ${initValue}`);
		setExpanded(initValue);
	}, [initValue]);

	return (
		<div>
			<button className="flex flex-row items-center justify-between w-full" onClick={() => setExpanded(!expanded)}>
				<label htmlFor={title + subtitle} className="text-[16px] font-bold cursor-pointer flex-1 text-left text-ellipsis overflow-hidden  whitespace-nowrap">
					{title}
					{!!subtitle && <span className="font-normal"> - {subtitle}</span>}
				</label>
				<div className={` ${expanded ? 'rotate-180' : ''}`}>
					<Arrow />
				</div>
			</button>
			{expanded && children}
		</div>
	);
}

export default ExpandedModule;
