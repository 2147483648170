import { FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, DateRangeIcon } from '@mui/x-date-pickers';
import { AnyARecord } from 'dns';
import React, { useEffect, useState } from 'react';
import { Dropdown } from '../SVG/UnselectedIcons';
import { DropdownActive } from '../SVG/SelectedIcons';
import Accounts from './Accounts/Accounts';
import { editCustomer, updateUser, whoAmI } from '../../../server/server';
import { useUser } from '../../../auth/UseUser';
import { useNavigate } from 'react-router';

export interface CustomerData {
	firstName: string;
	lastName: string;
	phone: string;
	birthdate: Date | null;
	gender: string;
	email: string;
}

const UserData = () => {
	const { user, setUser, isSignedIn } = useUser();
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(user?.birthdate));
	const [dateChanged, setDateChanged] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);

	const [accountsActive, setAccountsActive] = useState(false);
	const [updatedCustomerData, setUpdatedCustomerData] = useState<CustomerData>({
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		phone: user?.phone || '',
		birthdate: user?.birthdate || '',
		gender: user?.gender || '',
		email: user?.email || '',
	});

	const navigate = useNavigate();

	useEffect(() => {
		setUpdatedCustomerData({
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			phone: user?.phone || '',
			birthdate: user?.birthdate || '',
			gender: user?.gender || '',
			email: user?.email || '',
		});
	}, [user]);

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
		setUpdatedCustomerData((prevData) => ({
			...prevData,
			birthdate: date,
		}));
		setDateChanged(true);
	};

	const handleGenderChange = (selectedGender: string) => {
		setUpdatedCustomerData((prevData) => ({
			...prevData,
			gender: selectedGender,
		}));
	};

	const handleCustomerFieldUpdate = (fieldName: string, value: string) => {
		setUpdatedCustomerData((prevData) => ({
			...prevData,
			[fieldName]: value,
		}));
	};

	const formatDateToString = (date: Date | null): string => {
		if (!date) return ''; // Handle null or undefined dates as needed

		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
		const year = date.getFullYear();

		return `${year}/${month}/${day}`;
	};

	const handleSaveClick = async () => {
		let formattedDate = null;

		if (dateChanged && updatedCustomerData.birthdate) {
			formattedDate = updatedCustomerData.birthdate ? formatDateToString(updatedCustomerData.birthdate) : updatedCustomerData.birthdate;
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (emailRegex.test(updatedCustomerData.email)) {
			const payload = {
				email: updatedCustomerData.email,
				gender: updatedCustomerData.gender,
				birthDate: formattedDate,
				firstName: updatedCustomerData.firstName,
				lastName: updatedCustomerData.lastName,
				phone: updatedCustomerData.phone,
			};

			const resp = await updateUser(payload);

			if (resp.status == 201) {
				alert(`Information updated successfully!`);
				if (user.id) {
					const response = await whoAmI();
					if (response.status === 200) {
						const userData = { ...response.data };
						localStorage.setItem('user', JSON.stringify(userData));
						setUser(userData); // Update user context/state
					} else {
						console.log('who am i failed', response.status);
						// Handle failure (e.g., redirect to login or show error)
					}
				}
			} else if (resp.status == 409) {
				alert(`Email already in use`);
			} else {
				alert(`Error updating information:${resp.status}`);
			}
		} else {
			setAccountsActive(true);
			setInvalidEmail(true);
		}
	};

	const logout = () => {
		localStorage.clear();
		setUser({});
		navigate('/login');
	};

	return (
		<div className=" w-[503px]">
			<div>
				<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px]">My data</h4>
			</div>
			<div className="w-full">
				<div className="flex flex-col">
					<div className="mb-[20px]">
						<TextField
							className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
							type="text"
							label="Name *"
							value={updatedCustomerData?.firstName}
							InputLabelProps={{ shrink: !!updatedCustomerData?.firstName }}
							onChange={(e) => handleCustomerFieldUpdate('firstName', e.target.value)}
							variant="standard"
						></TextField>
					</div>
					<div className="mb-[20px]">
						<TextField
							className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
							type="text"
							label="Last name *"
							value={updatedCustomerData?.lastName}
							InputLabelProps={{ shrink: !!updatedCustomerData?.lastName }}
							onChange={(e) => handleCustomerFieldUpdate('lastName', e.target.value)}
							variant="standard"
						></TextField>
					</div>
					<div className="mb-[20px]">
						<TextField
							className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
							type="text"
							label="Phone"
							value={updatedCustomerData?.phone}
							InputLabelProps={{ shrink: !!updatedCustomerData?.phone }}
							onChange={(e) => handleCustomerFieldUpdate('phone', e.target.value)}
							variant="standard"
						></TextField>
					</div>
					<div className="mb-[20px]">
						<DatePicker slotProps={{ textField: { variant: 'standard' } }} className="xxs:w-[120px] xs:w-[260px] md:w-[522px]" label="Date of birth" value={updatedCustomerData.birthdate ? new Date(updatedCustomerData.birthdate) : null} onChange={handleDateChange} format="dd/MM/yyyy" />
					</div>
					<FormControl className="xxs:w-[120px] xs:w-[260px] md:w-[522px]" variant="standard">
						<InputLabel id="gender-label">Gender</InputLabel>
						<Select labelId="gender-label" id="gender-select" value={updatedCustomerData.gender || ''} onChange={(e) => handleGenderChange(e.target.value)}>
							<MenuItem value="male">Male</MenuItem>
							<MenuItem value="female">Female</MenuItem>
							<MenuItem value="other">Other</MenuItem>
						</Select>
					</FormControl>
					<div className="flex flex-row justify-between mt-[20px] xxs:w-[120px] xs:w-[260px] md:w-[522px] cursor-pointer" style={{ userSelect: 'none' }} onClick={() => setAccountsActive(!accountsActive)}>
						<p>Accounts</p>
						<div className="mt-[10px] mr-[5px]">{!accountsActive ? <Dropdown /> : <DropdownActive />}</div>
					</div>
					{accountsActive && (
						<div>
							<Accounts logout={logout} customer={user} updatedCustomerData={updatedCustomerData} setUpdatedCustomerData={setUpdatedCustomerData} invalidEmail={invalidEmail} />
						</div>
					)}
				</div>
			</div>
			<div className="mt-[20px]  md:w-[522px]">
				<button className="w-[130px] md:w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px]" onClick={handleSaveClick}>
					Save
				</button>
			</div>
		</div>
	);
};

export default UserData;
