import { MouseEventHandler } from 'react';

export const SelectedProfileIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.0857 21.5997C2.8087 20.7922 2.48006 18.9894 2.94955 17.5528C3.41903 16.1161 4.50824 14.98 5.60683 13.9471C7.7289 11.9565 10.0106 10.1349 12.4331 8.52927C12.1233 7.25227 11.8604 6.18185 11.5505 4.90485C13.513 3.96588 15.4003 2.84851 17.3627 1.90015"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.50391 21.0363C4.48982 22.1819 6.32081 22.2382 7.67293 21.5621C9.02505 20.8861 9.98279 19.6466 10.8466 18.4072C12.5086 16.0128 13.964 13.487 15.1941 10.8485C16.4992 10.9706 17.5978 11.0645 18.903 11.1865C19.5415 9.10201 20.3678 7.07384 21.0157 4.99872"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M17.3724 1.90015C16.6118 2.51048 15.87 3.19592 15.485 4.08794C15.1001 4.97997 15.1188 6.11612 15.7949 6.82034C16.5555 7.62786 17.8982 7.60908 18.8747 7.09265C19.8513 6.57621 20.5555 5.66541 21.2221 4.78278" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M6.79029 19.9189C8.19874 19.2053 6.81846 17.1302 5.53207 17.6936C3.93582 18.3884 5.36305 20.6419 6.79029 19.9189Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.1456 1.44958C17.2188 1.41418 17.2964 1.39825 17.3726 1.39975C17.5189 1.39986 17.6638 1.46386 17.7625 1.58684C17.9353 1.80221 17.9008 2.11691 17.6855 2.28974C16.9316 2.89469 16.2769 3.51503 15.9443 4.2857C15.6071 5.06687 15.6674 5.96499 16.1558 6.4737L16.159 6.47714L16.159 6.47716C16.7082 7.06025 17.7786 7.1064 18.6411 6.65027C19.3812 6.25887 19.959 5.60254 20.5358 4.85791L20.5384 4.84939C20.576 4.7291 20.6548 4.63309 20.7545 4.57208L20.8233 4.48105C20.9898 4.2607 21.3033 4.21699 21.5237 4.38343C21.744 4.54986 21.7877 4.86341 21.6213 5.08376C21.5606 5.16414 21.4989 5.24568 21.4362 5.32794C21.1336 6.28109 20.7965 7.22334 20.4627 8.15629L20.4266 8.25719C20.0588 9.28538 19.6965 10.3031 19.3811 11.3327C19.3117 11.5594 19.0925 11.7061 18.8565 11.684C18.2049 11.6231 17.6049 11.5692 17.0038 11.5152L17.0032 11.5151C16.5111 11.4709 16.0185 11.4267 15.4972 11.3785C14.2872 13.9278 12.8683 16.3713 11.2574 18.692L11.2569 18.6928C10.3941 19.9307 9.37395 21.2703 7.89657 22.009C6.75747 22.5786 5.24089 22.7056 4.06217 22.0989C3.97852 22.0949 3.89471 22.0699 3.81887 22.0219C2.28162 21.0499 1.96204 18.9658 2.47468 17.3971C2.9877 15.8272 4.16675 14.6148 5.26474 13.5825L5.26515 13.5821C7.32116 11.6534 9.52667 9.88105 11.8652 8.30724C11.7413 7.79769 11.6239 7.31656 11.5065 6.83521L11.5061 6.83362L11.5059 6.83293L11.5058 6.83249C11.3628 6.24657 11.2198 5.66024 11.065 5.02238C11.0093 4.79273 11.1219 4.55544 11.3351 4.45344C12.3045 3.98959 13.2573 3.48078 14.221 2.96484L14.2881 2.92894L14.2881 2.92893C15.2268 2.42637 16.1764 1.91792 17.1456 1.44958Z"
				fill="#21187F"
			/>
			<path d="M6.79029 19.9186C8.19874 19.205 6.81846 17.1298 5.53207 17.6932C3.93582 18.388 5.36305 20.6416 6.79029 19.9186Z" fill="#F1F1FE" stroke="#F1F1FE" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
};

export const SelectedOrdersIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1252_1381)">
				<g clip-path="url(#clip1_1252_1381)">
					<g clip-path="url(#clip2_1252_1381)">
						<g clip-path="url(#clip3_1252_1381)">
							<g clip-path="url(#clip4_1252_1381)">
								<path
									d="M4.20152 13.63C4.06152 12.94 3.24152 12.53 2.55152 12.7C1.86152 12.87 1.36152 13.48 1.15152 14.16C0.941525 14.84 0.981525 15.56 1.08152 16.25C1.19152 17.09 1.39152 17.94 1.87152 18.64C2.35152 19.34 3.18152 19.84 4.02152 19.74C4.91152 19.63 5.62152 18.86 5.92153 18.01C6.22153 17.16 6.20152 16.23 6.18152 15.33C6.07152 11.23 5.95153 7.14001 5.84153 3.04001C10.4015 2.94001 14.8815 2.86001 19.4415 2.76001C19.5015 7.88001 19.5315 13.11 19.5815 18.24C17.8015 18.9 15.9315 19.39 14.1515 20.05C12.4615 20.68 10.7415 21.31 8.95152 21.46C7.16152 21.61 5.23153 21.22 3.89153 20.02"
									stroke="#21187F"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M18.262 8.48C19.842 6.29 21.432 4.07 23.012 1.87C22.752 1.6 22.442 1.28 22.182 1C20.702 3.08 18.682 5.55 17.212 7.62C16.762 8.26 16.532 9.02 16.292 9.76C16.332 9.54 16.752 9.44 17.002 9.32C17.492 9.1 17.952 8.9 18.262 8.46V8.48Z"
									fill="#F89DBD"
									stroke="#F89DBD"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M9.27148 10.5C9.27148 10.16 9.61148 9.89997 9.95148 9.87997C10.2915 9.85997 10.6115 9.98997 10.9215 10.14C11.2315 10.29 11.5315 10.45 11.8715 10.47C12.3115 10.49 12.7215 10.26 13.1315 10.11C13.5415 9.95997 14.0515 9.86997 14.3915 10.15"
									stroke="#F89DBD"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_1252_1381">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip1_1252_1381">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip2_1252_1381">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip3_1252_1381">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip4_1252_1381">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const SelectedAddressesIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1252_2865)">
				<path d="M10.5 7C10.1 9 12.5 14.5 15 16.5L18.5001 12.2501L20.0001 7L18.5001 3.5L16.0001 2L13 2.5C12.1666 3 10.9 5 10.5 7Z" fill="#21187F" />
				<g clip-path="url(#clip1_1252_2865)">
					<path d="M10.5 7C10.1 9 12.5 14.5 15 16.5L18.5001 12.2501L20.0001 7L18.5001 3.5L16.0001 2L13 2.5C12.1666 3 10.9 5 10.5 7Z" fill="#F89DBD" />
					<path
						d="M5 22.2467C6.36553 22.3195 7.74017 22.0919 9.01467 21.5913C9.6064 21.3546 10.2528 20.9358 10.2436 20.2985C10.2436 19.7705 9.77026 19.3791 9.3606 19.0423C8.95094 18.7054 8.50487 18.2685 8.5868 17.7404C8.64142 17.3672 8.96005 17.0668 9.31509 16.9393C9.67012 16.8119 10.0616 16.8028 10.4348 16.8028C11.855 16.8028 13.2842 16.7846 14.7044 16.7755C13.0202 14.7909 11.5364 12.2419 10.8172 9.74753C10.098 7.25315 10.6624 4.17615 12.8382 2.74689C14.1309 1.89116 15.8696 1.76371 17.2807 2.40095C18.6917 3.0382 19.7296 4.44015 19.9389 5.97865C20.4578 9.86587 17.4081 13.7622 14.9957 16.8574"
						stroke="#21187F"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M13.5849 6.44282C13.5121 7.27124 14.1311 8.10877 14.9505 8.27263C15.7698 8.4365 16.6619 7.90849 16.9168 7.11648C17.108 6.50654 16.9259 5.79646 16.4799 5.33218C16.1066 4.94073 15.2782 4.73135 14.7138 4.90432C14.122 5.08639 13.6486 5.8784 13.594 6.44282H13.5849Z"
						fill="white"
						stroke="#21187F"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_1252_2865">
					<rect width="24" height="24" fill="white" />
				</clipPath>
				<clipPath id="clip1_1252_2865">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const SelectedHelpIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1252_490)">
				<path d="M9.74421 21.9999C14.5735 21.9999 18.4884 17.9156 18.4884 12.8772C18.4884 7.83889 14.5735 3.75452 9.74421 3.75452C4.91492 3.75452 1 7.83889 1 12.8772C1 17.9156 4.91492 21.9999 9.74421 21.9999Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M14.6549 9.65524C15.0043 8.6071 15.3439 7.54925 15.6933 6.50111C18.2457 6.31672 20.7982 6.12262 23.3506 5.93822C23.3215 4.7251 23.2923 3.52168 23.2632 2.30855C20.1285 2.24062 16.7221 1.79419 13.6165 2.11445C13.6941 3.34699 14.1502 4.51159 14.4608 5.68589C14.8005 6.95725 14.6549 8.34506 14.6549 9.64553V9.65524Z"
					fill="#21187F"
					stroke="#21187F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M7.22083 9.07294C7.14319 7.76276 8.26897 6.49141 9.58885 6.41377C10.9087 6.33613 12.1704 7.47161 12.2383 8.78179C12.3063 10.0726 11.4134 11.1789 10.5982 12.1785C9.78295 13.1782 8.94832 14.3719 9.18124 15.6432" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M9.39494 17.8657C9.19114 17.9724 9.07468 18.2247 9.13291 18.4383C9.19114 18.6518 9.43376 18.8168 9.65698 18.7682C9.88019 18.7197 10.0452 18.4771 9.98695 18.2539C9.92872 18.0306 9.64727 17.9045 9.44347 18.0015" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_1252_490">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const SelectedPaymentMethodIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1252_3662)">
				<path
					d="M1.09743 15.6421C7.06295 14.7385 13.1264 14.4664 19.1464 14.8256C18.896 10.9829 18.6347 7.1401 18.3844 3.29735C18.3844 3.54773 3.92779 2.99255 2.62147 2.92723C0.661994 2.84014 1.09743 2.3285 1.15186 7.37959C1.18452 10.1337 1.11921 12.8879 1.09743 15.6421Z"
					fill="white"
					stroke="#21187F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M15.2495 6.70463L3.16602 6.93324C6.72573 6.98767 11.6897 6.66109 15.2495 6.70463Z" fill="white" stroke="#21187F" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M14.2913 10.1011C14.9118 9.41527 16.2399 9.67654 16.5665 10.5365C16.8931 11.3965 16.0657 12.4633 15.1513 12.3654C14.2369 12.2674 13.6599 11.0482 14.1607 10.2753" fill="white" />
				<path d="M14.2913 10.1011C14.9118 9.41527 16.2399 9.67654 16.5665 10.5365C16.8931 11.3965 16.0657 12.4633 15.1513 12.3654C14.2369 12.2674 13.6599 11.0482 14.1607 10.2753" stroke="#21187F" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M4.95073 21.7382C10.9162 20.8346 16.9797 20.5625 22.9997 20.9217C22.7493 17.079 22.488 13.2362 22.2377 9.39346C22.2377 9.64384 7.78108 9.08866 6.47477 9.02334C4.51529 8.93625 4.96162 8.42461 5.01605 13.4648C5.0487 16.219 4.98339 18.9731 4.96162 21.7273L4.95073 21.7382Z"
					fill="#F89DBD"
					stroke="#21187F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M22 13H5C10.0081 13 16.9919 13 22 13Z" fill="#F89DBD" stroke="#21187F" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M11 18H7C8.17838 18 9.82162 18 11 18Z" fill="#F89DBD" stroke="#21187F" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M14 16H7C9.06216 16 11.9378 16 14 16Z" fill="#F89DBD" stroke="#21187F" stroke-linecap="round" stroke-linejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_1252_3662">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const DropdownActive = () => {
	return (
		<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.70711 1.20711L11.2929 5.79289C11.9229 6.42286 11.4767 7.5 10.5858 7.5H6.25H1.41421C0.523309 7.5 0.0771403 6.42286 0.707105 5.79289L5.29289 1.20711C5.68342 0.816583 6.31658 0.816582 6.70711 1.20711Z" fill="#21187F" />
		</svg>
	);
};

interface CloseIconProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
}

export const CloseIcon: React.FC<CloseIconProps> = ({ onClick }) => {
	return (
		<button onClick={onClick} className="cursor-pointer">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</button>
	);
};
