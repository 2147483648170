import React, { createContext, useContext, useEffect, useState } from 'react';

const CartContext = createContext<any>(undefined);

const CartProvider = (props: any) => {
	const _cart = JSON.parse(localStorage.getItem('cart') || '[]');
	const [cart, setCart] = useState<any[]>(_cart);

	const addToCart = (item: any) => {
		item.quantity = 1;
		const newCart = [...cart, item];
		setCart(newCart);
	};

	const updateCart = (item: any, index: number) => {
		let newCart = [...cart];
		newCart[index] = item;
		setCart(newCart);
	};

	const saveToLocalStorage = (cart: any[]) => {
		localStorage.setItem('cart', JSON.stringify(cart));
	};

	const emptyCart = () => {
		setCart([]);
	};

	const changeQuantity = (index: number, quantityDif: 1 | -1) => {
		const product = cart[index];
		product.quantity += quantityDif;
		let newCart = [...cart];
		newCart[index] = product;
		setCart(newCart);
	};

	const removeFromCart = (index: number) => {
		const newCart = cart.filter((e, i) => i !== index);
		setCart(newCart);
	};

	useEffect(() => {
		saveToLocalStorage(cart);
	}, [cart]);

	return <CartContext.Provider value={{ cart, setCart, addToCart, emptyCart, removeFromCart, updateCart, changeQuantity }} {...props} />;
};

function useCart() {
	const context = useContext(CartContext);

	if (context === undefined) {
		throw new Error(`useCart must be used within a CartProvider`);
	}

	return context;
}

export { CartProvider, useCart };
