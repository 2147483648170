import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { ReactComponent as SendBackward } from '../../assets/icons/sendBackward.svg';
import { ReactComponent as SendToBack } from '../../assets/icons/sendToBack.svg';
import { ReactComponent as BringForward } from '../../assets/icons/bringForward.svg';
import { ReactComponent as BringToFront } from '../../assets/icons/bringToFront.svg';
import Loading from '../../common/Loading';
import { useContainerDimensions } from '../../util/util';

const ConfiguratorBody = ({
	onReady = () => {},
	showAdditionalButtons = false,
	image = '',
	loading,
	bringToFront,
	bringToBack,
	bringForward,
	bringBackward,
	rotateBack,
}: {
	onReady?: any;
	image?: string;
	loading?: boolean;
	bringToFront?: () => void;
	bringToBack?: () => void;
	bringForward?: () => void;
	bringBackward?: () => void;
	showAdditionalButtons: boolean;
	rotateBack?: () => void;
}) => {
	const ref = useRef(null);

	const { width, height } = useContainerDimensions(ref);

	const canvasWidth = useMemo(() => Math.min(width, 760), [width]);
	return (
		<div className="w-full bg-[#E5DDF6] h-full flex items-center justify-center" ref={ref}>
			{!loading && (
				// <div style={{ height: canvasWidth, width: '100%' }}>
				<FabricJSCanvas
					onReady={(c) => {
						c.preserveObjectStacking = true;
						c.width = canvasWidth;
						c.height = canvasWidth;
						return onReady(c);
					}}
					className="w-full h-full fabric-container flex items-center justify-center"
				/>
				// </div>
			)}
			{showAdditionalButtons && (
				<div className="absolute bottom-[30px] left-[30px] flex flex-row gap-[20px]">
					<button className=" h-[44px] w-[44px] rounded-full bg-white flex items-center justify-center hover:scale-110 duration-200 transition-all ease-in-out shadow-md " onClick={bringToFront}>
						<BringToFront />
					</button>
					<button className=" h-[44px] w-[44px] rounded-full bg-white flex items-center justify-center hover:scale-110 duration-200 transition-all ease-in-out shadow-md" onClick={bringForward}>
						<BringForward />
					</button>
					<button className=" h-[44px] w-[44px] rounded-full bg-white flex items-center justify-center hover:scale-110 duration-200 transition-all ease-in-out shadow-md" onClick={bringBackward}>
						<SendBackward />
					</button>
					<button className=" h-[44px] w-[44px] rounded-full bg-white flex items-center justify-center hover:scale-110 duration-200 transition-all ease-in-out shadow-md" onClick={bringToBack}>
						<SendToBack />
					</button>
				</div>
			)}
			{loading && (
				<div className="flex-1 flex items-center justify-center h-full">
					<Loading />
				</div>
			)}
		</div>
	);
};

export default ConfiguratorBody;
