import React, { useEffect, useState } from 'react';
import ScrollingBanner from '../../common/ScrollingBanner';
import FooterBanner from '../../common/FooterBanner';
import EmptyCart from './EmptyCart';
import CartList from './CartList';
import GreetingModal from './GreetingModal';
import { useUser } from '../../auth/UseUser';
import { useCart } from '../../hooks/useCart';
import { useNavigate } from 'react-router-dom';
import SEO from '../seo/seo';

function CartHome() {
	const { isSignedIn } = useUser();
	const pathname = window.location.pathname.slice(1);
	let navigate = useNavigate();
	const { cart } = useCart();
	const [empty, setEmpty] = useState(cart.length == 0);
	const [mode, setMode] = useState(pathname?.includes('checkout') ? 'checkout' : 'cart');

	useEffect(() => {
		if (empty && pathname?.includes('checkout')) navigate('cart');
	}, []);

	useEffect(() => {
		setEmpty(cart.length === 0);
	}, [cart]);

	useEffect(() => {
		setMode(pathname?.includes('checkout') ? 'checkout' : 'cart');
	}, [pathname]);

	return (
		<div className=" w-full  flex flex-col bg-[#F1F1FE]">
			<SEO title="Korpa - Layka" />
			<div className=" w-full flex flex-col items-center pt-[60px] bg-laykaBackgroundColor">
				<div className="xl:w-[1065px] xl:px-0 w-full p-[20px]">
					<div>
						<h2 className="text-[14px] leading-[18px] font-normal text-black uppercase">{pathname.replace('/', ' / ')}</h2>
						<h1 className="text-[70px] leading-[80px] font-bold text-black mt-3">{mode === 'cart' ? 'Cart' : 'Checkout'}</h1>
					</div>
					{empty && <EmptyCart />}
					{!empty && <CartList mode={mode} setMode={setMode} />}
				</div>
			</div>
			{mode === 'cart' && <ScrollingBanner text="BRING YOUR GIFTS TO LIFE" />}
			{mode === 'cart' && <FooterBanner />}
			{/* <GreetingModal modalIsOpen={modalOpen} closeModal={closeModal} /> */}
		</div>
	);
}

export default CartHome;
