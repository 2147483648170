import React from 'react';
import { ReactComponent as EaseShopping } from '../../assets/icons/EaseShopping.svg';
import { Link } from 'react-router-dom';

function EmptyCart() {
	return (
		<div className="w-full flex flex-col items-center mt-[16px] mb-[230px]">
			<div className="w-[430px] flex flex-col items-center ">
				<EaseShopping width={253} height={253} title="Empty cart" />
				<h2 className="text-[30px] leading-[36px] font-bold text-black mt-10 text-center px-5">There is still space in your cart</h2>
				<h3 className="text-[16px] leading-[24px] font-normal text-black text-center mt-5">Browse our products to start filling it now.</h3>
				<Link to={'/'} className="w-full max-w-[343px] h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple text-[16px] text-white font-bold mt-[30px] uppercase">
					Explore products
				</Link>
			</div>
		</div>
	);
}

export default EmptyCart;
