import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as SaveToDraft } from '../../assets/icons/saveToDraft.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import Loading from '../../common/Loading';

const Header = ({ product, price = '1000RSD', downloadPDF, saveDraft, loading, disabled, orderProgress, draftProgress }: { product?: any; downloadPDF?: () => void; saveDraft?: () => void; price?: string; loading: boolean; disabled: boolean; orderProgress?: boolean; draftProgress?: boolean }) => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div className="h-[80px] bg-[#FFFFFF] flex flex-row  items-center w-full border-b border-b-[#F1F1FE]">
			<div className="mx-[40px] my-[14px]">
				<Logo />
			</div>
			<div className="h-[60px] w-[1px] bg-[#E5DDF6]" />
			<div className="flex-1 px-[40px]">
				<p>{product?.name}</p>
			</div>
			<div>
				<button className="hover:cursor-pointer flex flex-row items-center gap-[8px] px-[20px] mx-[20px]" onClick={saveDraft}>
					{draftProgress ? <Loading height={20} color="#0F2765" secondaryColor="#f89dbd" /> : <SaveToDraft />}
					<p>Save to My Draft</p>
				</button>
			</div>
			<div className="h-[60px] w-[1px] bg-[#E5DDF6]" />
			<div>
				<button className={`mx-[40px] rounded-full px-[62px] text-[white] h-[44px] text-bold min-w-[285px] items-center justify-center flex ${disabled ? 'bg-[#DFDFDF]' : 'bg-[#21187f]'}`} onClick={downloadPDF}>
					{loading || orderProgress ? <Loading height={24} color="#0F2765" /> : <p>ADD TO CART ({price})</p>}
				</button>
			</div>
			<div className="h-[100%] w-[1px] bg-[#E5DDF6]" />
			<div className="h-full">
				<button className="hover:cursor-pointer px-[40px]  h-full" onClick={goBack}>
					<Close />
				</button>
			</div>
		</div>
	);
};

export default Header;
