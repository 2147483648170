import { ArrowLeftIcon } from '@mui/x-date-pickers';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../assets/icons/arrowNext.svg';
import { ReactComponent as ChooseDesign } from '../../assets/icons/chooseDesign.svg';
import { ReactComponent as MakeYourOwn } from '../../assets/icons/makeYourOwn.svg';
import { ReactComponent as SelectSymbol } from '../../assets/icons/selectSymbol.svg';
import { ReactComponent as AddSymbolIcon } from '../../assets/icons/addSymbol.svg';
import { ReactComponent as AddText } from '../../assets/icons/editText.svg';
import Addtext from './AddText';
import AddSymbol from './AddSymbol';
import { DEFAULT_NEW_TEXT, FONTS, FabricTypeConverter } from '../../Constants';

type buttonProp = {
	Symbol: any;
	text: string;
	children: any[];
	code: string;
	showRightArrow?: boolean;
	subMenuType?: string;
	hideAdditionalBar?: string;
};

const buttons: buttonProp[] = [
	{
		Symbol: ChooseDesign,
		text: 'CHOOSE DESIGN',
		code: 'choose',
		showRightArrow: true,
		children: [
			{
				Symbol: SelectSymbol,
				text: 'SELECT A SYMBOL',
				subMenuType: 'selectSymbol',
			},
		],
	},
	{
		Symbol: MakeYourOwn,
		showRightArrow: true,
		text: 'MAKE YOUR OWN',
		code: 'makeOwn',
		children: [
			{
				Symbol: AddSymbolIcon,
				text: 'ADD SYMBOL',
				code: 'addSymbol',
				subMenuType: 'addSymbol',
			},
			{
				Symbol: AddText,
				text: 'ADD TEXT',
				code: 'addText',
				subMenuType: 'addText',
			},
			{
				Symbol: SelectSymbol,
				text: 'UPLOAD IMAGE',
				subMenuType: 'uploadImage',
				code: 'uploadImage',
				hideAdditionalBar: true,
			},
		],
	},
];

const ConfigurationSideBar = ({
	functions,
	selectedObject,
	textAlignment,
	renderAll = () => {},
}: {
	functions: {
		onAddText: ({ text, fontFamily }: { text: string; fontFamily?: string }) => void;
		fitText?: () => void;
		addSymbol: ({ symbol }: { symbol: string }) => void;
		addImage?: (image: string) => void;
		alignText?: (type: string) => void;
		removeObject: (object: any) => void;
		removeSelectedObject: () => void;
	};
	selectedObject: any;
	renderAll?: any;
	textAlignment?: string;
}) => {
	const { onAddText, fitText, addSymbol, alignText, addImage = () => {}, removeObject, removeSelectedObject } = functions;
	const [selectedButtonsArray, setSelectedButtonsArray] = useState<buttonProp[]>(buttons);
	const [selectedDesignType, setSelectedDesignType] = useState<any>(null);
	const [subMenuType, setSubMenuType] = useState<any>(null);
	const [selectedSubMenu, setSelectedSubMenu] = useState<any>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const back = () => {
		setSelectedButtonsArray(buttons);
		setSelectedDesignType(null);
		setSubMenuType(null);
		setSelectedSubMenu(null);
	};

	const clickButton = (button: buttonProp) => {
		if (!!button?.children) {
			setSelectedDesignType(button.code);
			setSelectedButtonsArray(button.children);
		} else if (button?.hideAdditionalBar) {
			fileInputRef.current?.click();
		} else {
			setSelectedSubMenu(button.code);
			setSubMenuType(button.subMenuType);
			if (button.code == 'addText') {
				onAddText({ text: DEFAULT_NEW_TEXT, fontFamily: FONTS[0] });
			}
		}
	};

	useEffect(() => {
		if (selectedObject) {
			if (selectedObject?.type?.toLowerCase()?.includes('text')) {
				setSelectedDesignType(buttons[1].code);
				setSelectedButtonsArray(buttons[1].children);
				setSelectedSubMenu('addText');
				const type = FabricTypeConverter[selectedObject?.get('type')];
				setSubMenuType(type);
			} else back();
		} else {
			back();
		}
	}, [selectedObject]);

	const onImageUpload = (event: any) => {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		const reader = new FileReader();
		reader.onload = (event) => {
			addImage(event?.target?.result as string);
		};
		reader.readAsDataURL(event.target.files[0]);
	};

	return (
		<div className="flex flex-row h-full">
			<div className="w-[330px] bg-white z-[2]">
				{selectedDesignType != null && (
					<div className="h-[100px] pl-[40px] pr-[20px] items-center flex flex-row gap-[16px] cursor-pointer border-t border-t-[#F1F1FE] border-b border-b-[#F1F1FE] " onClick={back}>
						<LeftArrow className="rotate-180" />
						<label htmlFor="back" className="text-[16px] font-[500]">
							BACK
						</label>
					</div>
				)}
				{selectedButtonsArray.map((button: buttonProp) => (
					<SideBarButton Symbol={button.Symbol} text={button.text} onClick={() => clickButton(button)} showRightArrow={button.showRightArrow} selected={selectedSubMenu === button.code} key={button.code} />
				))}
			</div>

			<div className={`absolute w-[370px] z-[1] bg-[#FAFAFA] duration-[230ms] ease-in-out transition-all h-full ${!subMenuType ? '-ml-[40px]' : ' ml-[330px]'}`} style={{ height: 'calc(100vh - 80px)' }}>
				{subMenuType === 'addText' && <Addtext onAddText={onAddText} selectedObject={selectedObject} renderAll={renderAll} fitText={fitText} alignText={alignText} alignedText={textAlignment} removeSelectedObject={removeSelectedObject} />}
				{subMenuType === 'editText' && <Addtext onAddText={onAddText} selectedObject={selectedObject} renderAll={renderAll} fitText={fitText} alignText={alignText} alignedText={textAlignment} removeSelectedObject={removeSelectedObject} />}
				{subMenuType === 'addSymbol' && <AddSymbol addSymbol={addSymbol} />}
				{subMenuType === 'selectSymbol' && <AddSymbol addSymbol={addSymbol} />}
			</div>
			<input type="file" accept="image/*" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
		</div>
	);
};

type SideBarButtonProps = {
	Symbol: any;
	text: string;
	onClick: () => void;
	showRightArrow?: boolean;
	selected?: boolean;
};

const SideBarButton: FunctionComponent<SideBarButtonProps> = ({ Symbol, text, onClick, showRightArrow = false, selected = false }: SideBarButtonProps) => {
	return (
		<button className={`w-full h-[100px] pl-[40px] pr-[20px] items-center flex flex-row cursor-pointer border-t border-t-[#F1F1FE] border-b border-b-[#F1F1FE]  ${selected ? 'bg-[#FAFAFA]' : ''}`} disabled={selected} onClick={onClick}>
			<Symbol />
			<p className="mr-[8px] ml-[39px]">{text}</p>
			{showRightArrow && <LeftArrow />}
		</button>
	);
};

export default ConfigurationSideBar;
