import React from 'react';
import { ReactComponent as Amex } from '../assets/icons/amex.svg';
import { ReactComponent as Visa } from '../assets/icons/visa.svg';
import { ReactComponent as Mastercard } from '../assets/icons/mastercard.svg';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { useMenuItems } from '../hooks/useMenuItems';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
	const { menuItems } = useMenuItems();
	const navigate = useNavigate();
	return (
		<div className="bg-darkBlue p-[10px]">
			<div className="max-w-[1440px] w-full mx-auto grid grid-cols-2 md:grid-cols-7 pt-[80px]">
				<div className="col-span-2 gap-[16px] flex flex-col">
					<p className="text-pink uppercase font-bold text-[18px]">Products</p>
					{menuItems.map((item: any, index: number) => (
						<div key={item.id}>
							<button className="font-medium text-white uppercase text-[16px] text-left pr-[5px]" onClick={() => navigate(`/products/${item.name.toLowerCase()}`)} title={item.name}>
								{item.name}
							</button>
						</div>
					))}
				</div>
				<div className="col-span-2 gap-[16px] flex flex-col">
					<p className="text-pink uppercase font-bold text-[18px]" title="About">
						ABOUT
					</p>
					<Link to="/placanje_i_isporuka" title="Plaćanje i isporuka">
						<span className="font-medium text-white uppercase text-[16px]">Plaćanje i isporuka</span>
					</Link>
					<Link to="/povracaj_sredstava" title="Povraćaj sredstava">
						<span className="font-medium text-white uppercase text-[16px]">Povraćaj sredstava</span>
					</Link>
					<Link to="/uslovi_koriscenja" title="Uslovi korišćenja">
						<span className="font-medium text-white uppercase text-[16px]">Uslovi korišćenja</span>
					</Link>
					<Link to="/politika_privatnosti" title="Politika Privatnosti">
						<span className="font-medium text-white uppercase text-[16px]">Politika Privatnosti</span>
					</Link>
					<Link to="/kontakt" title="kontakt">
						<span className="font-medium text-white uppercase text-[16px]">Kontakt</span>
					</Link>
					<Link to="/faq" title="FAQ">
						<span className="font-medium text-white uppercase text-[16px]">FAQ</span>
					</Link>
					<Link to="o_nama" title="O nama">
						<span className="font-medium text-white uppercase text-[16px]">O nama</span>
					</Link>
				</div>
				<div className="col-span-2">
					<p className="text-pink uppercase font-bold text-[18px]">SECURE PAYMENT</p>
					<div className="flex flex-col gap-[10px] mt-[16px]  h-full pb-[20px]">
						<a href="https://www.raiffeisenbank.rs" target="_blank" rel="noreferrer" title="Raiffeisen Bank">
							<img src={require('../assets/images/RBL.webp')} width={330} alt="Raiffeisen Bank" rel="noreferrer" title="Raiffeisen Bank" />
						</a>
						<div className="flex flex-row flex-wrap gap-[20px] items-center ">
							<a href="https://www.mastercard.rs/sr-rs/korisnici/podrska/sigurnost-i-zastita/identity-check.html" target="_blank" rel="noreferrer" title="Master card ID Check">
								<img src={require('../assets/images/mc_idcheck_vrt.webp')} width={60} alt="Master card ID Check" title="Master card ID Check" />
							</a>
							<a href="https://rs.visa.com/run-your-business/small-business-tools/payment-technology/visa-secure.html" target="_blank" rel="noreferrer" title="Visa Secure">
								<img src={require('../assets/images/visa_secure.webp')} width={60} alt="Visa Secure" title="Visa Secure" />
							</a>
						</div>
						<div className="flex flex-row flex-wrap gap-[20px] items-center mt-5">
							<img src={require('../assets/images/DinaCard.jpg')} width={60} alt="Dina Card" title="Dina Card" />
							<img src={require('../assets/images/Visa.webp')} width={60} alt="Visa Secure" title="Visa Secure" />
							<img src={require('../assets/images/masterCard.webp')} width={60} alt="Master Card" title="Master Card" />
							<img src={require('../assets/images/maestroCard.webp')} width={150} alt="Maestro Card" title="Maestro Card" style={{ marginLeft: -20 }} />
						</div>
					</div>
				</div>
				<div className="col-span-1">
					<Logo />
				</div>
			</div>
			<div className="py-[40px]">
				<p className="font-normal text-[14px] text-center text-white">Copyright 2024 Layka - Design by SuperCluster</p>
			</div>
		</div>
	);
}

export default Footer;
