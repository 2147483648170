export const MyProfileIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.0857 21.5997C2.8087 20.7922 2.48006 18.9894 2.94955 17.5528C3.41903 16.1161 4.50824 14.98 5.60683 13.9471C7.7289 11.9565 10.0106 10.1349 12.4331 8.52927C12.1233 7.25227 11.8604 6.18185 11.5505 4.90485C13.513 3.96588 15.4003 2.84851 17.3627 1.90015"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.50391 21.0363C4.48982 22.1819 6.32081 22.2382 7.67293 21.5621C9.02505 20.8861 9.98279 19.6466 10.8466 18.4072C12.5086 16.0128 13.964 13.487 15.1941 10.8485C16.4992 10.9706 17.5978 11.0645 18.903 11.1865C19.5415 9.10201 20.3678 7.07384 21.0157 4.99872"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M17.3724 1.90015C16.6118 2.51048 15.87 3.19592 15.485 4.08794C15.1001 4.97997 15.1188 6.11612 15.7949 6.82034C16.5555 7.62786 17.8982 7.60908 18.8747 7.09265C19.8513 6.57621 20.5555 5.66541 21.2221 4.78278" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M6.78931 19.9189C8.19776 19.2053 6.81748 17.1302 5.53109 17.6936C3.93484 18.3884 5.36208 20.6419 6.78931 19.9189Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
};

export const MyOrdersIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.20152 13.63C4.06152 12.94 3.24152 12.53 2.55152 12.7C1.86152 12.87 1.36152 13.48 1.15152 14.16C0.941525 14.84 0.981525 15.56 1.08152 16.25C1.19152 17.09 1.39152 17.94 1.87152 18.64C2.35152 19.34 3.18152 19.84 4.02152 19.74C4.91152 19.63 5.62152 18.86 5.92153 18.01C6.22153 17.16 6.20152 16.23 6.18152 15.33C6.07152 11.23 5.95153 7.14001 5.84153 3.04001C10.4015 2.94001 14.8815 2.86001 19.4415 2.76001C19.5015 7.88001 19.5315 13.11 19.5815 18.24C17.8015 18.9 15.9315 19.39 14.1515 20.05C12.4615 20.68 10.7415 21.31 8.95152 21.46C7.16152 21.61 5.23153 21.22 3.89153 20.02"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.261 8.48C19.841 6.29 21.431 4.07 23.011 1.87C22.751 1.6 22.441 1.28 22.181 1C20.701 3.08 18.681 5.55 17.211 7.62C16.761 8.26 16.531 9.02 16.291 9.76C16.331 9.54 16.751 9.44 17.001 9.32C17.491 9.1 17.951 8.9 18.261 8.46V8.48Z"
				fill="white"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.27148 10.5C9.27148 10.16 9.61148 9.89997 9.95148 9.87997C10.2915 9.85997 10.6115 9.98997 10.9215 10.14C11.2315 10.29 11.5315 10.45 11.8715 10.47C12.3115 10.49 12.7215 10.26 13.1315 10.11C13.5415 9.95997 14.0515 9.86997 14.3915 10.15"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const MyAddressesIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 22.2467C6.36553 22.3195 7.74017 22.0919 9.01467 21.5913C9.6064 21.3546 10.2528 20.9358 10.2436 20.2985C10.2436 19.7705 9.77026 19.3791 9.3606 19.0423C8.95094 18.7054 8.50487 18.2685 8.5868 17.7404C8.64142 17.3672 8.96005 17.0668 9.31509 16.9393C9.67012 16.8119 10.0616 16.8028 10.4348 16.8028C11.855 16.8028 13.2842 16.7846 14.7044 16.7755C13.0202 14.7909 11.5364 12.2419 10.8172 9.74753C10.098 7.25315 10.6624 4.17615 12.8382 2.74689C14.1309 1.89116 15.8696 1.76371 17.2807 2.40095C18.6917 3.0382 19.7296 4.44015 19.9389 5.97865C20.4578 9.86587 17.4081 13.7622 14.9957 16.8574"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.5839 6.44282C13.5111 7.27124 14.1302 8.10877 14.9495 8.27263C15.7688 8.4365 16.661 7.90849 16.9158 7.11648C17.107 6.50654 16.925 5.79646 16.4789 5.33218C16.1056 4.94073 15.2772 4.73135 14.7128 4.90432C14.1211 5.08639 13.6477 5.8784 13.5931 6.44282H13.5839Z"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const HelpIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1252_125)">
				<path d="M9.74421 21.9999C14.5735 21.9999 18.4884 17.9156 18.4884 12.8772C18.4884 7.83889 14.5735 3.75452 9.74421 3.75452C4.91492 3.75452 1 7.83889 1 12.8772C1 17.9156 4.91492 21.9999 9.74421 21.9999Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M14.6556 9.65524C15.005 8.6071 15.3447 7.54925 15.6941 6.50111C18.2465 6.31672 20.7989 6.12262 23.3513 5.93822C23.3222 4.7251 23.2931 3.52168 23.264 2.30855C20.1292 2.24062 16.7228 1.79419 13.6172 2.11445C13.6948 3.34699 14.151 4.51159 14.4615 5.68589C14.8012 6.95725 14.6556 8.34506 14.6556 9.64553V9.65524Z"
					fill="#F1F1FE"
					stroke="black"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M7.22059 9.07294C7.14295 7.76276 8.26873 6.49141 9.58861 6.41377C10.9085 6.33613 12.1701 7.47161 12.2381 8.78179C12.306 10.0726 11.4132 11.1789 10.5979 12.1785C9.78271 13.1782 8.94808 14.3719 9.181 15.6432" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M9.39421 17.8657C9.19041 17.9724 9.07395 18.2247 9.13218 18.4383C9.19041 18.6518 9.43303 18.8168 9.65625 18.7682C9.87946 18.7197 10.0444 18.4771 9.98622 18.2539C9.92799 18.0306 9.64654 17.9045 9.44274 18.0015" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_1252_125">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const MyPaymentMethodsIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.09646 15.6421C7.06197 14.7385 13.1255 14.4664 19.1454 14.8256C18.895 10.9829 18.6338 7.1401 18.3834 3.29735C18.3834 3.54773 3.92681 2.99255 2.62049 2.92723C0.661018 2.84014 1.09646 2.3285 1.15089 7.37959C1.18354 10.1337 1.11823 12.8879 1.09646 15.6421V15.6421Z"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M15.2475 6.70463L3.16406 6.93324C6.72378 6.98767 11.6878 6.66109 15.2475 6.70463Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M14.2904 10.1011C14.9109 9.41527 16.2389 9.67654 16.5655 10.5365C16.8921 11.3965 16.0648 12.4633 15.1504 12.3654C14.2359 12.2674 13.659 11.0482 14.1597 10.2753" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path
				d="M4.94975 21.7382C10.9153 20.8346 16.9788 20.5625 22.9987 20.9217C22.7483 17.079 22.4871 13.2362 22.2367 9.39346C22.2367 9.64384 7.78011 9.08866 6.47379 9.02334C4.51431 8.93625 4.96064 8.42461 5.01507 13.4648C5.04773 16.219 4.98241 18.9731 4.96064 21.7273L4.94975 21.7382Z"
				fill="#F1F1FE"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M21.998 13H4.99805C10.0062 13 16.9899 13 21.998 13Z" fill="#F1F1FE" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M10.998 18H6.99805C8.17643 18 9.81967 18 10.998 18Z" fill="#F1F1FE" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M13.998 16H6.99805C9.06021 16 11.9359 16 13.998 16Z" fill="#F1F1FE" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
};

export const HeartIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.6323 3.34668C6.91357 2.64153 4.68265 3.05097 3.6241 4.58639C2.47449 6.25828 3.0436 8.62396 4.27289 10.239C5.50217 11.854 7.25504 12.9686 8.76888 14.3334C11.3299 16.6309 13.208 19.679 14.1072 23C13.4242 19.2013 14.3803 15.1409 16.6909 12.0474C18.0113 10.2731 19.8438 8.62396 19.9918 6.40614C20.1625 3.92672 17.6357 1.62929 15.1771 2.05011C13.0941 2.40268 11.66 4.57501 11.808 6.62223C11.3413 5.18918 10.0096 3.91535 8.62091 3.34668H8.6323Z"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const Dropdown = () => {
	return (
		<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.70711 6.79289L11.2929 2.20711C11.9229 1.57714 11.4767 0.5 10.5858 0.5H6.25H1.41421C0.523309 0.5 0.0771403 1.57714 0.707105 2.20711L5.29289 6.79289C5.68342 7.18342 6.31658 7.18342 6.70711 6.79289Z" fill="#21187F" />
		</svg>
	);
};

export const SignOut = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.2702 22.1356C14.5894 22.0033 17.9196 21.7827 21.2387 21.6283C21.4593 21.6283 21.6908 21.6063 21.9114 21.5953C21.8011 15.1444 21.36 8.46191 21.2498 2C21.4041 2.09924 21.5585 2.20952 21.7129 2.30876L11.0938 3.53277C11.6451 9.74106 11.0166 15.9052 11.2702 22.1356Z"
				stroke="black"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M14.2918 12.145L3.07715 12.5199C4.65403 11.4172 5.73469 10.0498 7.31158 8.94713" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M3 12.8066L6.09863 16.3022" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
};
