import React, { useEffect, useState } from 'react';
import Loading from '../../common/Loading';
import { deleteDraftsById, getDrafts } from '../../server/server';
import DraftItem from './DraftItem';
import EmptyDraft from './EmptyDraft';
import SEO from '../seo/seo';

function DraftPage() {
	const [drafts, setDrafts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadDrafts();
	}, []);

	const loadDrafts = async () => {
		const resp = await getDrafts();
		if (resp.status == 200) {
			console.log(resp.data);

			setDrafts(resp.data);
			setLoading(false);
		}
	};

	const deleteDraft = async (draft: any) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Delete this draft?')) {
			const resp = await deleteDraftsById(draft.id);
			if (resp.status === 200) {
				loadDrafts();
			}
		}
	};

	return (
		<div className=" w-full  flex flex-col bg-[#F1F1FE]">
			<SEO title="Draft - Layka" />
			<div className=" w-full flex flex-col items-center pt-[60px] bg-laykaBackgroundColor">
				<div className="w-[1065px] min-h-[828px] flex flex-col">
					<div>
						<p className="text-[14px] leading-[18px] font-normal text-black uppercase">HOME / MY DRAFTS</p>
						<p className="text-[70px] leading-[80px] font-bold text-black mt-3">My Drafts</p>
					</div>
					{loading && (
						<div className=" flex flex-row items-center justify-center flex-1 ">
							<Loading secondaryColor="white" />
						</div>
					)}
					{!loading && (
						<>
							{drafts.length > 0 ? (
								<div className="pt-[60px] pb-[120px] flex-1 flex flex-col">
									<div className="flex flex-col flex-1 gap-[20xp]">
										{drafts.map((item, index) => (
											<DraftItem item={item} deleteDraft={deleteDraft} key={index} />
										))}
									</div>
								</div>
							) : (
								<EmptyDraft />
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default DraftPage;
