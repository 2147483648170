import React, { useState } from 'react';
import ProductWidget from './ProductWidget';

function ProductGroup({ groupName, products, hideTitle }: { groupName: string; products: any[]; hideTitle: boolean }) {
	const [numberOfProductsVisible, setNumberOfProductsVisible] = useState(8);

	const showAll = () => {
		setNumberOfProductsVisible(products?.length ?? 0);
	};

	return (
		<div className="">
			{!hideTitle && <h2 className="text-[36px] mb-[20px]">{groupName}</h2>}
			<div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 flex-wrap gap-[20px] ">
				{products.slice(0, numberOfProductsVisible).map((product, index) => (
					<ProductWidget key={index} product={product} />
				))}
			</div>
			{products?.length > numberOfProductsVisible && (
				<button className="mx-auto rounded-full h-[44px] flex items-center justify-center bg-[#21187f] text-white px-[66px] uppercase font-bold mt-[50px]" onClick={showAll}>
					View all
				</button>
			)}
		</div>
	);
}

export default ProductGroup;
