export const NoAddresses = () => {
	return (
		<svg width="241" height="264" viewBox="0 0 241 264" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1010_10893)">
				<path
					d="M192.061 104.945C197.123 103.286 202.873 103.819 207.532 106.392C212.203 108.964 215.724 113.529 217.028 118.698C217.763 121.602 217.716 124.969 215.784 127.258C213.851 129.546 209.607 129.818 208.125 127.21C206.501 124.341 209.204 120.856 212.108 119.279C218.285 115.924 226.335 116.362 232.121 120.346C237.906 124.341 241.166 131.715 240.23 138.674"
					stroke="black"
					stroke-width="1.23156"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M177.727 2.80951C171.373 9.25894 167.448 18.0439 166.891 27.066C166.785 28.8443 166.82 30.7175 167.674 32.2706C168.527 33.8237 170.448 34.9381 172.12 34.3216C174.064 33.6221 174.657 30.9072 173.673 29.0933C172.689 27.2794 170.626 26.3072 168.587 25.9279C164.117 25.1098 159.28 26.8526 156.352 30.3263C153.423 33.8 152.534 38.8623 154.087 43.1303"
					stroke="black"
					stroke-width="1.23156"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M41.9094 90.2444C43.3677 86.3202 48.3589 84.4589 52.3542 85.7156C56.3495 86.9723 59.2186 90.6831 60.3804 94.714C61.0562 97.0614 61.1984 99.6814 60.0722 101.839C58.9459 103.997 56.2428 105.443 53.9665 104.566C50.5521 103.262 50.73 97.7253 53.7176 95.6268C56.717 93.5284 60.9139 94.216 64.1268 95.9588C72.7576 100.618 76.504 112.592 72.0463 121.342"
					stroke="black"
					stroke-width="1.23156"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M42.7275 155.367C24.0787 155.877 6.42571 169.037 0.592773 186.761" stroke="black" stroke-width="1.23156" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M142.161 222.09C143.987 238.972 154.171 254.752 168.812 263.347" stroke="black" stroke-width="1.23156" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M53.8242 250.923C56.0897 250.923 57.9262 249.086 57.9262 246.821C57.9262 244.555 56.0897 242.719 53.8242 242.719C51.5587 242.719 49.7222 244.555 49.7222 246.821C49.7222 249.086 51.5587 250.923 53.8242 250.923Z" stroke="black" stroke-width="1.23156" />
				<path d="M199.34 246.821C201.605 246.821 203.442 244.984 203.442 242.719C203.442 240.453 201.605 238.616 199.34 238.616C197.074 238.616 195.238 240.453 195.238 242.719C195.238 244.984 197.074 246.821 199.34 246.821Z" stroke="black" stroke-width="1.23156" />
				<path d="M219.269 89.8649C221.534 89.8649 223.371 88.0283 223.371 85.7629C223.371 83.4974 221.534 81.6608 219.269 81.6608C217.004 81.6608 215.167 83.4974 215.167 85.7629C215.167 88.0283 217.004 89.8649 219.269 89.8649Z" stroke="black" stroke-width="1.23156" />
				<path d="M222.92 195.602C225.185 195.602 227.022 193.765 227.022 191.5C227.022 189.234 225.185 187.398 222.92 187.398C220.654 187.398 218.818 189.234 218.818 191.5C218.818 193.765 220.654 195.602 222.92 195.602Z" stroke="black" stroke-width="1.23156" />
				<path
					d="M20.4128 69.7622C20.0907 63.9766 22.2555 58.1008 26.2629 53.913C30.2702 49.7252 36.0559 47.3027 41.8415 47.38C45.1015 47.4187 48.6192 48.4109 50.4748 51.0782C52.3432 53.7455 51.4283 58.2812 48.2842 59.1059C44.8051 60.0078 41.9188 56.1937 41.0683 52.7018C39.2772 45.2668 41.9703 36.9427 47.7946 31.9818C53.6059 27.0209 62.265 25.655 69.3134 28.5929"
					stroke="black"
					stroke-width="1.23156"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M100.628 53.6811C98.888 48.3851 97.1355 43.0892 95.396 37.7932" stroke="black" stroke-width="1.23156" />
				<path d="M104.003 113.625C100.836 133.156 121.16 164.873 131.717 178.29C140.075 170.152 158.243 147.937 164.05 124.182C171.308 94.4889 148.873 83.2716 136.336 81.9519C123.799 80.6322 107.963 89.2102 104.003 113.625Z" fill="#E5DDF6" stroke="#E3DCF3" stroke-width="1.23156" />
				<path
					d="M68.3721 212.517C77.178 212.986 86.0426 211.519 94.2614 208.29C98.0773 206.763 102.245 204.063 102.187 199.953C102.187 196.548 99.134 194.024 96.4923 191.852C93.8505 189.68 90.9739 186.862 91.5023 183.457C91.8545 181.05 93.9092 179.113 96.1987 178.291C98.4883 177.469 101.013 177.41 103.42 177.41C112.578 177.41 121.795 177.293 130.953 177.234C120.092 164.436 110.523 147.999 105.885 131.913C101.247 115.828 104.887 95.985 118.918 86.7681C127.254 81.2498 138.467 80.4279 147.567 84.5373C156.666 88.6467 163.358 97.6875 164.709 107.609C168.055 132.676 148.388 157.802 132.831 177.763"
					stroke="black"
					stroke-width="1.23156"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M155.473 114.814C139.203 117.206 137.327 119.133 135.017 135.929C132.699 119.133 130.831 117.198 114.562 114.814C130.831 112.422 132.707 110.495 135.017 93.6987C137.335 110.495 139.203 112.43 155.473 114.814Z" fill="#21187F" />
			</g>
			<defs>
				<clipPath id="clip0_1010_10893">
					<rect width="241" height="263.941" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
