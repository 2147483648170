import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { AddImageSection, EditTextSection } from './ChooseDesignSection';
import EditText from './Design/EditText';
import EditDesign from './Design/EditDesign';

interface DefaultDesignSideBarType {
	productId?: string;
	selectedObject?: any;
	loading?: boolean;
	canvas?: any;
	product: any;
	renderAll: () => void;
	addDesign?: ({ design }: { design: any }) => void;
	functions: {
		onAddText?: ({ text, fontFamily }: { text: string; fontFamily?: string }) => void;
		fitText?: (obj: any) => void;
		addSymbol?: ({ symbol }: { symbol: string }) => void;
		addImage?: (image: string) => void;
		alignText?: (type: string) => void;
		removeObject?: (object: any) => void;
		removeSelectedObject?: () => void;
		setDisabledButton: (value: boolean) => void;
	};
	chosenDesign?: any;
	resetChosenDesign?: () => void;
	updateTextVersion: any;
	engravingColor: string | null;
}
function DefaultDesignSideBar({ product, canvas, productId = '', chosenDesign, loading, functions, engravingColor }: DefaultDesignSideBarType) {
	const [objects, setObjects] = useState<any>([]);
	const { setDisabledButton } = functions;

	// const objects = (canvas as any)?.getObjects();
	return (
		<div className="flex flex-col h-full ">
			<div className="border-b-[#D4C4F6] border-b border-solid flex flex-col pb-[30px] gap-[16px] mb-[30px] ">
				<p className="font-bold text-[20px]">Description</p>
				<p className="text-[14px] font-normal">
					<Markdown>{product?.description}</Markdown>
				</p>
			</div>
			<div className=" overflow-auto max-h-[650px] h-full">
				<div className="flex flex-row  items-center ">
					<p className="flex-1 font-bold text-[20px]">Options:</p>
				</div>

				{!loading && <EditDesign design={chosenDesign} canvas={canvas} setDisabledButton={setDisabledButton} hideHeader engravingColor={engravingColor} />}
			</div>
		</div>
	);
}

export default DefaultDesignSideBar;
