import React from 'react';

function InputArea({ text = '', onTextChange = () => {}, isError = false, numberOfCharacters = 50, onFocus, onBlur }: propTypes) {
	const changeText = (e: React.ChangeEvent<HTMLInputElement>) => {
		onTextChange(e.target.value);
	};
	return (
		<div className={`w-full flex flex-row items-end border-b ${isError ? 'border-b-[red]' : 'border-b-[#000000]'} pb-[13px]`}>
			<input className="flex-1 bg-[transparent] focus:outline-none pr-[5px]" value={text} onChange={changeText} placeholder="Enter text" maxLength={numberOfCharacters} autoFocus onFocus={onFocus} onBlur={onBlur} />
			<label htmlFor="" className={`${isError ? 'text-[red]' : ''}`}>
				{text?.length}/{numberOfCharacters}
			</label>
		</div>
	);
}

type propTypes = {
	text?: string;
	onTextChange?: (text: string) => void;
	isError?: boolean;
	numberOfCharacters?: number;
	onFocus?: () => void;
	onBlur?: () => void;
};

export default InputArea;
