import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../SVG/UnselectedIcons';
import { DropdownActive } from '../../SVG/SelectedIcons';
import ReactMarkdown from 'react-markdown';
import MessageModal from './MessageModal';

interface HelpProps {
	terms: string;
	privacy: string;
}

const AdditionalInformation: React.FC<HelpProps> = ({ terms, privacy }) => {
	const [dropdownActive, setDropDownActive] = useState({
		terms: false,
		privacy: false,
	});
	return (
		<div className="w-[522px]">
			<div>
				<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px]">Infromation</h4>
			</div>
			<div
				className="flex flex-col justify-between justify-items-center mt-[20px] w-full  cursor-pointer bg-white rounded-t-[12px] border-b border-laykaLight"
				style={{ userSelect: 'none' }}
				onClick={() =>
					setDropDownActive((prevState) => ({
						...prevState,
						terms: !prevState.terms,
					}))
				}
			>
				<div className="flex flex-row justify-between items-center min-h-[64px] p-[20px]">
					<span className="text-base font-notoSans font-normal text-black">Terms & Conditions</span>
					<div className="">{!dropdownActive.terms ? <Dropdown /> : <DropdownActive />}</div>
				</div>

				{dropdownActive.terms && (
					<div className="p-[20px]">
						<ReactMarkdown>{terms}</ReactMarkdown>
					</div>
				)}
			</div>
			<div
				className="flex flex-col justify-between justify-items-center  w-full  cursor-pointer bg-white rounded-b-[12px]"
				style={{ userSelect: 'none' }}
				onClick={() =>
					setDropDownActive((prevState) => ({
						...prevState,
						privacy: !prevState.privacy,
					}))
				}
			>
				<div className="flex flex-row justify-between items-center min-h-[64px] p-[20px]">
					<span className="text-base font-notoSans font-normal text-black">Privacy Policy</span>
					<div className="">{!dropdownActive.privacy ? <Dropdown /> : <DropdownActive />}</div>
				</div>

				{dropdownActive.privacy && (
					<div className="p-[20px]">
						<ReactMarkdown>{privacy}</ReactMarkdown>
					</div>
				)}
			</div>
		</div>
	);
};

export default AdditionalInformation;
