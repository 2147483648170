import React from 'react';
import SEO from '../seo/seo';

function ProductNameAndPrice({ name = '', price = '', currency = '' }: { name: string; price: string; currency: string }) {
	return (
		<div className="flex flex-col gap-[10px]">
			<SEO title={`${name} - Layka`} />
			<h1 className="text-[36px] font-[400]">{name}</h1>
			<p className="text-[20px] font-normal -mt-[6px] text-[#21187F]">
				{parseInt(price) < 0 ? '' : price} <span className="lowercase">{currency}</span>
			</p>
		</div>
	);
}

export default ProductNameAndPrice;
