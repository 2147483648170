import React from 'react';
import { NoPaymentMethods } from '../../SVG/NoPaymentMethods';
import { AmericanExpress, Visa, MasterCard } from '../../SVG/PaymentTypes';
import { useNavigate } from 'react-router';

const PaymentMethods = () => {
	const navigate = useNavigate();
	const paymentMethods: any[] = [
		// {
		// 	nameOnCard: 'Eva Johnson',
		// 	cardNumber: '9876 5432 1098 7654',
		// 	expirationDate: '11/26',
		// 	cvv: '456',
		// 	default: true,
		// 	issuer: 'mastercard',
		// },
		// {
		// 	nameOnCard: 'Chris Anderson',
		// 	cardNumber: '1234 5678 9012 3456',
		// 	expirationDate: '09/24',
		// 	cvv: '789',
		// 	default: false,
		// 	issuer: 'Visa',
		// },
		// {
		// 	nameOnCard: 'Sophia Davis',
		// 	cardNumber: '8765 4321 9876 5432',
		// 	expirationDate: '05/22',
		// 	cvv: '123',
		// 	default: false,
		// 	issuer: 'Amex',
		// },
		// Add more payment methods as needed
	];

	const getIssuerIcon = (issuer: any) => {
		const lowercaseIssuer = issuer.toLowerCase();

		if (lowercaseIssuer.match(/americanexpress|amex/)) {
			return <AmericanExpress />;
		} else if (lowercaseIssuer.match(/visa/)) {
			return <Visa />;
		} else if (lowercaseIssuer.match(/mastercard|master card/)) {
			return <MasterCard />;
		}

		// Default icon or handle other issuers as needed
		return;
	};

	return (
		<div>
			{paymentMethods.length > 0 ? (
				<div className="max-w-[630px]">
					<div>
						<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px]">My Payment Methods</h4>
					</div>
					<div>
						{paymentMethods.map((method, index) => (
							<div key={index} className="p-[40px] mb-[20px] bg-white rounded-[12px]">
								<h4 className="font-bold text-3xl font-notoSans leading-9 tracking-normal text-left mb-6">Payment method</h4>
								<div className="flex flex-row p-5">
									<div className="mt-[15px]">{getIssuerIcon(method.issuer)}</div>
									<div className="ml-[20px]">
										<div className="flex mb-[5px]">
											<div>{'**** **** ****' + method.cardNumber.slice(14)}</div>
										</div>
										<div className="flex">
											<div>Expires: {method.expirationDate}</div>
										</div>
									</div>
								</div>
								<div className="flex flex-row mt-[20px] space-x-[10px]">
									<div>
										<button className="text-laykaPurple border-r border-laykaLight pr-[20px]" onClick={() => navigate('/profile/editpaymentmethod')}>
											Edit
										</button>
									</div>
									<div>
										<button className="text-laykaPurple  px-[20px]">Delete</button>
									</div>
								</div>
							</div>
						))}
					</div>
					<div>
						<button className="w-[343px] h-[44px] border rounded-[30px] text-laykaPurple text-base font-bold leading-5 text-center font-noto-sans" onClick={() => navigate('/profile/newpaymentmethod')}>
							ADD NEW CARD
						</button>
					</div>
				</div>
			) : (
				<div>
					<div className="flex flex-col items-center justify-center w-full">
						<NoPaymentMethods />
						<h4 className="font-bold text-3xl font-notoSans leading-9 tracking-normal max-w-[429px] text-center mb-4">There are no payment methods added yet...</h4>
						<p className="max-w-[429px] mb-[30px] text-[14px] leading-[24px] font-notoSans text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </p>
						<button className="w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-4" onClick={() => navigate('/profile/newpaymentmethod')}>
							ADD PAYMENT METHOD
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default PaymentMethods;
