import { fabric } from 'fabric';

export const FONTS = ['Amarante', 'Black Ops One', 'Bree Serif', 'Cantora One', 'Courgette', 'Francois One', 'Grand Hotel', 'Grand Vibes', 'Khand', 'Limelight', 'Lobster', 'Oregano', 'Oswald', 'Playball', 'Rubik One', 'Sacramento', 'Sancreek'];
export const DEFAULT_NEW_TEXT = 'New text';
export const FabricTypeConverter: any = {
	'i-text': 'editText',
	textbox: 'editText',
	'i-image': '',
};
export const TEXT_COLORS = ['#212121', '#FFFFFF', '#DE901C', '#4EDE1C', '#C52A2A', '#2AC5BB', '#522AC5', '#B82AC5'];
export const DEFAULT_COLOR = '#212121';
export const DEFAULT_TEXT_CONFIG = {
	editable: true,
	hasBorders: true,
	borderColor: 'rgba(255, 255, 255, 1)',
	borderDashArray: [4],
	padding: 0,
	fill: DEFAULT_COLOR,
	splitByGrapheme: true,
};
export const DEFAULT_FONT_SIZE = 12;
export const DELETE_IMAGE_STRING =
	'data:image/svg+xml;base64,' +
	btoa(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#D4C4F6"/>
<path d="M16.2356 7.76478L7.76489 16.2354" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.76489 7.76478L16.2356 16.2354" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`);
//                                   LEFT, TOP, dx , dy
export const FAKE_RESTRICTION_AREA: [LEFT: number, TOP: number, dX: number, dY: number] = [2200, 230, 290, 370];
export const RESTRICTION_AREA_BORDER = 3;

export const HideControls = {
	tl: false,
	tr: false,
	bl: false,
	br: false,
	ml: false,
	mt: false,
	mr: false,
	mb: false,
	mtr: false, //True za rotaciju
};
export const HideControlsTextbox = {
	tl: false,
	tr: false,
	bl: false,
	br: false,
	ml: true,
	mt: false,
	mr: true,
	mb: false,
	mtr: true, //True za rotaciju
};

export const renderIcon = () => {
	const img = new Image();
	img.src = DELETE_IMAGE_STRING;

	return (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) => {
		const size = 24;
		if (img.complete && size > 0) {
			ctx.save();
			ctx.translate(left, top);
			ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as any));
			ctx.drawImage(img, -size / 2, -size / 2, size, size);
			ctx.restore();
		}
	};
};

export const deleteObject = (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => {
	const target = transformData.target;
	const canvas = target?.canvas;
	if (canvas && target) {
		canvas?.remove(target);
		canvas?.requestRenderAll();
		return true;
	}
	return false;
};

export const DELETE_CONTROL = new fabric.Control({
	x: -0.5,
	y: -0.5,
	offsetY: -0,
	offsetX: -0,
	cursorStyle: 'pointer',
	mouseUpHandler: deleteObject,
	render: renderIcon(),
});

export const getCurrentPrice = (commercialOptions: any) => {
	const now = new Date();
	const validFrom = new Date(commercialOptions?.validFrom);
	const validTo = new Date(commercialOptions?.validTo);

	// Check if current date is within the valid date range
	if (now >= validFrom && now <= validTo) {
		// Check if there is a sale price and it's not "0.00"
		if (commercialOptions?.salePrice && commercialOptions?.salePrice !== '0.00') {
			return commercialOptions?.salePrice;
		}
	}
	return commercialOptions?.price;
};
export const FAKE_SYMBOLS = [
	'https://files.upstrivesystem.com/public/bells.svg',

	'https://files.upstrivesystem.com/public/candy.svg',
	'https://files.upstrivesystem.com/public/christmas-decorations-for-trees-1.svg',
	'https://files.upstrivesystem.com/public/christmas-decorations-for-trees.svg',
	'https://files.upstrivesystem.com/public/christmas-deer-1.svg',
	'https://files.upstrivesystem.com/public/christmas-deer.svg',
	'https://files.upstrivesystem.com/public/christmas-bird.svg',
	'https://files.upstrivesystem.com/public/christmas-candy.svg',
];
export const VIRTUAL_CANVAS_SIZE = 500;
export const INITIAL_SCALE_FACTOR = 1.1;
export const LINE_CONFIGURATION = {
	stroke: 'rgba(255, 255, 255, 1)',
	strokeWidth: 3,
	selectable: false,
	hoverCursor: 'default',
	moveCursor: 'default',
	name: 'restriction_area',
};

export const productTypeLabelConverter: any = {
	newYear: 'New Year',
	bar: 'Bar',
	office: 'Office',
	wallDecor: 'Wall Decor',
	home: 'Home',
	kitchen: 'Kitchen',
	clothes: 'Clothes',
	baby: 'Baby',
	giftIdeas: 'Gift Ideas',
};
export const MerchantID = '1732074';
export const TerminalID = 'E7058699';
export const CurrencyID = 941;
