import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { deleteCustomer } from '../../../../server/server';
import { useNavigate } from 'react-router-dom';
import { CustomerData } from '../UserData';

interface UserDataProps {
	setSelectedLink?: React.Dispatch<React.SetStateAction<string>>;
	setUpdatedCustomerData?: React.Dispatch<React.SetStateAction<CustomerData>>;
	customer: any;
	updatedCustomerData?: CustomerData;
	logout?: () => void;
	invalidEmail: any;
}

const Accounts: React.FC<UserDataProps> = ({ setSelectedLink, customer, logout = () => {}, updatedCustomerData, setUpdatedCustomerData, invalidEmail }) => {
	const navigate = useNavigate();

	const handleEmailChange = (newEmail: string) => {
		if (setUpdatedCustomerData) {
			setUpdatedCustomerData((prevData: any) => ({
				...prevData,
				email: newEmail,
			}));
		}
	};

	const deleteAccount = async () => {
		if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
			//const resp = await deleteCustomer(customer?.id);

			if (/*resp.status == 200*/ window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
				navigate('/login');
			}
		}
	};
	return (
		<div>
			<div className="mt-[20px]">
				<button className="text-laykaPurple" onClick={logout}>
					Sign out
				</button>
			</div>
			<div>
				<div className="font-noto-sans text-base font-bold leading-6 mt-[20px]">
					<h2>My account</h2>
				</div>
				<div className="flex flex-col  mt-[20px]">
					<div>
						<TextField
							label="Email"
							variant="standard"
							className="xxs:w-[120px] xs:w-[260px] md:w-[522px]"
							value={updatedCustomerData?.email}
							InputLabelProps={{ shrink: !!updatedCustomerData?.email }}
							onChange={(e) => handleEmailChange(e.target.value)}
							error={invalidEmail}
							helperText={invalidEmail && 'Email must be a valid format'}
						/>
					</div>
					<div className="mt-[20px]">
						<TextField label="Password" type="password" variant="standard" className="xxs:w-[120px] xs:w-[260px] md:w-[522px]" value={'12345678'} disabled />
					</div>
				</div>
				<div className="flex flex-col  mt-[20px]">
					<div>
						<button className="text-laykaPurple" onClick={() => navigate('/profile/passwordchange')}>
							Update password
						</button>
					</div>
					<div className="mt-[20px]">
						<button className="text-laykaPurple" onClick={deleteAccount}>
							Delete Account
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Accounts;
