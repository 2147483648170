// import {Reacct} from 'react';

import { useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import SignInPhoto from '../../assets/images/SignIn.webp';
import SignUpPhoto from '../../assets/images/SignUp.webp';
import ForgotPasswordPhoto from '../../assets/images/ForgotPassword.webp';
import { useEffect, useRef, useState } from 'react';
import LoginSection from './login-screens/LoginSection';
import Registration from './login-screens/Registration';
import ForgotPassword from './login-screens/ForgotPassword';
import ResetPassword from './login-screens/ResetPassword';
import SEO from '../seo/seo';

export default function Login() {
	let navigate = useNavigate();
	const { setUser } = useUser();
	const [selectedMode, setSelectedMode] = useState('login');
	const [selectedImage, setSelectedImage] = useState(SignInPhoto);
	const containerRef = useRef<any>(null);

	const handleImage = (mode: string) => {
		setSelectedMode(mode);
		if (mode === 'login' || mode === 'country') setSelectedImage(SignInPhoto);
		else if (mode === 'registration' || mode === 'resetPassword') setSelectedImage(SignUpPhoto);
		else if (mode === 'forgotPassword') setSelectedImage(ForgotPasswordPhoto);
	};

	useEffect(() => {
		// containerRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}, [selectedMode]);

	return (
		<div className=" w-full  grid grid-cols-1 xl:grid-cols-2   bg-white">
			<SEO title="Login - Layka" />
			<div className=" bg-white hidden xl:block">
				<img src={selectedImage} alt="" className="w-full  object-contain h-full " />
			</div>
			<div className="h-full py-10 xl:py-0" ref={containerRef}>
				{selectedMode === 'login' && <LoginSection setSelectedMode={handleImage} />}
				{selectedMode === 'registration' && <Registration setSelectedMode={handleImage} />}
				{selectedMode === 'forgotPassword' && <ForgotPassword setSelectedMode={handleImage} />}
				{selectedMode === 'resetPassword' && <ResetPassword setSelectedMode={handleImage} />}
			</div>
		</div>
	);
}
