import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import { SubmitHandler, useForm } from 'react-hook-form';
import { login, whoAmI } from '../../../server/server';
import Loading from '../../../common/Loading';
import { ReactComponent as ShowPasswordIcon } from '../../../assets/icons/ShowPasswordIcon.svg';
import { ReactComponent as HidePasswordIcon } from '../../../assets/icons/HidePasswordIcon.svg';

type Inputs = {
	email: string;
	password: string;
};
interface SignIn {
	setSelectedMode: any;
}

function LoginSection({ setSelectedMode }: SignIn) {
	const navigate = useNavigate();
	const { setUser } = useUser();
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<Inputs>({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const onSubmit: SubmitHandler<Inputs> = async () => {
		loginUser();
	};

	const loginUser = async () => {
		setLoading(true);
		const resp = await login({
			email: getValues('email'),
			password: getValues('password'),
		});
		if (resp?.status === 201) {
			const data = resp.data;
			setErrorMessage('');
			localStorage.setItem('accessToken', data['accessToken']);
			const token = localStorage.getItem('accessToken');
			const response = await whoAmI();
			if (response.status === 200) {
				console.log('who am i success ', response.data);
				const user = response.data;
				if (user.valid === false) {
					setErrorMessage('Vaš nalog nije verifikovan. Molimo vas proverite vas inbox');
					setLoading(false);
					localStorage.removeItem('accessToken');
					return;
				}
				setUser(response.data);
				localStorage.setItem('user', JSON.stringify(response.data));
				navigate('/');
			} else {
				console.log('who am i failed ', response.status);
			}
		} else {
			// console.log('login error', resp?.data?.message);
			setErrorMessage(resp?.data?.message);
			// if (resp.data?.message === 'Please check your login credentials') setErrorMessage('Molim vas proverite unete podatke');
		}
		setLoading(false);
	};
	return (
		<div className="w-full h-full bg-white flex flex-col items-center justify-center">
			<div className="flex flex-col max-w-[430px] w-full px-3 ">
				<h1 className="text-[30px] leading-[36px] text-black  font-bold  ">Sign in</h1>
				<form className="flex flex-col items-center w-full mt-[50px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<div className="flex flex-col w-full">
						<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
							<input
								className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
								placeholder="Email"
								{...register('email', {
									required: true,
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Invalid email address',
									},
								})}
							/>
						</div>
						<span className="text-redError text-[12px] leading-5 h-5 ">
							{errors.email?.type === 'required' ? 'Field is required!' : ''}
							{errors.email?.type === 'pattern' ? 'Email is not valid!' : ''}
						</span>
					</div>
					<div className="flex flex-col w-full">
						<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center ">
							<input
								className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-placeholderGrey "
								placeholder="Password"
								type={showPassword ? 'text' : 'password'}
								{...register('password', { required: true })}
								autoComplete="off"
								onClick={() => setErrorMessage('')}
							/>
							<div className="cursor-pointer" onClick={toggleShowPassword}>
								{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon width={22} height={18} />}
							</div>
						</div>
						<span className="text-redError text-[12px] leading-5 h-5 ">{errors.password?.type === 'required' ? 'Field is required!' : ''}</span>
					</div>

					<p className="text-redError text-[12px] leading-5 h-5 text-center">{!!errorMessage ? errorMessage : ''}</p>
					<button type="submit" className="w-full h-[44px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple hover:bg-orange400 text-[16px] text-white font-bold cursor-pointer mt-[10px] uppercase" disabled={loading}>
						{loading ? <Loading height={25} /> : 'Sign in'}
					</button>
					<div className="w-full flex flex-row items-center justify-center mt-5">
						<div className="text-[16px] leading-[20px] text-black font-bold cursor-pointer" onClick={() => setSelectedMode('forgotPassword')}>
							Forgot password?
						</div>
					</div>
				</form>
				<p className=" text-[16px] text-black700 leading-5 font-normal  text-center mt-10 ">Don’t have an account?</p>
				<button
					type="button"
					className="w-full flex items-center justify-center  bg-white text-[16px] text-laykaDarkPurple font-bold cursor-pointer mt-[10px] "
					onClick={() => {
						setSelectedMode('registration');
					}}
				>
					Create account
				</button>
			</div>
		</div>
	);
}

export default LoginSection;
