import React, { useEffect, useState } from 'react';
import { FAKE_SYMBOLS } from '../../Constants';
import Loading from '../../common/Loading';
import { getAllSymbols, getImage } from '../../server/server';

const AddSymbol = ({ addSymbol }: { addSymbol: ({ symbol }: { symbol: string }) => void }) => {
	const [symbols, setSymbols] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getSymbols();
	}, []);

	const getSymbols = async () => {
		const resp = await getAllSymbols();
		if (resp.status === 200) {
			setSymbols(resp.data);
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-row flex-wrap gap-[24px] px-[50px] py-[30px]">
			{symbols.map((symbol: any) => (
				<SymbolButton symbol={getImage(symbol?.image)} addSymbol={addSymbol} key={symbol?.name} />
			))}
		</div>
	);
};

const SymbolButton = ({ symbol = '', addSymbol }: { symbol: string; addSymbol: ({ symbol }: { symbol: string }) => void }) => {
	const [loaded, setLoaded] = useState(false);
	return (
		<button className=" w-[70px] h-[70px] transition-all duration-[230ms] hover:scale-[1.35] relative" onClick={() => addSymbol({ symbol })}>
			<img src={symbol} alt="Symbol" width={70} height={70} className="object-fill" onLoad={() => setLoaded(true)} />
			{!loaded && (
				<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
					<Loading height={40} />
				</div>
			)}
		</button>
	);
};

export default AddSymbol;
