import React, { useState } from 'react';
import '../assets/style/ScrollingBanner.css'; // Uvezi CSS
import { ReactComponent as LaykaStar } from '../assets/icons/laykaStar.svg';

const ScrollingBanner = ({ text = 'BRING YOUR GIFTS TO LIFE', animationDuration = '20s' }: { text?: string; animationDuration?: string }) => {
	const [array, setArray] = useState(new Array(100).fill(''));
	return (
		<div className="banner-container bg-[#21187F] h-[60px] flex flex-col justify-center">
			<div className="scrolling-banner items-center" style={{ animationDuration }}>
				{array.map((e) => (
					<div className="flex flex-row gap-[6px] items-center">
						<span className="font-bold text-[20px] text-[white]">{text}&nbsp;</span>
						<LaykaStar />
					</div>
				))}
			</div>
		</div>
	);
};

export default ScrollingBanner;
