export const NoOrders = () => {
	return (
		<svg width="241" height="264" viewBox="0 0 241 264" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_916_23097)">
				<path d="M180.074 155.521L199.339 130.731L147.436 148.704" stroke="black" stroke-width="1.3197" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M91.0502 169.546L72.6504 162.255V217.763L91.0384 225.196C91.6312 204.081 91.3585 188.041 91.0502 169.546Z" fill="#E3DCF3" />
				<path
					d="M97.7571 163.88C97.7571 163.252 97.5674 162.624 97.2355 162.09C94.3308 157.324 88.8891 147.603 86.1624 142.955L74.9707 138.569C77.8753 143.335 79.9145 146.844 82.6531 151.503C85.2376 155.902 87.9051 160.442 90.6556 164.971C90.6793 166.251 90.703 167.52 90.7149 168.777L97.8757 171.61C97.8401 169.049 97.7927 166.477 97.7571 163.892V163.88Z"
					fill="#F89DBD"
				/>
				<path d="M97.8748 171.599L90.714 168.766C91.0223 187.261 91.2949 203.301 90.7021 224.416L97.7206 227.249C97.7206 227.155 97.7562 227.072 97.7562 226.977C98.3253 206.81 98.1593 189.406 97.8748 171.599Z" fill="#F89DBD" />
				<path d="M156.469 171.763L125.277 183.097L98.1991 172.38C98.4717 190.199 98.6496 207.591 98.0805 227.757C98.0805 227.852 98.0568 227.935 98.0449 228.03L125.277 239.032L156.943 225.481C156.766 225.054 156.659 224.592 156.659 224.094L156.469 171.751V171.763Z" fill="#E3DCF3" />
				<path d="M163.773 222.576L182.623 214.514V162.255L163.583 169.191L163.773 222.576Z" fill="#E3DCF3" />
				<path d="M183.631 136.173L163.583 168.776V169.179L163.785 222.576L156.968 225.493C156.79 225.066 156.683 224.604 156.683 224.106L156.494 171.763V167.744C156.482 167.057 156.672 166.381 157.051 165.8L174.396 138.983L183.655 136.173H183.631Z" fill="#F89DBD" />
				<path d="M182.623 214.515L125.29 239.044V176.34L72.651 155.521L47.5527 128.455L116.528 155.521" stroke="black" stroke-width="1.3197" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M192.06 104.945C197.122 103.286 202.872 103.819 207.531 106.392C212.202 108.964 215.723 113.529 217.027 118.698C217.762 121.602 217.715 124.969 215.783 127.258C213.85 129.546 209.606 129.818 208.124 127.21C206.5 124.341 209.203 120.856 212.107 119.279C218.284 115.924 226.334 116.362 232.12 120.346C237.905 124.341 241.165 131.715 240.229 138.674"
					stroke="black"
					stroke-width="1.3197"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M177.726 2.80945C171.372 9.25888 167.447 18.0439 166.89 27.0659C166.784 28.8443 166.819 30.7175 167.673 32.2705C168.526 33.8236 170.447 34.938 172.119 34.3215C174.063 33.6221 174.656 30.9071 173.672 29.0932C172.688 27.2793 170.625 26.3072 168.586 25.9278C164.116 25.1098 159.279 26.8525 156.351 30.3262C153.422 33.7999 152.533 38.8622 154.086 43.1302"
					stroke="black"
					stroke-width="1.3197"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M41.9092 90.2447C43.3674 86.3205 48.3586 84.4592 52.3539 85.7159C56.3493 86.9726 59.2183 90.6834 60.3802 94.7143C61.0559 97.0617 61.1982 99.6817 60.0719 101.839C58.9456 103.997 56.2426 105.444 53.9663 104.566C50.5519 103.262 50.7297 97.7256 53.7173 95.6271C56.7168 93.5287 60.9137 94.2163 64.1265 95.9591C72.7574 100.618 76.5037 112.592 72.046 121.342"
					stroke="black"
					stroke-width="1.3197"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M42.7275 155.367C24.0787 155.877 6.42571 169.037 0.592773 186.761" stroke="black" stroke-width="1.3197" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M142.16 222.09C143.986 238.972 154.17 254.752 168.811 263.348" stroke="black" stroke-width="1.3197" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M53.8237 250.923C56.0892 250.923 57.9257 249.086 57.9257 246.821C57.9257 244.555 56.0892 242.719 53.8237 242.719C51.5582 242.719 49.7217 244.555 49.7217 246.821C49.7217 249.086 51.5582 250.923 53.8237 250.923Z" stroke="black" stroke-width="1.3197" />
				<path d="M199.339 246.821C201.605 246.821 203.441 244.984 203.441 242.719C203.441 240.453 201.605 238.617 199.339 238.617C197.074 238.617 195.237 240.453 195.237 242.719C195.237 244.984 197.074 246.821 199.339 246.821Z" stroke="black" stroke-width="1.3197" />
				<path d="M219.269 89.8651C221.534 89.8651 223.371 88.0285 223.371 85.763C223.371 83.4975 221.534 81.661 219.269 81.661C217.004 81.661 215.167 83.4975 215.167 85.763C215.167 88.0285 217.004 89.8651 219.269 89.8651Z" stroke="black" stroke-width="1.3197" />
				<path d="M155.486 118.556C157.751 118.556 159.588 116.719 159.588 114.454C159.588 112.188 157.751 110.352 155.486 110.352C153.22 110.352 151.384 112.188 151.384 114.454C151.384 116.719 153.22 118.556 155.486 118.556Z" stroke="black" stroke-width="1.3197" />
				<path d="M162.752 90.2289C137.161 94.0044 134.21 97.0454 130.577 123.551C126.93 97.0454 123.992 93.9915 98.4014 90.2289C123.992 86.4535 126.943 83.4125 130.577 56.9069C134.223 83.4125 137.161 86.4663 162.752 90.2289Z" fill="#21187F" />
				<path
					d="M20.4123 69.7625C20.0902 63.9769 22.255 58.1011 26.2624 53.9133C30.2698 49.7255 36.0554 47.303 41.841 47.3803C45.101 47.419 48.6188 48.4112 50.4743 51.0785C52.3427 53.7458 51.4278 58.2815 48.2837 59.1062C44.8046 60.0082 41.9183 56.194 41.0678 52.7021C39.2767 45.2671 41.9698 36.943 47.7941 31.9821C53.6054 27.0212 62.2645 25.6553 69.3129 28.5932"
					stroke="black"
					stroke-width="1.3197"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M100.627 53.681C98.8875 48.3851 97.1351 43.0891 95.3955 37.7932" stroke="black" stroke-width="1.3197" />
			</g>
			<defs>
				<clipPath id="clip0_916_23097">
					<rect width="241" height="263.941" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
