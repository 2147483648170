import React from 'react';
import { ReactComponent as ShippingIcon } from '../../assets/icons/shippingIcon.svg';

function ShippingInfo({ data = [] }: { data: any[] }) {
	return (
		<div className="flex flex-col gap-[14px]">
			<p className="font-bold text-[20px]">SHIPPING</p>
			{data.length > 0 && (
				<div>
					<div className="flex flex-row gap-[8px] items-center">
						<ShippingIcon />
						<p className="font-bold">Processing & Delivering:</p>
						<p>{data[0].estimatedTime}</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default ShippingInfo;
