import React from 'react';
import UserProfile from './UserProfile';
import { useUser } from '../../auth/UseUser';
import ProfileNavigation from './ProfileNavigation';
import { Outlet } from 'react-router';
import UserNotLoggedIn from './UserNotLoggedIn';
import SEO from '../seo/seo';

const ProfileLayout = () => {
	const { isSignedIn } = useUser();
	return (
		<div className="bg-laykaBackround ">
			<SEO title="Korisnik - Layka" />
			<div className="flex flex-row max-w-[1440px] mx-auto overflow-hidden px-[40px]">
				<div className="">
					<ProfileNavigation />
				</div>
				<div className="ml-[40px] mt-[108px] mb-[150px] w-full justify-center items-center">{isSignedIn ? <Outlet /> : <UserNotLoggedIn />}</div>
			</div>
		</div>
	);
};

export default ProfileLayout;
