import React, { useLayoutEffect } from 'react';
import { ReactComponent as DecorationLeft } from '../../assets/icons/AboutDecorationLeft.svg';
import { ReactComponent as DecorationRight } from '../../assets/icons/AboutDecorationRight.svg';
import '../../assets/style/About.css';
import { Link } from 'react-router-dom';
import SEO from '../seo/seo';
const Obrazac = require('../../assets/files/Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf');

function PaymentsInfo() {
	useLayoutEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
	});

	return (
		<div>
			<SEO title="Plaćanje i Isporuka - Layka" />
			<div className="h-[240px] bg-[#F1F1FE] flex items-center ">
				<div className="justify-between items-center flex flex-row max-w-[1280px] w-full px-[20px] mx-auto">
					<DecorationLeft />
					<p className="self-center text-[40px] sm:text-[70px] font-bold text-center transition-all ease-in-out">Plaćanje i Isporuka</p>
					<DecorationRight />
				</div>
			</div>
			<div>
				<div className="max-w-[1280px] w-full px-[20px] mx-auto py-[80px]">
					<h1 className="title">NAČINI PLAĆANJA</h1>
					<p className="paragraph font-semibold text-[20px]">Plaćanje na našoj web stranici je sigurno!</p>
					<h2 className="subtitle">Plaćanje platnim karticama</h2>
					<p className="paragraph">
						Svoju porudžbinu možete platiti koristeći Visa, Visa Electron, MasterCard, Amex ili Maestro platnu karticu. Kartica mora biti odobrena od strane banke izdavaoca za online (Internet) plaćanje.
						<br />
						<br />
						Nakon što potvrdite porudžbinu, bićete preusmereni na Raiffeisen banke sistem kartične naplate, na kome vršite unos podataka s platne kartice i obavljate plaćanje. Ukoliko je transakcija uspešna bićete vraćeni na stranicu sa informacijom da je plaćanje uspešno obavljeno. U
						slučaju da plaćanje nije uspelo, bićete vraćeni na našu stranicu sa informacijom o neuspelom plaćanju.
						<br />
						<br />
						Naša prodavnica nikada ne dolazi u kontakt s celovitim podacima o Vašoj platnoj kartici. Takođe, podaci su nedostupni čak i zaposlenima Raiffeisen banke sistema. <br />
						Formular za upis platnih podataka osiguran je SSL transportnom šifrom najveće pouzdanosti. Svi skladišteni podaci dodatno su zaštićeni šifriranjem, korištenjem kriptografskog uređaja sertifikovanog sistema prema FIPS 140-2 Level 3 standardu. Pri plaćanju karticama uvrštenim u
						3-D Secure program Vaša banka uz valjanost same kartice dodatno potvrđuje i Vaš identitet pomoću tokena ili lozinke.
						<br />
						<br />
						Prilikom unošenja podataka o platnoj kartici, poverljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka prilikom kupovine, garantuje
						procesor platnih kartica, Raiffeisen banke , pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu. <br />
					</p>
					<h2 className="subtitle">Plaćanje pouzećem</h2>
					<p className="paragraph">Nudimo Vam opciju plaćanja pouzećem (direktno plaćanje kuriru na adresi isporuke) za kupovinu iz Srbije, BiH i Crne Gore. U ovom slučaju nakon potvrde porudžbine roba će Vam biti poslata kurirskom službom, a Vi ćete je platiti kuriru pri preuzimanju.</p>
					<h2 className="subtitle">Plaćanje uplatnicom ili transferom sredstava direktno na račun</h2>
					<p className="paragraph">
						Plaćanje uplatnicom podrazumeva da svoju narudžbinu možete platiti direktnom uplatom u bilo kojoj pošti ili banci, a transfer da uplatu možete izvršiti prenosom sredstava sa Vašeg računa ako ste korisnik elektronskog bankarstva.{' '}
						<b>
							Nakon uplate molimo vas da sliku uplatnice ili naloga posaljete na <a href="mailto:sales@laykagift.com">sales@laykagift.com</a>
						</b>
						. Po evidentiranju Vaše uplate, roba se šalje na izabranu adresu za dostavu.
						<br />
						<br />
						<b>Za plaćanje opštom uplatnicom ili elektronskim bankarstvom neophodno je popuniti sledeće podatke:</b>
						<br />
						<br />
						<b>Za plaćanja na dinarski račun iz Republike Srbije:</b>
						<br />
						<br />
						Primalac: <b>AIO PRO d.o.o. BEOGRAD</b>
						<br />
						Svrha uplate: <b>Uplata za poručenu robu (sa naznakom proizvoda koji ste kupili)</b>
						<br />
						Šifra uplate: <b>121</b>
						<br />
						Valuta: <b>RSD</b>
						<br />
						Iznos: <b>Ukupan iznos Vaše porudžbine</b>
						<br />
						Račun broj: <b>265-6250310001139-79 Raiffeisen banke Beograd</b>
						<br />
						Poziv na broj: <b>Broj Vaše porudžbine *</b>
						<br />
						<br />
						<i>*Broj porudžbine postaće Vam dostupan pošto kliknete na dugme ZAVRŠITE KUPOVINU.</i>
					</p>
					<h2 className="subtitle">Izjava o konverziji</h2>
					<p className="paragraph">
						Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD).
						<br />
						<br />
						Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u trenutku
						transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu.
					</p>
					<h2 className="subtitle">Zamena artikla</h2>
					<p className="paragraph">
						Ukoliko ste nakon prijema artikla ustanovili da želite da ga zamenite za drugi artikal iste ili veće cenovne kategorije, molimo Vas da nam pošaljite email na adresu: <a href="mailto:sales@laykagift.com">sales@laykagift.com</a> sa Vašim broj porudžbine, šifrom artikla koji
						želite da zamenite i šifrom artikla za koji želite da zamenite prvobitno poručeni artikal.
						<br />
						<br />
						Nakon toga, vrši se fizička provera dostupnosti željenog artikla nakon čega dobijate instrukcije na Vašu email adresu gde će Vam biti objašnjen čitav proces zamene artikla za drugi.
						<br />
						<br />
						Poručeni artikal nije moguće menjati ukoliko je korišćen, odnosno prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju. Takodje s obzirom da su u pitanju personalizovani pokloni napravljeni samo za vas, nije ih
						moguce zameniti ukoliko nije greska prilikom same proizvodnje proizvoda, ili ostecenosti usled transporta, jer se isti ne mogu vise prodati.
						<br />
						<br />
						Nakon prijave za zamenu artikla, neophodno je robu adekvatno upakovati i poslati po kurirskoj službi, zajedno sa odštampanom, popunjenom dokumentacijom koju ste dobili na e-mail adresu pošto ste poslali zahtev za zamenu artikla. Onog momenta kada se prvobitna roba vrati na
						stanje, biće Vam poslat zamenski artikal.
					</p>
					<h2 className="subtitle">Rok zamene</h2>
					<p className="paragraph">Rok za zamenu artikla je 14 dana od trenutka podnošenja zahteva od strane kupca, a nakon prijema proizvoda.</p>
					<h2 className="subtitle">Troškovi isporuke</h2>
					<p className="paragraph">
						Troškove zamene robe i novca snosi kupac, osim u slučajevima kada kupac dobije neispravan ili pogrešan artikal. Ukoliko je kupac pogrešio prilikom odabira artikla, prilikom preuzimanja robe, kupac plaća iznos jedne poštarine po zvaničnom cenovniku kurirske službe. Poštarinu
						plaćate gotovinom kuriru koji bude preuzimao robu za zamenu.
						<br />
						<br />
						<b>NAPOMENA</b>: Potvrdom porudžbine, klikom na dugme ZAVRŠITE KUPOVINU, saglasni ste sa uslovima zamene artikla za drugi.
					</p>
					<h2 className="subtitle">Pravo na odustajanje / povraćaj robe</h2>
					<p className="paragraph">
						Poštovani kupci, Obaveštavamo Vas da se prema Zakonu o zaštiti potrošača (<i>Službeni glasnik RS, br. 62/2014</i>), kupovina preko naše prodajne internet stranice <a href="www.laykagift.com">www.laykagift.com</a> smatra prodajom na daljinu.
						<br />
						<br />
						Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se smatra potrošačem (fizičko lice koje proizvod kupuje radi namirenja svojih individualnih potreba, a ne radi obavljanja profesionalne delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu je
						proizvod predat. Prilikom odustanka kupac može, ali ne mora da navede razloge zbog kojih odustaje.
						<br />
						<br />
						Obrazac/Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata trgovcu.
						<br />
						<br />
						Obrazac/izjavu o odustanku možete preuzeti{' '}
						<a href={Obrazac} target="_blank" rel="noreferrer" download={'Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf'}>
							<b>ovde</b>
						</a>
						. Nakon popunjavanja obrasca, isti je potrebno poslati skeniran na <a href="mailto:office@laykagift.com">office@laykagift.com</a>
						<br />
						<br />
						U slučaju odustaka od ugovora, potrošač ima pravo na povraćaj novca ili na zamenu za drugi proizvod. Cena se kupcu vraća po prijemu proizvoda, i nakon što se utvrdi da je proizvod neoštećen i ispravan.
						<br />
						<br />
						Kupac je dužan da proizvod vrati bez odlaganja, a najkasnije u roku od <b>14 dana</b> od dana kada je poslao obrazac/izjavu o odustanku. Po isteku roka od 14 dana od dana kada je poslao odustanak, proizvod se više ne moze vratiti.
						<br />
						<br />
						Prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju, pri čemu se mora priložiti i račun-otpremnica koju je kupac prethodno dobio uz porudžbinu.
						<br />
						<br />
						Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Kupac odgovara za neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja proizvodom, tj. kupac je isključivo odgovoran za umanjenu vrednost proizvoda koja nastane kao posledica
						rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono što je neophodno da bi se ustanovili njegova priroda, karakteristike i funkcionalnost. Ukoliko se utvrdi da je nastupila neispravnost ili oštećenje proizvoda krivicom kupca, odbiće se vraćanje cene i
						proizvod će mu biti vraćen na njegov trošak.
						<br />
						<br />
						Trgovac je dužan da potrošaču bez odlaganja vrati iznos koji je potrošač platio po osnovu ugovora, a najkasnije u roku od 14 dana od dana prijema obrasca/izjave o odustanku, a nakon prijema proizvoda.
						<br />
						<br />
						Troškove vraćanja robe i novca – direktni troškovi, snosi kupac, sem u slučajevima kada kupac dobije neispravan ili pogrešan artikal.
						<br />
						<br />U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, laykagift.com je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači
						da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice.
					</p>
					<br />
					<br />
					<h2 className="subtitle">USLOVI ISPORUKE</h2>
					<h3 className="subsubtitle">Gde se roba isporučuje?</h3>
					<p className="paragraph">
						Proizvodi se šalju na sve lokacije u svetu. Naručene artikle sa opcijom plaćanja pouzećem isporučujemo samo na teritoriji <b>Srbije, BiH i Crne Gore</b>. Isporuka artikala za sve ostale lokacije na svetu je moguća, ali uz prethodno obavljenu kupovinu platnim karticama preko
						sajta ili uplatom preko uplatnice ili računa.
					</p>
					<h3 className="subsubtitle">Rok isporuke</h3>
					<p className="paragraph">
						Nakon što ste završili proces poručivanja klikom na dugme "Završite kupovinu", na svoju e-mail adresu, koju ste prijavili prilikom popunjavanja podataka, automatski ćete dobiti potvrdni mail o uspešnoj kupovini. Time je Vaša porudžbina ušla u proces obrade i slanja i isporuku
						možete očekivati u roku od 2-4 radna dana (za porudžbine na teritoriji Republike Srbije). Isporuke u druge zemlje možete očekivati u roku od 1 do 2 nedelje (ne računajući i potencijalna zadržavanja na carini).
						<br />
						<br />
						Samo u slučaju nejasnoća ili bilo kakvih problema vezanih za Vašu porudžbinu, kontaktiraće Vas naša služba podrške.
						<br />
						<br />
						<b>Rok isporuke porudžbina za Republiku Srbiju je 2-4 radnih dana</b>, dani vikenda (subota i nedelja) se ne računaju.
					</p>
					<h3 className="subsubtitle">Rok isporuke</h3>
					<p className="paragraph">
						Kuriri pošiljke donose na adresu za isporuku u periodu od 8-19 h. Prilikom preuzimanja pošiljke potrebno je da vizuelno pregledate paket da slučajno ne postoje neka vidna oštećenja. Ukoliko primetite da je transportna kutija značajno oštećena i posumnjate da je proizvod možda
						oštećen, odbijte prijem pošiljke i odmah nas obavestite. Ukoliko je pošiljka naizgled bez oštećenja slobodno preuzmite pošiljku i potpišite kuriru adresnicu. Ukoliko Vas ne pronađe na adresi, porudžbina će kraći rok ostati u skladištu kurirske službem a nakon toga će se
						vratiti nama. Po prijemu porudžbine, kontkatiraćemo Vas kako bi ustanovili razlog neuručenja i dogovoriti se o ponovnom slanju.
					</p>
					<h3 className="subsubtitle">Cena isporuke</h3>
					<p className="paragraph">Cena isporuke za sve porudžbine zavisi od lokacije za dostavu kao i od vrednosti i težine kupljenih proizvoda.</p>
				</div>
			</div>
		</div>
	);
}

export default PaymentsInfo;
