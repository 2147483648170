import React from 'react';
import { UserProvider } from '../auth/UseUser';
import { CartProvider } from '../hooks/useCart';
import { MenuItemsProvider } from '../hooks/useMenuItems';
import { AppContextProvider } from '../hooks/useAppContext';

const AppProviders = ({ children }: any) => {
	return (
		<UserProvider>
			<CartProvider>
				<AppContextProvider>
					<MenuItemsProvider>{children}</MenuItemsProvider>
				</AppContextProvider>
			</CartProvider>
		</UserProvider>
	);
};

export default AppProviders;
