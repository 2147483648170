import React, { useLayoutEffect } from 'react';
import { ReactComponent as DecorationLeft } from '../../assets/icons/AboutDecorationLeft.svg';
import { ReactComponent as DecorationRight } from '../../assets/icons/AboutDecorationRight.svg';
import '../../assets/style/About.css';
import { Link } from 'react-router-dom';
import SEO from '../seo/seo';

function PrivacyPolicy() {
	useLayoutEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
	});

	return (
		<div>
			<SEO title="Politika privatnosti - Layka" />
			<div className="h-[240px] bg-[#F1F1FE] flex items-center ">
				<div className="justify-between items-center flex flex-row max-w-[1280px] w-full px-[20px] mx-auto">
					<DecorationLeft />
					<p className="self-center text-[40px] sm:text-[70px] font-bold text-center transition-all ease-in-out">Politika privatnosti</p>
					<DecorationRight />
				</div>
			</div>
			<div>
				<div className="max-w-[1280px] w-full px-[20px] mx-auto py-[80px] terms-of-use">
					<p className="paragraph font-semibold text-[20px] !mb-0">Vaša porudžbina je potpuno sigurna!</p>
					<p className="paragraph">
						U kompaniji AIO PRO d.o.o. cenimo Vašu privatnost, zato o Vašim podacima izuzetno pažljivo brinemo.
						<br />
						<br />
						Sve posredovane informacije su sigurne, nećemo ih ni pod kojim uslovima ustupati trećem licu, što Vam osigurava potpuno sigurno i bezbedno poslovanje i saradnju sa kompanijom AIO PRO d.o.o.
					</p>
					<h2 className="subtitle">Zaštita privatnosti podataka</h2>
					<p className="paragraph">
						Da bismo uspešno obradili Vašu narudžbinu potrebni su nam Vaše ime i prezime, adresa, e-mail i telefon. Uz pomoć tih podataka bićemo u mogućnosti da Vam isporučimo željenu robu, kao i da Vas obavestimo o trenutnom statusu narudžbine.
						<br />
						<br />
						Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Prijava ili registracija na www.laykagift.com podrazumeva saglasnost
						korisnika da mu šaljemo email, sms obaveštenja. Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li žele ili ne da se izbrišu sa mailing, sms lista koje se koriste za marketinške kampanje.
						<br />
						<br />
						Kompanija AIO PRO d.o.o. će Vaše lične podatke koristiti isključivo u svrhe tehničke administracije web sajta, kako bi Vam dala pristup posebnim informacijama ili za komunikaciju sa Vama. AIO PRO d.o.o. neće prodati podatke koje poseduje trećim licima, niti na tržištu.
						Zaposleni u kompaniji AIO PRO d.o.o. su obavezni da poštuju poverljivost Vaših podataka.
						<br />
						<br />
						Nikada i ni na koji način nećemo zloupotrebiti Vaše privatne informacije. Nećemo predati Vaše podatke za kontakt trećim licima, sem ukoliko se to od nas bude zahtevalo zakonom.
						<br />
						<br />
						Pored navedenih prikupljamo, analiziramo i obrađujemo podatke o proizvodima koje naši posetioci traže i kupuju, kao i o stranicama koje posećuju. Te podatke koristimo da bismo poboljšali ponudu i izgled naših stranica, i omogućili Vam jednostavnije korišćenje, sigurniju i
						udobniju kupovinu.
					</p>
				</div>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
