import React, { useState } from 'react';
import ExpandedModule from '../Common/ExpandedModule';
import ShowImagePreview from './ShowImagePreview';
import UploadImageButton from './UploadImageButton';

type Type = {
	uploadImageObjects: any[];
	imageObjects: any[];
	canvas: any;
	loadingImages: any[];
};

function ManageImages({ imageObjects, uploadImageObjects, canvas, loadingImages }: Type) {
	return (
		<ExpandedModule title="Add your image (s)" initValue={true}>
			<div className="flex flex-col gap-[20px] items-start mt-[20px]">
				<div className="flex flex-row gap-[20px] flex-wrap">
					{loadingImages?.map((e: any, index: number) => <ShowImagePreview obj={e} loading canvas={canvas} key={index} />)}
					{imageObjects?.map((e: any, index: number) => <ShowImagePreview obj={e} canvas={canvas} key={index} />)}
				</div>
				{uploadImageObjects?.map((obj: any, index: number) => {
					const onClick = () => canvas.setActiveObject(obj);
					const hoverElement = () => {};
					const leaveHover = () => {};
					return <UploadImageButton onClick={onClick} key={index} hoverElement={hoverElement} leaveHover={leaveHover} />;
				})}
			</div>
		</ExpandedModule>
	);
}

export default ManageImages;
