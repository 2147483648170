import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { getDraftsById, getImage, getProductDesign } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import { roundToDecimal } from '../../util/util';

function DraftItem({ item, deleteDraft }: { item: any; deleteDraft: any }) {
	const [design, setDesign] = useState<any>(null);
	const navigate = useNavigate();
	const openDraft = async () => {
		navigate(`/product/${item.urlSlug}--${item?.productId}?draftId=${item.id}&standalone=true`);
	};

	const price = useMemo(() => {
		const designPrice = !!design ? design?.price : 0;
		return parseFloat(item?.salePrice ?? item?.price) + designPrice;
	}, [design, item]);

	const loadDesign = async () => {
		const resp = await getProductDesign(item.productDesignId);
		if (resp.status === 200) {
			setDesign(resp.data);
		}
	};

	useEffect(() => {
		if (!!item.productDesignId) loadDesign();
	}, [item]);

	return (
		<button className="flex flex-row justify-between  bg-white p-10 rounded-[12px] mb-5 max-w-[1065px]" onClick={openDraft}>
			<div className="flex flex-row gap-5">
				<div className="w-[147px] h-[147px] ">
					<img src={item?.previewImageId ? getImage(item?.previewImageId) : getImage(item?.fileId)} className="w-[147px] h-[147px] object-cover rounded-md" alt="Custom " />
				</div>
				<div className="flex flex-col justify-center items-start">
					<p className="text-[24px] leading-[30px] font-normal text-black ">{item?.productName}</p>
					<div className="mt-[10px] flex flex-col items-start">
						{!!item.optionsInfo.g1 && (
							<p className="text-[16px] leading-[24px] font-semibold text-black">
								{item?.optionsInfo?.g1}: {item?.optionsInfo?.o1}
							</p>
						)}
						{!!item?.optionsInfo?.g2 && (
							<p className="text-[16px] leading-[24px] font-semibold text-black">
								{item?.optionsInfo?.g2}: {item?.optionsInfo?.o2}
							</p>
						)}
					</div>
					<p className="text-[16px] leading-[24px] font-bold text-black mt-[10px]">
						{roundToDecimal(price)} <span className="lowercase">rsd</span>
					</p>
					<div className="flex-1"></div>
					{/* <button className="text-[16px] leading-[20px] font-semibold text-[#21187F] mt-[20px] text-left" onClick={openDraft}>
						Edit
					</button> */}
				</div>
			</div>
			<div className="flex flex-col items-center justify-center">
				<div
					className="p-[13px] cursor-pointer hover:bg-pink rounded-full duration-[230ms] ease-in-out transition-all"
					onClick={(e) => {
						e.stopPropagation();
						deleteDraft(item);
					}}
				>
					<Trash />
				</div>
			</div>
		</button>
	);
}

export default DraftItem;
