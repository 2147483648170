import React from 'react';
import { ReactComponent as UniqueDesign } from '../../assets/icons/UniqueDesign.svg';
import { ReactComponent as FastDelevery } from '../../assets/icons/FastDelevery.svg';
import { ReactComponent as EaseShopping } from '../../assets/icons/EaseShopping.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/PaymentIcon.svg';

function BottomIcons() {
	return (
		<div className="py-20 bg-[#F1F1FE] flex justify-center">
			<div className="max-w-[1280px] grid lg:grid-cols-4  sm:grid-cols-2 px-[20px] w-full">
				<div className="flex flex-col items-center justify-between gap-10">
					<UniqueDesign title="Unique design" />
					<p className="text-[20px] font-medium text-black leading-[28px] text-center ">Unique design</p>
				</div>
				<div className="flex flex-col items-center justify-center gap-10">
					<FastDelevery title="Fast delivery" />
					<p className="text-[20px] font-medium text-black leading-[28px] text-center ">Fast delivery</p>
				</div>
				<div className="flex flex-col items-center justify-center gap-10">
					<EaseShopping title="Easy shopping" />
					<p className="text-[20px] font-medium text-black leading-[28px] text-center ">Easy shopping</p>
				</div>
				<div className="flex flex-col items-center justify-center gap-10">
					<PaymentIcon title="Easy ordering" />
					<p className="text-[20px] font-medium text-black leading-[28px] text-center ">Easy ordering</p>
				</div>
			</div>
		</div>
	);
}

export default BottomIcons;
