import React, { useMemo, useState } from 'react';
import { ReactComponent as PurpleTimes } from '../../../assets/icons/PurpleTimes.svg';
import Loading from '../../../common/Loading';
type Type = {
	obj: any;
	canvas?: fabric.Canvas;
	loading?: boolean;
};

function ShowImagePreview({ obj, canvas, loading = false }: Type) {
	const imageUrl = useMemo(() => (loading ? null : (obj as any).getElement()?.src), [obj, loading]);

	const deleteObj = () => {
		const rootObj = { ...obj.rootObj };
		const createObjFunction = obj.createObjFunction;
		const oldRootElement = obj.createObjFunction(rootObj);
		// oldRootElement.top = rootObj.top;
		// oldRootElement.left = rootObj.left;
		(oldRootElement as any).rootObj = rootObj; //(oldRootElement as any)?.getObjects()[0];
		(oldRootElement as any).createObjFunction = createObjFunction;
		canvas?.remove(obj);
		canvas?.add(oldRootElement);
		canvas?.requestRenderAll();
	};

	return (
		<div className="rounded-[10px] bg-[#F1F1FEB3] w-[92px] h-[92px] flex items-center justify-center relative overflow-hidden">
			{loading ? (
				<Loading height={30} />
			) : (
				<>
					<button className="top-[5px] right-[5px] w-[14px] h-[15px] rounded-full bg-[#F1F1FE] flex items-center justify-center absolute" onClick={deleteObj}>
						<PurpleTimes />
					</button>
					<img src={imageUrl} width={90} height={90} alt="" />
				</>
			)}
		</div>
	);
}

export default ShowImagePreview;
