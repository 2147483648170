import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';

function ConfirmationModal({ modalIsOpen, afterOpenModal, closeModal, confirmation, message, subMessage = '' }: any) {
	useEffect(() => {
		ReactModal.setAppElement('#root');
	}, []);
	const submitConfirm = () => {
		confirmation();
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel="Example Modal"
			style={{
				overlay: {
					zIndex: 10,
				},
				content: {
					width: 600,
					height: 350,
					padding: 0,
					opacity: 1,
					minWidth: 600,
					margin: 'auto',
					marginTop: '5%',
					backgroundColor: 'white',
					maxHeight: '80%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
				<p className="text-[#04438b] text-2xl font-bold">{message}</p>
				<p className="text-[#81848f] text-sm mt-2">{subMessage}</p>

				<div className="flex flex-row justify-between items-center w-full mt-6">
					<div className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]" onClick={submitConfirm}>
						Потврди
					</div>
					<div className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]" onClick={() => closeModal()}>
						Oткажи
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmationModal;
