import React from 'react';
import { ReactComponent as UploadFile } from '../../../assets/icons/UploadFile.svg';
type Type = {
	onClick: () => void;
	hoverElement?: () => void;
	leaveHover?: () => void;
};
function UploadImageButton({ onClick, hoverElement, leaveHover }: Type) {
	return (
		<button className="gap-[10px] h-[44px] rounded-full px-[60px] bg-[#21187F] flex flex-row items-center justify-center" onClick={onClick} onMouseEnter={hoverElement} onMouseLeave={leaveHover}>
			<UploadFile />
			<span className="text-[16px] text-[white] uppercase font-bold">upload image</span>
		</button>
	);
}

export default UploadImageButton;
