import React, { useLayoutEffect } from 'react';
import { ReactComponent as DecorationLeft } from '../../assets/icons/AboutDecorationLeft.svg';
import { ReactComponent as DecorationRight } from '../../assets/icons/AboutDecorationRight.svg';
import '../../assets/style/About.css';
import { Link } from 'react-router-dom';
import SEO from '../seo/seo';
const Obrazac = require('../../assets/files/Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf');

function TermsOfUse() {
	useLayoutEffect(() => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
	});

	return (
		<div>
			<SEO title="Uslovi кориšćenja - Layka" />
			<div className="h-[240px] bg-[#F1F1FE] flex items-center ">
				<div className="justify-between items-center flex flex-row max-w-[1280px] w-full px-[20px] mx-auto">
					<DecorationLeft />
					<p className="self-center text-[40px] sm:text-[70px] font-bold text-center transition-all ease-in-out">Uslovi кориšćenja</p>
					<DecorationRight />
				</div>
			</div>
			<div>
				<div className="max-w-[1280px] w-full px-[20px] mx-auto py-[80px] terms-of-use">
					<p className="paragraph">Na osnovu odredbe čl.13. i čl.27. Zakona o zaštiti potrošača (Sl.glasnik RS 62/2014), privredno društvo AIO PRO D.O.O BEOGRAD svoje cenjene potrošače koji robu kupuju putem internet sajta www.laykagift.com</p>
					<h3 className="subsubtitle">OBAVEŠTAVA</h3>
					<p className="paragraph">
						<ul>
							<li>
								da se prodaja robe putem internet sajta www.nudeandrude.rs obavlja u okviru registrovane delatnosti privrednog društva AIO PRO d.o.o., Beograd, Despotovacka 56b, Srbija; Mat.br. 21623652; PIB: 112186734; Šifra delatnosti: 181200; Tel.: +381 11 6355200,
								www.laykagift.com, Adresa za izjavljivanje reklamacije je: office@laykagift.com;
							</li>
							<li>da se osnovna obeležja robe mogu naći na sajtu www.laykagift.com;</li>
							<li>da se prema Zakonu o zaštiti potrošača (Službeni glasnik RS br. 62/2014), kupovina preko naše prodajne internet stranice www.nudeandrude.rs smatra prodajom na daljinu;</li>
							<li>da roba koja se prodaje putem internet sajta www.nudeandrude.rs poseduje svojstva potrebna za redovnu upotrebu te vrste robe u skladu sa njenom namenom koja je definisana u Deklaraciji;</li>
							<li>da se saobraznost robe ugovoru ISKLJUČIVO utvrđuje prema svojstvima i nameni robe kako je to definisano pripadajućom Deklaracijom;</li>
							<li>
								da je prodajna cena robe naznačena uz svaki artikal, vidite više niže u sekciji <b>Cena robe</b>;
							</li>
							<li>da se usluga isporuke robe naplaćuje za sve porudzbine, osim ako je to drugačije naznačeno;</li>
							<li>
								kupljenu robu isporučujemo u roku od 2-5 radnih dana putem kurirske službe na način koji je opisan niže u sekciji <b>Uslovi isporuke</b>;
							</li>
							<li>da se mogućnost kupovine robe uz posebne cenovne pogodnosti ili uz posebne prodajne podsticaje (akcijska prodaja), obavlja u skladu sa uslovima koji su objavljeni na internet sajtu www.laykagift.com;</li>
							<li>
								da potrošač robu koju kupuje putem internet sajta www.laykagift.com može platiti platnim karticama, pouzećem gotovinski u trenutku isporuke, kao i opštom uplatnicom u bilo kojoj pošti ili banci ili putem e-bankinga. Svaki tip plaćanja je opisan niže u sekciji Načini
								plaćanja;
							</li>
							<li>da potrošač prilikom kreiranja porudžbenice pritiskom na taster ZAVRŠITE KUPOVINU preuzima obavezu plaćanja naručene robe;</li>
							<li>
								da u slučaju prijema robe potrošač ustanovi da artikal želi da zameni za drugi artikal, koji može biti veće, manje ili iste cenovne kategorije od predhodno poručenog, potrošač može zameniti artikal u roku od 14 dana na način kako je to definisano niže u sekciji Zamena
								artikala;
							</li>
							<li>
								da u slučaju prijema robe potrošač isti ne želi da zadrži, ima pravo na odustanak ugovora i povraćaj sredstava u visini plaćene kupoprodajne cene za predmetnu robu, u roku od 14 dana na način kako je to definisano niže u sekciji{' '}
								<b>Pravo na odustajanje/Povraćaj robe</b>;
							</li>
							<li>
								da za robu koja je poručena putem ONLINE prodavnice potrošač ima pravo da izjavi reklamaciju na već korišćen proizvod, na način koji je definisan za robu kupljenu putem ONLINE prodavnice. Način i postupak izjavljivanja reklamacije možete videti niže u sekciji{' '}
								<i>
									<b>Reklamacije</b>
								</i>
								.
							</li>
						</ul>
					</p>
					<h1 className="title">CENA ROBE</h1>
					<p className="paragraph">
						Cena pojedinačnog artikla nalazi se pod pregledom artikala, na stranici artikla i u korpi. U cenu pojedinačnog artikla uračunat je PDV.
						<br />
						<br />
						Važi ona cena artikla koja je bila vidljiva u vreme kupovine. Sve cene su trenutne i redovno ažurirane.
						<br />
						<br />
						Dostupnost i cene proizvoda na sajtu www.laykagift.com su podložni promenama zbog situacija rasprodatih zaliha, kao i štamparskih grešaka ili pogrešno istaknutih cena. U slučaju pogrešno istaknute cene, nastojaćemo da je ispravimo što je pre moguće.
					</p>
					<h1 className="title">USLOVI ISPORUKE</h1>
					<h2 className="subtitle">Gde se roba isporučuje?</h2>
					<p className="paragraph">
						Proizvodi se šalju na sve lokacije u svetu. Naručene artikle sa opcijom plaćanja pouzećem isporučujemo samo na teritoriji <b>Srbije, Bih i Crne Gore</b>. Isporuka artikala za sve ostale lokacije na svetu je moguća, ali uz prethodno obavljenu kupovinu platnim karticama preko
						sajta ili uplatom preko uplatnice ili računa.
					</p>
					<h2 className="subtitle">Rok isporuke</h2>
					<p className="paragraph">
						Nakon što ste završili proces poručivanja klikom na dugme "Završite kupovinu", na svoju e-mail adresu, koju ste prijavili prilikom popunjavanja podataka, automatski ćete dobiti potvrdni mail o uspešnoj kupovini. Time je Vaša porudžbina ušla u proces obrade i slanja i isporuku
						možete očekivati u roku od 2-4 radna dana (za porudžbine na teritoriji Republike Srbije). Isporuke u druge zemlje možete očekivati u roku od 1 do 2 nedelje (ne računajući i potencijalna zadržavanja na carini).
						<br />
						<br />
						Samo u slučaju nejasnoća ili bilo kakvih problema vezanih za Vašu porudžbinu, kontaktiraće Vas naša služba podrške.
						<br />
						<br />
						<b>Rok isporuke porudžbina za Republiku Srbiju je 2-4 radnih dana</b>, dani vikenda (subota i nedelja) se ne računaju.
					</p>
					<h2 className="subtitle">Preuzimanje pošiljke</h2>
					<p className="paragraph">
						Kuriri pošiljke donose na adresu za isporuku u periodu od 8-19 h. Prilikom preuzimanja pošiljke potrebno je da vizuelno pregledate paket da slučajno ne postoje neka vidna oštećenja. Ukoliko primetite da je transportna kutija značajno oštećena i posumnjate da je proizvod možda
						oštećen, odbijte prijem pošiljke i odmah nas obavestite. Ukoliko je pošiljka naizgled bez oštećenja slobodno preuzmite pošiljku i potpišite kuriru adresnicu. Ukoliko Vas ne pronađe na adresi, porudžbina će kraći rok ostati u skladištu kurirske službem a nakon toga će se
						vratiti nama. Po prijemu porudžbine, kontkatiraćemo Vas kako bi ustanovili razlog neuručenja i dogovoriti se o ponovnom slanju.
					</p>
					<h2 className="subtitle">Cena isporuke</h2>
					<p className="paragraph">Cena isporuke za sve porudžbine zavisi od lokacije isporuke kao i od vrednosti i težine kupljenih proizvoda.</p>
					<h2 className="subtitle">NAČINI PLAĆANJA</h2>
					<p className="paragraph font-semibold text-[20px]">Plaćanje na našoj web stranici je sigurno!</p>
					<h2 className="subtitle">Plaćanje platnim karticama</h2>
					<p className="paragraph">
						Svoju porudžbinu možete platiti koristeći Visa, Visa Electron, MasterCard, Amex ili Maestro platnu karticu. Kartica mora biti odobrena od strane banke izdavaoca za online (Internet) plaćanje.
						<br />
						<br />
						Nakon što potvrdite porudžbinu, bićete preusmereni na Raiffeisen banke sistem kartične naplate, na kome vršite unos podataka s platne kartice i obavljate plaćanje. Ukoliko je transakcija uspešna bićete vraćeni na stranicu sa informacijom da je plaćanje uspešno obavljeno. U
						slučaju da plaćanje nije uspelo, bićete vraćeni na našu stranicu sa informacijom o neuspelom plaćanju.
						<br />
						<br />
						Naša prodavnica nikada ne dolazi u kontakt s celovitim podacima o Vašoj platnoj kartici. Takođe, podaci su nedostupni čak i zaposlenima Raiffeisen banke sistema.
						<br />
						<br />
						Formular za upis platnih podataka osiguran je SSL transportnom šifrom najveće pouzdanosti. Svi skladišteni podaci dodatno su zaštićeni šifriranjem, korištenjem kriptografskog uređaja sertifikovanog sistema prema FIPS 140-2 Level 3 standardu. Pri plaćanju karticama uvrštenim u
						3-D Secure program Vaša banka uz valjanost same kartice dodatno potvrđuje i Vaš identitet pomoću tokena ili lozinke.
						<br />
						<br />
						Prilikom unošenja podataka o platnoj kartici, poverljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka prilikom kupovine, garantuje
						procesor platnih kartica, Raiffeisen banke , pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.
					</p>
					<h2 className="subtitle">Plaćanje pouzećem</h2>
					<p className="paragraph">Nudimo Vam opciju plaćanja pouzećem (direktno plaćanje kuriru na adresi isporuke) za kupovinu iz Srbije, BiH i Crne Gore. U ovom slučaju nakon potvrde porudžbine roba će Vam biti poslata kurirskom službom, a Vi ćete je platiti kuriru pri preuzimanju.</p>
					<h2 className="subtitle">Plaćanje uplatnicom ili transferom sredstava direktno na račun</h2>
					<p className="paragraph">
						Plaćanje uplatnicom podrazumeva da svoju narudžbinu možete platiti direktnom uplatom u bilo kojoj pošti ili banci, a transfer da uplatu možete izvršiti prenosom sredstava sa Vašeg računa ako ste korisnik elektronskog bankarstva.{' '}
						<b>
							Nakon uplate molimo vas da sliku uplatnice ili naloga posaljete na <a href="mailto:sales@laykagift.com">sales@laykagift.com</a>
						</b>
						. Po evidentiranju Vaše uplate, roba se šalje na izabranu adresu za dostavu.
						<br />
						<br />
						<br />
						<b>Za plaćanje opštom uplatnicom ili elektronskim bankarstvom neophodno je popuniti sledeće podatke:</b>
						<br />
						<br />
						Primalac: <b>AIO PRO d.o.o. BEOGRAD</b>
						<br />
						Svrha uplate: <b>Uplata za poručenu robu (sa naznakom proizvoda koji ste kupili)</b>
						<br />
						Šifra uplate: <b>121</b>
						<br />
						Valuta: <b>RSD</b>
						<br />
						Iznos: <b>Ukupan iznos Vaše porudžbine</b>
						<br />
						Račun broj: <b>265-6250310001139-79 Raiffeisen banke Beograd</b>
						<br />
						Poziv na broj: <b>Broj Vaše porudžbine *</b>
						<br />
						<br />
						<i>*Broj porudžbine postaće Vam dostupan pošto kliknete na dugme ZAVRŠITE KUPOVINU.</i>
					</p>
					<h2 className="subtitle">Izjava o konverziji</h2>
					<p className="paragraph">
						Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD).
						<br />
						<br />
						Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u trenutku
						transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu.
					</p>
					<h2 className="subtitle">Zamena artikla</h2>
					<p className="paragraph">
						Ukoliko ste nakon prijema artikla ustanovili da želite da ga zamenite za drugi artikal iste ili veće cenovne kategorije, molimo Vas da nam pošaljite email na adresu: sales@laykagift.com sa Vašim broj porudžbine, šifrom artikla koji želite da zamenite i šifrom artikla za koji
						želite da zamenite prvobitno poručeni artikal.
						<br />
						<br />
						Nakon toga, vrši se fizička provera dostupnosti željenog artikla nakon čega dobijate instrukcije na Vašu email adresu gde će Vam biti objašnjen čitav proces zamene artikla za drugi.
						<br />
						<br />
						Poručeni artikal nije moguće menjati ukoliko je korišćen, odnosno prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju. Takodje s obzirom da su u pitanju personalizovani pokloni napravljeni samo za vas, nije ih
						moguce zameniti ukoliko nije greska prilikom same proizvodnje proizvoda, ili ostecenosti usled transporta, jer se isti ne mogu vise prodati.
						<br />
						<br />
						Nakon prijave za zamenu artikla, neophodno je robu adekvatno upakovati i poslati po kurirskoj službi, zajedno sa odštampanom, popunjenom dokumentacijom koju ste dobili na e-mail adresu pošto ste poslali zahtev za zamenu artikla. Onog momenta kada se prvobitna roba vrati na
						stanje, biće Vam poslat zamenski artikal.
					</p>
					<h2 className="subtitle">Rok zamene</h2>
					<p className="paragraph">Rok za zamenu artikla je 14 dana od trenutka podnošenja zahteva od strane kupca, a nakon prijema proizvoda.</p>
					<h2 className="subtitle">Troškovi isporuke</h2>
					<p className="paragraph">
						Troškove zamene robe i novca snosi kupac, osim u slučajevima kada kupac dobije neispravan ili pogrešan artikal. Ukoliko je kupac pogrešio prilikom odabira artikla, prilikom preuzimanja robe, kupac plaća iznos jedne poštarine po zvaničnom cenovniku kurirske službe. Poštarinu
						plaćate gotovinom kuriru koji bude preuzimao robu za zamenu.
						<br />
						<br />
						<b>NAPOMENA</b>: Potvrdom porudžbine, klikom na dugme ZAVRŠITE KUPOVINU, saglasni ste sa uslovima zamene artikla za drugi.
					</p>
					<h2 className="subtitle">Pravo na odustajanje / povraćaj robe</h2>
					<p className="paragraph">
						Poštovani kupci, Obaveštavamo Vas da se prema Zakonu o zaštiti potrošača (Službeni glasnik RS, br. 62/2014), kupovina preko naše prodajne internet stranice www.laykagift.com smatra prodajom na daljinu.
						<br />
						<br />
						Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se smatra potrošačem (fizičko lice koje proizvod kupuje radi namirenja svojih individualnih potreba, a ne radi obavljanja profesionalne delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu je
						proizvod predat. Prilikom odustanka kupac može, ali ne mora da navede razloge zbog kojih odustaje.
						<br />
						<br />
						Obrazac/Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata trgovcu.
						<br />
						<br />
						Obrazac/izjavu o odustanku možete preuzeti{' '}
						<a href={Obrazac} target="_blank" rel="noreferrer" download={'Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf'}>
							<b>ovde</b>
						</a>
						. Nakon popunjavanja obrasca, isti je potrebno poslati skeniran na <a href="mailto:office@laykagift.com">office@laykagift.com</a>
						<br />
						<br />
						U slučaju odustaka od ugovora, potrošač ima pravo na povraćaj novca ili na zamenu za drugi proizvod. Cena se kupcu vraća po prijemu proizvoda, i nakon što se utvrdi da je proizvod neoštećen i ispravan.
						<br />
						<br />
						Kupac je dužan da proizvod vrati bez odlaganja, a najkasnije u roku od 14 dana od dana kada je poslao obrazac/izjavu o odustanku. Po isteku roka od 14 dana od dana kada je poslao odustanak, proizvod se više ne moze vratiti.
						<br />
						<br />
						Prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju, pri čemu se mora priložiti i račun-otpremnica koju je kupac prethodno dobio uz porudžbinu.
						<br />
						<br />
						Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Kupac odgovara za neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja proizvodom, tj. kupac je isključivo odgovoran za umanjenu vrednost proizvoda koja nastane kao posledica
						rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono što je neophodno da bi se ustanovili njegova priroda, karakteristike i funkcionalnost. Ukoliko se utvrdi da je nastupila neispravnost ili oštećenje proizvoda krivicom kupca, odbiće se vraćanje cene i
						proizvod će mu biti vraćen na njegov trošak.
						<br />
						<br />
						Trgovac je dužan da potrošaču bez odlaganja vrati iznos koji je potrošač platio po osnovu ugovora, a najkasnije u roku od 14 dana od dana prijema obrasca/izjave o odustanku, a nakon prijema proizvoda.
						<br />
						<br />
						Troškove vraćanja robe i novca – direktni troškovi, snosi kupac, sem u slučajevima kada kupac dobije neispravan ili pogrešan artikal.
						<br />
						<br />U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, laykagift.com je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači
						da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice.
					</p>
					<h1 className="title">USLOVI ISPORUKE</h1>
					<h2 className="subtitle">Gde se roba isporučuje?</h2>
					<p className="paragraph">
						Proizvodi se šalju na sve lokacije u svetu. Naručene artikle sa opcijom plaćanja pouzećem isporučujemo samo na teritoriji <b>Srbije, BiH i Crne Gore</b>. Isporuka artikala za sve ostale lokacije na svetu je moguća, ali uz prethodno obavljenu kupovinu platnim karticama preko
						sajta ili uplatom preko uplatnice ili računa.
					</p>
					<h2 className="subtitle">Rok isporuke</h2>
					<p className="paragraph">
						Nakon što ste završili proces poručivanja klikom na dugme "Završite kupovinu", na svoju e-mail adresu, koju ste prijavili prilikom popunjavanja podataka, automatski ćete dobiti potvrdni mail o uspešnoj kupovini. Time je Vaša porudžbina ušla u proces obrade i slanja i isporuku
						možete očekivati u roku od 2-4 radna dana (za porudžbine na teritoriji Republike Srbije). Isporuke u druge zemlje možete očekivati u roku od 1 do 2 nedelje (ne računajući i potencijalna zadržavanja na carini).
						<br />
						<br />
						Samo u slučaju nejasnoća ili bilo kakvih problema vezanih za Vašu porudžbinu, kontaktiraće Vas naša služba podrške.
						<br />
						<br />
						<b>Rok isporuke porudžbina za Republiku Srbiju je 2-4 radnih dana</b>, dani vikenda (subota i nedelja) se ne računaju.
					</p>
					<h2 className="subtitle">Preuzimanje pošiljke</h2>
					<p className="paragraph">
						Kuriri pošiljke donose na adresu za isporuku u periodu od 8-19 h. Prilikom preuzimanja pošiljke potrebno je da vizuelno pregledate paket da slučajno ne postoje neka vidna oštećenja. Ukoliko primetite da je transportna kutija značajno oštećena i posumnjate da je proizvod možda
						oštećen, odbijte prijem pošiljke i odmah nas obavestite. Ukoliko je pošiljka naizgled bez oštećenja slobodno preuzmite pošiljku i potpišite kuriru adresnicu. Ukoliko Vas ne pronađe na adresi, porudžbina će kraći rok ostati u skladištu kurirske službem a nakon toga će se
						vratiti nama. Po prijemu porudžbine, kontkatiraćemo Vas kako bi ustanovili razlog neuručenja i dogovoriti se o ponovnom slanju.
					</p>
					<h2 className="subtitle">Cena isporuke</h2>
					<p className="paragraph">Cena isporuke za sve porudžbine zavisi od lokacije za dostavu kao i od vrednosti i težine kupljenih proizvoda.</p>
					<br />
					<h1 className="title">REKLAMACIJE I POVRAT ROBE</h1>
					<p className="paragraph">
						Prilikom prijema pošiljke, molimo vas da pregledate vaš paket u prisustvu kurira. Ako postoje vidljivi znaci oštećenja (pocepani delovi ili udubljenja), ne bi trebalo da prihvatite paket. U tom slučaju, pošaljite nam e-mail sa vašim detaljima (ime, prezime, broj telefona) na
						office@laykagift.com и navedite razlog zašto ste odbili isporuku. Uskoro ćemo vas kontaktirati sa daljim instrukcijama.
						<br />
						<br />
						Ukoliko ste primili pošiljku i otkrili, po otvaranju paketa, da se isporučena roba ne podudara sa onima koji su poručeni ili da su detalji na fakturi/racunu netačni, molimo vas da nas kontaktirate u roku od 24 sata od prijema paketa na e-mail sa vašim detaljima (ime, prezime,
						broj telefona) na office@laykagift.com i opišu problem. Odgovorićemo poštom ili mejlom u najkraćem mogućem roku, u svakom slučaju najkasnije u roku od 8 dana od prijema reklamacije, i obavestiti vas o daljim koracima. Reklamacije treba da se reše u roku od 14 dana od dana
						prijema.
						<br />
						<br />
						Ukoliko se neusklađinost pojavljuje u smislu odredbi Zakona o zaštiti potrošača o kupljenom proizvodu, molimo vas da nas obavestitena office@laykagift.com ili kontakt telefonom 011 6355200
						<br />
						<br />U slučaju vraćanja robe kupcu koji je predhodno robu platio kuriru tj. pouzećem, troškove vraćanja robe ne snosi AIO PRO d.o.o. već trošak pada na teret kupca.
						<br />
						<br />
						<b>NAPOMENA</b>: Potvrdom porudžbine, klikom na dugme ZAVRŠITE KUPOVINU, saglasni ste sa uslovima reklamacije.
					</p>
					<h2 className="subtitle">Pravo na odustajanje / povraćaj robe</h2>
					<p className="paragraph">
						Poštovani kupci, Obaveštavamo Vas da se prema Zakonu o zaštiti potrošača (Službeni glasnik RS, br. 62/2014), kupovina preko naše prodajne internet stranice www.laykagift.com smatra prodajom na daljinu.
						<br />
						<br />
						Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se smatra potrošačem (fizičko lice koje proizvod kupuje radi namirenja svojih individualnih potreba, a ne radi obavljanja profesionalne delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu je
						proizvod predat. Prilikom odustanka kupac može, ali ne mora da navede razloge zbog kojih odustaje.
						<br />
						<br />
						Obrazac/Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata trgovcu.
						<br />
						<br />
						Obrazac/izjavu o odustanku možete preuzeti{' '}
						<a href={Obrazac} target="_blank" rel="noreferrer" download={'Obrazac_za_odustajanje_od_ugovora_na_daljinu.pdf'}>
							<b>ovde</b>
						</a>
						. Nakon popunjavanja obrasca, isti je potrebno poslati skeniran na <a href="mailto:office@laykagift.com">office@laykagift.com</a>.
						<br />
						<br />U slučaju odustaka od ugovora, potrošač ima pravo na povraćaj novca ili na zamenu za drugi proizvod. Cena se kupcu vraća po prijemu proizvoda, i nakon što se utvrdi da je proizvod neoštećen i ispravan.
						<br />
						<br />
						Kupac je dužan da proizvod vrati bez odlaganja, a najkasnije u roku od <b>14 dana</b> od dana kada je poslao obrazac/izjavu o odustanku. Po isteku roka od 14 dana od dana kada je poslao odustanak, proizvod se više ne moze vratiti.
						<br />
						<br />
						Prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakovanju, pri čemu se mora priložiti i račun-otpremnica koju je kupac prethodno dobio uz porudžbinu.
						<br />
						<br />
						Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Kupac odgovara za neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja proizvodom, tj. kupac je isključivo odgovoran za umanjenu vrednost proizvoda koja nastane kao posledica
						rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono što je neophodno da bi se ustanovili njegova priroda, karakteristike i funkcionalnost. Ukoliko se utvrdi da je nastupila neispravnost ili oštećenje proizvoda krivicom kupca, odbiće se vraćanje cene i
						proizvod će mu biti vraćen na njegov trošak.
						<br />
						<br />
						Trgovac je dužan da potrošaču bez odlaganja vrati iznos koji je potrošač platio po osnovu ugovora, a najkasnije u roku od 14 dana od dana prijema obrasca/izjave o odustanku, a nakon prijema proizvoda.
						<br />
						<br />
						Troškove vraćanja robe i novca – direktni troškovi, snosi kupac, sem u slučajevima kada kupac dobije neispravan ili pogrešan artikal.
						<br />
						<br />U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, laykagift.com je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači
						da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice.
						<br />
						<br />
						<br />
					</p>
					<h1 className="title">POLITIKA PRIVATNOSTI</h1>
					<p className="paragraph font-semibold text-[20px]">Vaša porudžbina je potpuno sigurna!</p>
					<p className="paragraph">
						U kompaniji AIO PRO d.o.o. cenimo Vašu privatnost, zato o Vašim podacima izuzetno pažljivo brinemo.
						<br />
						<br />
						Sve posredovane informacije su sigurne, nećemo ih ni pod kojim uslovima ustupati trećem licu, što Vam osigurava potpuno sigurno i bezbedno poslovanje i saradnju sa kompanijom AIO PRO d.o.o.
					</p>
					<h2 className="subtitle">Zaštita privatnosti podataka</h2>
					<p className="paragraph">
						Da bismo uspešno obradili Vašu narudžbinu potrebni su nam Vaše ime i prezime, adresa, e-mail i telefon. Uz pomoć tih podataka bićemo u mogućnosti da Vam isporučimo željenu robu, kao i da Vas obavestimo o trenutnom statusu narudžbine.
						<br />
						<br />
						Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Prijava ili registracija na www.laykagift.com podrazumeva saglasnost
						korisnika da mu šaljemo email, sms obaveštenja. Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li žele ili ne da se izbrišu sa mailing, sms lista koje se koriste za marketinške kampanje.
						<br />
						<br />
						Kompanija AIO PRO d.o.o. će Vaše lične podatke koristiti isključivo u svrhe tehničke administracije web sajta, kako bi Vam dala pristup posebnim informacijama ili za komunikaciju sa Vama. AIO PRO d.o.o. neće prodati podatke koje poseduje trećim licima, niti na tržištu.
						Zaposleni u kompaniji AIO PRO d.o.o. su obavezni da poštuju poverljivost Vaših podataka.
						<br />
						<br />
						Nikada i ni na koji način nećemo zloupotrebiti Vaše privatne informacije. Nećemo predati Vaše podatke za kontakt trećim licima, sem ukoliko se to od nas bude zahtevalo zakonom.
						<br />
						<br />
						Pored navedenih prikupljamo, analiziramo i obrađujemo podatke o proizvodima koje naši posetioci traže i kupuju, kao i o stranicama koje posećuju. Te podatke koristimo da bismo poboljšali ponudu i izgled naših stranica, i omogućili Vam jednostavnije korišćenje, sigurniju i
						udobniju kupovinu.
					</p>
				</div>
			</div>
		</div>
	);
}

export default TermsOfUse;
