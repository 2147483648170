export const NoPaymentMethods = () => {
	return (
		<svg width="243" height="245" viewBox="0 0 243 245" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M88.0943 84.8314C114.221 79.33 135.519 69.0791 160.31 55.4111C166.508 77.3824 173.322 101.301 179.52 123.272C143.566 131.849 106.79 140.187 70.5625 148.695" stroke="black" stroke-width="1.15157" />
			<path
				d="M163.049 76.8021C164.316 99.5934 165.856 124.845 167.157 147.636C123.361 148.353 78.5731 148.456 34.7777 149.139C34.1614 127.476 33.5792 105.812 32.9629 84.1824C76.1077 86.7452 120.554 84.4558 163.151 77.2459L163.049 76.8021Z"
				fill="#F89DBD"
				stroke="black"
				stroke-width="1.15157"
			/>
			<path
				d="M96.4831 102.019C93.0589 103.557 89.532 105.128 86.7242 107.623C83.9164 110.117 81.8961 113.808 82.2727 117.532C82.8206 122.726 87.8884 126.519 93.0247 127.51C99.4279 128.74 106.55 126.314 110.385 121.017C114.22 115.721 114.015 107.76 109.529 103.044C105.044 98.3287 96.5174 97.987 92.0659 102.737"
				stroke="black"
				stroke-width="1.15157"
			/>
			<path d="M145.552 89.2052C145.689 103.796 145.826 118.386 145.963 132.977" stroke="black" stroke-width="1.15157" />
			<path d="M46.9717 94.6728V132.191" stroke="black" stroke-width="1.15157" />
			<path d="M208.488 202.342C206.091 172.648 205.817 143.228 208.488 113.568C163.768 110.766 120.281 114.081 75.9033 120.095V204.324C120.931 202.513 163.46 204.119 208.488 202.308V202.342Z" fill="#F1F1FE" stroke="black" stroke-width="1.15157" />
			<path
				d="M93.127 130.824C90.73 131.132 88.2989 132.157 86.8265 134.104C85.1828 136.257 85.0116 139.435 86.4156 141.758C87.8195 144.082 90.73 145.38 93.4009 144.902C96.2772 144.355 98.5714 141.895 99.496 139.127C101.482 133.113 99.2563 130.038 93.0927 130.824H93.127Z"
				fill="#F1F1FE"
				stroke="black"
				stroke-width="1.15157"
			/>
			<path d="M99.5648 129.833C100.592 128.569 102.167 127.783 103.811 127.68C106.756 127.51 109.529 129.799 110.248 132.635C110.967 135.471 109.803 138.615 107.612 140.562C105.42 142.51 102.304 143.228 99.3936 142.886" stroke="black" stroke-width="1.15157" />
			<path d="M92.9229 159.698L179.623 162.329L92.9229 159.698Z" fill="#F1F1FE" />
			<path d="M92.9229 159.698L179.623 162.329" stroke="black" stroke-width="1.15157" />
			<path d="M92.9229 176.372C126.651 174.767 160.277 171.93 193.799 167.83C193.731 167.454 193.662 167.078 193.594 166.702" fill="#F1F1FE" />
			<path d="M92.9229 176.372C126.651 174.767 160.277 171.93 193.799 167.83C193.731 167.454 193.662 167.078 193.594 166.702" stroke="black" stroke-width="1.15157" />
			<path d="M88.8809 189.22C101.79 190.006 114.733 189.698 127.608 188.366L88.8809 189.22Z" fill="#F1F1FE" />
			<path d="M88.8809 189.22C101.79 190.006 114.733 189.698 127.608 188.366" stroke="black" stroke-width="1.15157" />
			<path d="M165.035 126.997C173.15 125.801 181.505 126.245 189.484 128.261L165.035 126.997Z" fill="#F1F1FE" />
			<path d="M165.035 126.997C173.15 125.801 181.505 126.245 189.484 128.261" stroke="black" stroke-width="1.15157" />
			<path d="M46.2343 63.1585C48.4758 63.1585 50.2929 61.3414 50.2929 59.1C50.2929 56.8585 48.4758 55.0414 46.2343 55.0414C43.9929 55.0414 42.1758 56.8585 42.1758 59.1C42.1758 61.3414 43.9929 63.1585 46.2343 63.1585Z" stroke="black" stroke-width="1.15157" />
			<path
				d="M194.184 34.0535C199.192 32.4113 204.881 32.9391 209.491 35.4845C214.113 38.0299 217.597 42.546 218.887 47.6602C219.614 50.5341 219.567 53.8654 217.655 56.1293C215.743 58.3932 211.544 58.663 210.078 56.0824C208.471 53.2437 211.145 49.7951 214.019 48.235C220.13 44.9154 228.095 45.3494 233.819 49.2907C239.543 53.2437 242.769 60.5398 241.843 67.4253"
				stroke="black"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.34603 212.631C5.05278 207.364 7.02342 202.015 10.6714 198.203C14.3195 194.391 19.5862 192.185 24.853 192.256C27.8207 192.291 31.0229 193.194 32.7121 195.622C34.4129 198.05 33.5801 202.179 30.718 202.93C27.5509 203.751 24.9234 200.279 24.1492 197.1C22.5187 190.332 24.9703 182.755 30.2722 178.238C35.5624 173.722 43.445 172.479 49.8613 175.153"
				stroke="black"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M215.078 243.46C219.127 243.46 222.41 240.178 222.41 236.129C222.41 232.08 219.127 228.798 215.078 228.798C211.029 228.798 207.747 232.08 207.747 236.129C207.747 240.178 211.029 243.46 215.078 243.46Z" stroke="black" stroke-width="1.15157" />
			<path
				d="M228.062 237.044C228.743 237.431 229.494 237.912 229.634 238.686C229.775 239.437 229.259 240.188 228.579 240.551C227.898 240.915 227.124 240.974 226.362 240.997C217.893 241.325 209.33 238.839 202.35 234.029C201.869 233.701 201.342 233.255 201.365 232.669C201.4 231.895 202.315 231.531 203.078 231.39"
				stroke="black"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M62.8574 17.6337C49.5929 21.4013 48.2365 23.2299 48.1713 37.5043C44.3906 23.7358 42.6371 22.343 28.846 22.1078C42.1105 18.3401 43.4669 16.5115 43.532 2.23714C47.3128 16.0056 49.0663 17.3985 62.8574 17.6337Z"
				stroke="#F89DBD"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M229.596 25.0333C225.291 25.6667 224.799 26.1829 224.189 30.6403C223.579 26.1829 223.086 25.6667 218.781 25.0333C223.086 24.3999 223.579 23.8838 224.189 19.4264C224.799 23.8838 225.291 24.3999 229.596 25.0333Z"
				stroke="#21187F"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.815 179.364C7.51014 179.998 7.01748 180.514 6.40752 184.971C5.79756 180.514 5.3049 179.998 1 179.364C5.3049 178.731 5.79756 178.215 6.40752 173.757C7.01748 178.215 7.51014 178.731 11.815 179.364Z"
				stroke="#21187F"
				stroke-width="1.15157"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
