import React, { useEffect, useRef, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { getAllCategories, getAllProducts, getFeatured, getImage } from '../../server/server';
import HeaderImage from './HeaderImage';
import BottomIcons from './BottomIcons';
import CategoriesHome from './CategoriesHome';
import FAQHome from './FAQHome';
import ScrollingBanner from '../../common/ScrollingBanner';
import FooterBanner from '../../common/FooterBanner';
import ProductWidget from '../products/ProductWidget';
import SEO from '../seo/seo';

function Home() {
	const [searchParams, setSearchParams] = useSearchParams();
	const type = searchParams.get('type') ?? 'bar';
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// TODO: OVO NE RADI
		setTimeout(function () {
			window.focus();
			// window.scrollTo(0, 300);
		}, 2);
		setLoading(true);
		fetchProducts();
	}, [type]);

	const fetchProducts = async () => {
		const resp = await getFeatured();
		if (resp.status === 200) {
			setProducts(resp.data.items);
			setLoading(false);
		}
	};

	return (
		<div className=" w-full  flex flex-col ">
			<SEO />
			<HeaderImage />
			<ScrollingBanner />
			<div className="py-[120px] w-full flex items-center justify-center   bg-white">
				<div className="max-w-[1280px] w-full flex flex-col items-center">
					<h2 className="text-[46px] font-bold text-black leading-[54px] text-center ">Trending now</h2>
					<p className="text-[14px] font-normal text-black leading-[18px] text-center max-w-[716px] mt-5">
						Explore the trendiest <b>personalized products</b> and transform the ordinary into extraordinary, showcasing your style in the coolest way possible.
						<br />
						<br />
						Stay in vogue, stay personalized!
					</p>
					<div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-5 mt-9 px-[20px] xl:px-0">
						{products.slice(0, 4).map((product: any, index: number) => (
							<ProductWidget product={product} key={product.id} />
						))}
					</div>
					{/* <button className="w-full max-w-[235px] h-[40px] flex items-center justify-center rounded-[30px] bg-laykaDarkPurple  text-[16px] leading-5 text-white font-bold cursor-pointer mt-9 uppercase">Explore more</button> */}
				</div>
			</div>

			<FooterBanner />
			<ScrollingBanner text="DOWNLOAD THE APP" />
			<CategoriesHome />
			<BottomIcons />
			<ScrollingBanner text="BRING YOUR GIFTS TO LIFE" />
			<FAQHome />
		</div>
	);
}

export default Home;
