import React, { useEffect, useState } from 'react';
import { NoAddresses } from '../../SVG/NoAddressesSvg';
import { Address, BillingAddress, Customer } from '../orders/OrderInterfaces';
import { changeDefaultBillingAddress, changeDefaultShippingAddress, deleteAddress, getCustomerByUserId, getMyAddresses, setDefaultAddress } from '../../../../server/server';
import { useUser } from '../../../../auth/UseUser';
import { useNavigate } from 'react-router';

const Addresses = () => {
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [customer, setCustomer] = useState<any>();
	const { user, setUser, isSignedIn } = useUser();

	const navigate = useNavigate();

	const shippingAddresses = customer?.shippingAddresses || [];
	const billingAddresses = customer?.billingAddresses || [];

	useEffect(() => {
		fetchAddresses();
	}, [customer]);

	const fetchCustomer = async (id: string) => {
		const resp = await getCustomerByUserId();
		if (resp.status == 200) {
			setCustomer(resp.data);
		} else {
			alert(`Error fetching user information: ${resp.status}`);
		}
	};

	const fetchAddresses = async () => {
		const resp = await getMyAddresses();

		if (resp.status == 200) {
			setAddresses(resp.data);
		}
	};

	const handleEditClick = (addressId: string) => {
		navigate(`/profile/address?id=${addressId}`);
	};

	const handleSetAsDefault = async (addressType: 'billing' | 'shipping', addressId: any) => {
		if (addressId) {
			const resp = await setDefaultAddress({ id: addressId, isDefault: true });

			if (resp.status === 201) {
				alert('Address set as default successfully');

				fetchAddresses();
			} else {
				alert(`Error setting address as default ${resp.status}`);
			}
		}

		fetchAddresses();
	};

	const handleDeleteAddress = async (id: any) => {
		const confirmed = window.confirm('Are you sure you want to delete this address?');
		if (confirmed) {
			const resp = await deleteAddress(id);

			console.log(resp);

			if (resp.status === 200) {
				alert('Address deleted successfully');

				fetchAddresses();
			} else {
				alert(`Error deleting address ${resp.status}`);
			}
		}
	};
	return (
		<div className="w-full lg:pr-[40px]">
			{addresses.length > 0 ? (
				<div className="max-w-[630px]">
					<div>
						<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px]">Saved addresses</h4>
					</div>
					<div>
						{addresses.map((address, index) => (
							<div key={index} className="pl-[40px] py-[40px] mb-[20px] bg-white rounded-[12px] h-[366px]">
								<h4 className="font-semibold text-[20px] font-notoSans leading-[28px] tracking-normal text-left pb-[20px]">{billingAddresses.some((billingAddress: any) => billingAddress.id === address.id) ? 'Billing' : 'Shipping'} address</h4>
								<div className="flex mb-[10px] ">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">Name:</div>
									<div className=" text-[16px] leading-[24px] text-black">
										{address?.firstName} {address?.lastName}
									</div>
								</div>
								<div className="flex  mb-[10px]">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">Street:</div>
									<div className=" text-[16px] leading-[24px] text-black">{address?.street}</div>
								</div>
								<div className="flex  mb-[10px]">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">City:</div>
									<div className=" text-[16px] leading-[24px] text-black">{address?.city}</div>
								</div>
								<div className="flex  mb-[10px]">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">Country:</div>
									<div className=" text-[16px] leading-[24px] text-black">{address?.country?.name}</div>
								</div>
								<div className="flex  mb-[10px]">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">Zip Code:</div>
									<div className=" text-[16px] leading-[24px] text-black">{address?.zip}</div>
								</div>
								<div className="flex ">
									<div className="w-[167px] font-semibold text-[16px] leading-[24px] text-black">Phone:</div>
									<div className=" text-[16px] leading-[24px] text-black">{address?.phone}</div>
								</div>

								<div className="flex flex-row mt-[20px] space-x-[40px]">
									<div>
										<button className="text-laykaPurple border-r border-laykaLight pr-[40px]" onClick={() => handleEditClick(address?.id)}>
											Edit
										</button>
									</div>
									<div>
										<button className="text-laykaPurple  border-r border-laykaLight pr-[40px]" onClick={() => handleDeleteAddress(address.id)}>
											Delete
										</button>
									</div>
									<div>
										<button
											className={` ${address.isDefault ? 'text-laykaPink' : 'text-laykaPurple'}`}
											disabled={address.isDefault}
											onClick={() => handleSetAsDefault(billingAddresses.some((billingAddress: any) => billingAddress.id === address.id) ? 'billing' : 'shipping', address?.id)}
										>
											{address.isDefault ? 'Default' : 'Set as Default'}
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
					<div>
						<button className="w-[343px] h-[44px] mt-[10px] border rounded-[30px] text-laykaPurple text-base font-bold leading-5 text-center font-noto-sans" onClick={() => navigate('/profile/address')}>
							ADD NEW ADDRESS
						</button>
					</div>
				</div>
			) : (
				<div>
					<div className="flex flex-col items-center">
						<NoAddresses />
						<h4 className="font-bold text-3xl font-notoSans leading-9 tracking-normal text-center max-w-[429px] mb-4">There are no addresses yet...</h4>
						<p className="max-w-[429px] mb-[30px] text-[14px] leading-[24px] font-notoSans text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </p>
						<button className="w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-4" onClick={() => navigate('/profile/address')}>
							ADD ADDRESS
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Addresses;
