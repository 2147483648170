import { TextField } from '@mui/material';
import IosSwitchMaterialUi from 'ios-switch-material-ui';
import React, { useState } from 'react';

const AddPaymentMethod = () => {
	const [knobPosition, setKnobPosition] = useState(false);

	const handleKnobChange = (knobOnLeft: boolean) => {
		setKnobPosition(!knobOnLeft);
	};
	return (
		<div>
			<div>
				<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px] w-[522px]">Add card</h4>
			</div>
			<div>
				<div className="mb-[20px]">
					<TextField className="w-[522px]" type="text" label="Name on card *" variant="standard"></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField className="w-[522px]" type="text" label="Card number *" variant="standard"></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField className="w-[522px]" type="text" label="Exp. Date *" variant="standard"></TextField>
				</div>
				<div className="mb-[20px]">
					<TextField className="w-[522px]" type="text" label="CVV Code*" variant="standard"></TextField>
				</div>
				<div className="mb-[20px] flex flex-row">
					<IosSwitchMaterialUi colorKnobOnLeft="white" colorKnobOnRight="white" knobSize={25} knobOnLeft={!knobPosition} colorSwitch={knobPosition ? '#21187f' : '#e5ddf6'} onChange={handleKnobChange} />
					<div className="ml-[5px] mt-[3px]">
						<span className="text-[16px] font-normal leading-6 text-center font-notoSans">Set as default payment method</span>
					</div>
				</div>
				<div>
					<button className="w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-[20px]">SAVE CARD</button>
				</div>
			</div>
		</div>
	);
};

export default AddPaymentMethod;
