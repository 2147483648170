import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from './Header';
import ConfigurationSideBar from './ConfigurationSideBar';
import ConfiguratorBody from './ConfiguratorBody';
import { useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import { DEFAULT_COLOR, DEFAULT_TEXT_CONFIG, DELETE_IMAGE_STRING, FAKE_RESTRICTION_AREA, FONTS, HideControls, INITIAL_SCALE_FACTOR, LINE_CONFIGURATION, RESTRICTION_AREA_BORDER, TEXT_COLORS, VIRTUAL_CANVAS_SIZE, getCurrentPrice } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { createDraft, createOrder, getDraftsById, getImage, getProductInfo } from '../../server/server';
import { useUser } from '../../auth/UseUser';
import { calculateAngleBetweenTwoVectors, findCloser, findEdgePoint, getEdgeDots, getIntersectionPointsWithHorizontal, getIntersectionPointsWithVertical } from '../../util/util';

const Configurator = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { isSignedIn } = useUser();
	const productId = searchParams.get('id') ?? '';
	const o1 = searchParams.get('o1') ?? '';
	const o2 = searchParams.get('o2') ?? '';
	const draftId = searchParams.get('draftId') ?? '';
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const { editor, onReady } = useFabricJSEditor();
	const canvasContainerRef = useRef<HTMLDivElement | null>(null);
	const [selectedObject, setSelectedObject] = useState<any>(null);
	const [previousSelectedObject, setPreviousSelectedObject] = useState<any>(null);
	const [init, setInit] = useState(false);
	const [productInfo, setProductInfo] = useState<any>({});
	const [loading, setLoading] = useState(true);
	const [orderProgress, setOrderProgress] = useState(false);
	const [draftProgress, setDraftProgress] = useState(false);
	const [textAlignment, setTextAlignment] = useState('');

	const product = useMemo(() => (loading ? {} : productInfo?.product), [loading, productInfo]);
	const productPriceString = useMemo(() => `${getCurrentPrice(product?.commercialOptions)}${product?.zone?.currency?.symbol}`, [product]);
	const productImage = useMemo(() => getImage(product?.restrictedImageId), [product]);
	const virtualRestrictionArea = useMemo(() => (loading ? FAKE_RESTRICTION_AREA : product?.restricted), [product, loading]);
	const [distance, setDistance] = useState({ leftDistance: 0, rightDistance: 0 });
	const { leftDistance, rightDistance } = distance;

	const [area, setArea] = useState({
		left: 0,
		top: 0,
		width: 0,
		height: 0,
	});

	const computeScaleFactor = (imgHeight: any) => {
		if (imgHeight === 0) {
			console.error('Image height is zero, cannot compute scale factor.');
			return 1;
		}
		const containerHeight = (canvasContainerRef?.current?.clientHeight || 0) / imgHeight;

		return containerHeight;
	};

	// const [scaleFactor, setScaleFactor] = useState(1);
	const scaleFactorRef = useRef(1);

	const getRestrictionMargin = () => {
		// Calculate the real restriction area based on scaleFactor
		const scale = scaleFactorRef.current;

		const realRestrictionArea = virtualRestrictionArea.map((val: any) => val * scale);

		// Compute the area based on the real restriction area
		const computedArea = {
			left: realRestrictionArea[0],
			top: realRestrictionArea[1],
			width: realRestrictionArea[2],
			height: realRestrictionArea[3],
		};

		// Return the margins
		return {
			left: computedArea.left + RESTRICTION_AREA_BORDER,
			right: computedArea.left + computedArea.width - RESTRICTION_AREA_BORDER,
			top: computedArea.top + RESTRICTION_AREA_BORDER,
			bottom: computedArea.top + computedArea.height - RESTRICTION_AREA_BORDER,
			width: computedArea.width,
			height: computedArea.height,
		};
	};
	const imgElement = useRef(document.createElement('img')).current;

	// useEffect(() => {
	// 	setTriggerRecalculation((prev) => prev + 1);
	// }, [scaleFactorRef.current]);
	useEffect(() => {
		if (!!loading) {
			getProduct();
		}
	}, [loading]);

	const getDraft = async () => {
		if (draftId != '') {
			const resp = await getDraftsById(draftId);
			if (resp.status === 200) {
				const data: any = resp.data;
				fabric?.util?.enlivenObjects(
					data.design,
					(objs: any) => {
						objs.forEach((item: any) => {
							const obj = new fabric.Object(item);

							// Correctly apply the scale factor
							const scaleFactor = scaleFactorRef.current;
							obj.set({
								scaleX: (obj.scaleX || 1) * scaleFactor,
								scaleY: (obj.scaleY || 1) * scaleFactor,
								left: (obj.left || 1) * scaleFactor,
								top: (obj.top || 1) * scaleFactor,
							});

							// Additional settings for text objects
							if (item.type === 'i-text') {
								obj.set({ ...DEFAULT_TEXT_CONFIG, fill: item.fill, lockScalingFlip: true });
								obj.setControlsVisibility(HideControls);
								obj.setControlVisible('deleteControl', true);
							}

							editor?.canvas?.add(obj);
						});
						editor?.canvas?.requestRenderAll();
					},
					''
				);
			}
		}
	};

	const getProduct = async () => {
		const startTime = Date.now();
		const resp = await getProductInfo(o1, o2);
		if (resp.status === 200) {
			setProductInfo(resp.data);
			const endTime = Date.now();
			const dif = 500 - (endTime - startTime);
			const timeoutTime = Math.max(dif, 0);
			setTimeout(() => setLoading(false), timeoutTime);
		}
	};

	const drawRestrictionArea = (initScale: any) => {
		const [left, top, width, height] = virtualRestrictionArea?.map((val: any) => val * initScale);
		editor?.canvas.add(
			new fabric.Rect({
				top: top,
				left: left,
				height,
				width,
				fill: 'transparent',
				...LINE_CONFIGURATION,
			})
		);
	};

	const restrictMovement = (obj: any, accurate = true) => {
		const { top, left, width, height } = obj.getBoundingRect(accurate, accurate);
		const leftDistance = Math.max((obj?.left ?? 0) - left, 0);
		const topDistance = Math.max((obj?.top ?? 0) - top, 0);
		if (left < getRestrictionMargin().left) obj.set('left', getRestrictionMargin().left + leftDistance);
		if (getRestrictionMargin().right < left + width) obj?.set('left', getRestrictionMargin().right - (width - leftDistance));
		if (top < getRestrictionMargin().top) obj?.set('top', getRestrictionMargin().top + topDistance);
		if (top + height > getRestrictionMargin().bottom) obj?.set('top', getRestrictionMargin().bottom - height + topDistance);
	};

	const restrictScaling = (obj: any) => {
		console.log('Scaling...');

		const { top, left, width, height } = obj.getBoundingRect(false, true);
		const smallerWidth = getRestrictionMargin().width - RESTRICTION_AREA_BORDER;
		const smallerHeight = getRestrictionMargin().height - RESTRICTION_AREA_BORDER;

		if (smallerWidth < width || smallerHeight < height) {
			const scaleX = ((smallerWidth / width) * obj.getScaledWidth()) / obj.width;
			const scaleY = ((smallerHeight / height) * obj.getScaledHeight()) / obj.height;
			const scale = Math.min(scaleX, scaleY);

			obj?.set({ scaleX: scale, scaleY: scale });
		}
		restrictMovement(obj);
	};

	const restrictRotating = (obj: fabric.Object) => {
		const { top, left, width, height } = obj.getBoundingRect(true, true);
		const { x, y } = obj.getCenterPoint();
		const [tl, tr, br, bl] = obj.getCoords(true, true);

		const objH: any = obj.getScaledHeight();
		const objW: any = obj.getScaledWidth();
		const r = Math.sqrt(objH * objH + objW * objW) / 2;

		let deltaAngle = 0;
		const objAngle = obj?.angle ?? 0;

		if (left < getRestrictionMargin().left || left + width > getRestrictionMargin().right || top < getRestrictionMargin().top || top + height > getRestrictionMargin().bottom) {
			if (left < getRestrictionMargin().left) {
				const { point1, point2 } = getIntersectionPointsWithVertical({ x, y }, getRestrictionMargin().left, r);
				const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'x', 'lower');
				const intersection = findCloser(objEdgePoint, point1, point2, 'y');
				const intersectionPoint = { x: getRestrictionMargin().left, y: intersection.y };
				deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
				if (intersection === point2) deltaAngle *= -1;
			} else if (left + width > getRestrictionMargin().right) {
				const { point1, point2 } = getIntersectionPointsWithVertical({ x, y }, getRestrictionMargin().right, r);
				const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'x', 'higher');
				const intersection = findCloser(objEdgePoint, point1, point2, 'y');
				const intersectionPoint = { x: getRestrictionMargin().right, y: intersection.y };
				deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
				if (intersection === point1) deltaAngle *= -1;
			} else if (top < getRestrictionMargin().top) {
				const { point1, point2 } = getIntersectionPointsWithHorizontal({ x, y }, getRestrictionMargin().top, r);
				const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'y', 'lower');
				const intersection = findCloser(objEdgePoint, point1, point2, 'x');
				const intersectionPoint = { y: getRestrictionMargin().top, x: intersection.x };
				deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
				if (intersection === point1) deltaAngle *= -1;
			} else if (top + height > getRestrictionMargin().bottom) {
				const { point1, point2 } = getIntersectionPointsWithHorizontal({ x, y }, getRestrictionMargin().bottom, r);
				const objEdgePoint = findEdgePoint([tl, tr, br, bl], 'y', 'higher');
				const intersection = findCloser(objEdgePoint, point1, point2, 'x');
				const intersectionPoint = { y: getRestrictionMargin().bottom, x: intersection.x };
				deltaAngle = calculateAngleBetweenTwoVectors({ x, y }, intersectionPoint, { x: objEdgePoint.x, y: objEdgePoint.y });
				if (intersection === point2) deltaAngle *= -1;
			}
			obj?.rotate(objAngle - deltaAngle);
		}
	};

	const deleteObject = (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => {
		const target = transformData.target;
		const canvas = target?.canvas;
		if (canvas && target) {
			canvas?.remove(target);
			canvas?.requestRenderAll();
			return true;
		}
		return false;
	};

	const renderIcon = () => {
		const img = new Image();
		img.src = DELETE_IMAGE_STRING;

		return (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) => {
			const size = 24;
			if (img.complete && size > 0) {
				ctx.save();
				ctx.translate(left, top);
				ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as any));
				ctx.drawImage(img, -size / 2, -size / 2, size, size);
				ctx.restore();
			}
		};
	};

	useEffect(() => {
		if (!loading) {
			fabric.Object.prototype.controls.deleteControl = new fabric.Control({
				x: -0.5,
				y: -0.5,
				offsetY: -0,
				offsetX: -0,
				cursorStyle: 'pointer',
				mouseUpHandler: deleteObject,
				render: renderIcon(),
			});
			setSelectedObject(editor?.canvas?.getActiveObject());
			setPreviousSelectedObject(editor?.canvas?.getActiveObject());
			if (!init) {
				imgElement.onload = () => {
					try {
						const fabricImage = new fabric.Image(imgElement);
						const imageHeight = fabricImage?.height ?? 1;
						const initScale = computeScaleFactor(imageHeight);
						scaleFactorRef.current = initScale;
						fabricImage?.scale(initScale);
						//editor?.canvas?.setZoom(initScale);
						editor?.canvas?.setWidth(fabricImage?.getScaledWidth());
						editor?.canvas?.setHeight(fabricImage?.getScaledHeight());
						editor?.canvas?.setBackgroundImage(fabricImage, editor?.canvas?.requestRenderAll.bind(editor?.canvas), {});
						drawRestrictionArea(initScale);
						getDraft();
					} catch (e) {}
				};
				imgElement.src = productImage; //require('../../assets/images/krigla.png');
			}

			if (!init && !!editor) {
				editor?.canvas?.on('object:moving', (event) => {
					setTextAlignment('');
					const obj = event.target;
					if (!obj) return;
					if (obj?.name === 'imageInClippingArea') return;
					restrictMovement(obj);
					editor?.canvas?.requestRenderAll();
				});
				editor?.canvas?.on('object:scaling', (event) => {
					const obj = event.target;
					if (!obj) return;
					if (obj?.name === 'imageInClippingArea') return;
					restrictScaling(obj);
					editor?.canvas?.requestRenderAll();
				});
				editor?.canvas?.on('object:resizing', (event) => {
					const obj = event.target;
					if (!obj) return;
					if (obj?.name === 'imageInClippingArea') return;
					restrictScaling(obj);
					editor?.canvas?.requestRenderAll();
				});
				editor?.canvas?.on('object:rotating', (event) => {
					const obj = event.target;
					if (!obj) return;
					if (obj?.name === 'imageInClippingArea') return;
					restrictRotating(obj);
					editor?.canvas?.requestRenderAll();
				});

				setInit(true);
			}
		}
	}, [editor, computeScaleFactor, loading]);

	const onImageUpload = (event: any) => {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		const reader = new FileReader();
		reader.onload = (event) => {
			addImage(event?.target?.result as string);
		};
		reader.readAsDataURL(event.target.files[0]);
	};

	const addSymbol = ({ symbol = '' }) => {
		fabric.Image.fromURL(symbol, (img) => {
			img?.set({ left: getRestrictionMargin().left + 50, top: getRestrictionMargin().top + 50, scaleX: 0.5, scaleY: 0.5, lockScalingFlip: true });
			editor?.canvas?.add(img);
			editor?.canvas?.requestRenderAll();
		});
	};

	const addImage = (image: string) => {
		const imgElement = new Image();
		imgElement.src = image;

		imgElement.onload = () => {
			const fabricImage = new fabric.Image(imgElement);

			// Calculate the maximum scale of the image to fit within the restricted area
			const maxScaleX = (getRestrictionMargin().width - 10) / (fabricImage.width ?? 1);
			const maxScaleY = (getRestrictionMargin().height - 10) / (fabricImage.height ?? 1);

			const scale = Math.min(maxScaleX, maxScaleY);
			fabricImage?.set({
				left: getRestrictionMargin().left + 5,
				top: getRestrictionMargin().top + 5,
				scaleX: scale,
				scaleY: scale,
				lockScalingFlip: true,
			});

			editor?.canvas?.add(fabricImage);
			editor?.canvas?.requestRenderAll();
		};
	};

	const onAddText = ({ text, fontFamily = FONTS[0] }: { text: string; fontFamily?: string }) => {
		const textComponent = new fabric.IText(text, {
			left: getRestrictionMargin().left + 50,
			top: getRestrictionMargin().top + 50,
			...DEFAULT_TEXT_CONFIG,
		});

		textComponent.set({ fontFamily });
		textComponent.setControlsVisibility(HideControls);
		textComponent.setControlVisible('deleteControl', true);

		editor?.canvas?.add(textComponent);
		editor?.canvas?.setActiveObject(textComponent);
		editor?.canvas?.requestRenderAll();
	};

	const fitText = () => {
		const obj = editor?.canvas?.getActiveObject();
		const [restrictionLeft, restrictionTop, restrictionWidth, restrictionHeight] = virtualRestrictionArea?.map((val: any) => val * scaleFactorRef.current);
		const correctedLeftRestriction = restrictionLeft + RESTRICTION_AREA_BORDER;
		const objLeft = obj?.left ?? 0;
		const objWidth = obj?.getScaledWidth() ?? 0;
		const objRightMargin = objLeft + objWidth;
		const restrictionAreaRightMargin = correctedLeftRestriction + restrictionWidth - RESTRICTION_AREA_BORDER;
		if (objRightMargin > restrictionAreaRightMargin) {
			const leftSpaceOnLeft = objLeft - correctedLeftRestriction;
			if (leftSpaceOnLeft < RESTRICTION_AREA_BORDER) {
				const fontSize = selectedObject.fontSize;
				const newFontSize = fontSize - 1;
				selectedObject.set({ fontSize: newFontSize });
				const newSelectedObject = new fabric.Object(selectedObject);
				setSelectedObject(newSelectedObject);
				setPreviousSelectedObject(newSelectedObject);
				fitText();
			} else {
				const dif = objRightMargin - restrictionAreaRightMargin;
				obj?.set('left', objLeft - dif);
				fitText();
			}
		} else if (objLeft < correctedLeftRestriction) {
			const fontSize = selectedObject.fontSize;
			const newFontSize = fontSize - 1;
			selectedObject.set({ fontSize: newFontSize });
			obj?.set('left', correctedLeftRestriction);
			const newSelectedObject = new fabric.Object(selectedObject);
			setSelectedObject(newSelectedObject);
			fitText();
		} else {
		}
	};

	const alignTextLeft = () => {
		const [restrictionLeft, restrictionTop, restrictionWidth, restrictionHeight] = virtualRestrictionArea?.map((val: any) => val * scaleFactorRef.current);
		selectedObject.set({ left: restrictionLeft + RESTRICTION_AREA_BORDER });
		editor?.canvas?.requestRenderAll();
	};
	const alignTextCenter = () => {
		const [restrictionLeft, restrictionTop, restrictionWidth, restrictionHeight] = virtualRestrictionArea?.map((val: any) => val * scaleFactorRef.current);

		selectedObject.set({ left: restrictionLeft - RESTRICTION_AREA_BORDER * 2 + restrictionWidth / 2 - selectedObject.getScaledWidth() / 2 });
		editor?.canvas?.requestRenderAll();
	};
	const alignTextRight = () => {
		const [restrictionLeft, restrictionTop, restrictionWidth, restrictionHeight] = virtualRestrictionArea?.map((val: any) => val * scaleFactorRef.current);
		const rightMargin = restrictionLeft - RESTRICTION_AREA_BORDER * 2 + restrictionWidth;
		selectedObject.set({ left: rightMargin - selectedObject.getScaledWidth() });
		editor?.canvas?.requestRenderAll();
	};

	const alignText = (type: string) => {
		setTextAlignment(type);
		switch (type) {
			case 'left':
				alignTextLeft();
				break;
			case 'center':
				alignTextCenter();
				break;
			case 'right':
				alignTextRight();
				break;
		}
	};

	const makeOrder = async () => {
		if (!isSignedIn) {
			alert('You are not signed in!');
			return;
		}
		setOrderProgress(true);

		const scaleFactor = scaleFactorRef.current; // Assuming this is the scale factor used for scaling

		const normalizedObjects = editor?.canvas
			?.getObjects()
			?.filter((e) => e.name !== 'restriction_area')
			?.map((e: any) => {
				// Normalize the properties based on the scale factor
				const normalizedObject = {
					...e.toObject(),
					scaleX: e.scaleX / scaleFactor,
					scaleY: e.scaleY / scaleFactor,
					left: e.left / scaleFactor,
					top: e.top / scaleFactor,
					totalWidth: (e.width * e.scaleX) / scaleFactor,
					totalHeight: (e.height * e.scaleY) / scaleFactor,
				};
				return normalizedObject;
			});

		const data = {
			commercialOptionId: product.id,
			design: normalizedObjects,
		};

		const resp = await createOrder(data);
		if (resp.status == 201) {
			setOrderProgress(false);
			alert('Order is made');
		}
	};

	const saveDraft = async () => {
		if (!isSignedIn) {
			alert('You are not signed in!');
			return;
		}
		setDraftProgress(true);

		const scaleFactor = scaleFactorRef.current; // Assuming this is the scale factor used for scaling

		const normalizedObjects = editor?.canvas
			?.getObjects()
			?.filter((e) => e.name !== 'restriction_area')
			?.map((e: any) => {
				// Normalize the properties based on the scale factor
				const normalizedObject = {
					...e.toObject(),
					scaleX: e.scaleX / scaleFactor,
					scaleY: e.scaleY / scaleFactor,
					left: e.left / scaleFactor,
					top: e.top / scaleFactor,
					totalWidth: (e.width * e.scaleX) / scaleFactor,
					totalHeight: (e.height * e.scaleY) / scaleFactor,
				};
				return normalizedObject;
			});

		const data = {
			productId: product.id,
			design: normalizedObjects,
		};

		const resp = await createDraft(data);

		if (resp.status == 201) {
			setDraftProgress(false);
			alert('Draft is made');
		}
	};

	const removeObject = (object: any) => {
		editor?.canvas?.remove(selectedObject);
		editor?.canvas?.requestRenderAll();
	};
	const removeSelectedObject = () => {
		const obj: any = editor?.canvas?.getActiveObject();
		editor?.canvas?.remove(obj);
		editor?.canvas?.requestRenderAll();
	};
	const bringBackward = () => {
		editor?.canvas.getActiveObject()?.sendBackwards();
		editor?.canvas?.requestRenderAll();
	};
	const bringToBack = () => {
		console.log('Back');
		editor?.canvas.getActiveObject()?.sendToBack();
		editor?.canvas?.requestRenderAll();
	};
	const bringForward = () => {
		console.log('Forward');
		editor?.canvas.getActiveObject()?.bringForward();
		editor?.canvas?.requestRenderAll();
	};
	const bringToFront = () => {
		console.log('Front');
		editor?.canvas.getActiveObject()?.bringToFront();
		editor?.canvas?.requestRenderAll();
	};

	document.onkeydown = function (e) {
		if (selectedObject)
			switch (e.key) {
				case 'Backspace': // delete
					if (selectedObject.type !== 'i-text') {
						editor?.canvas?.remove(selectedObject);
						editor?.canvas?.requestRenderAll();
					}
			}
	};

	const renderAll = () => editor?.canvas?.requestRenderAll();

	const functions = {
		onAddText,
		fitText,
		addSymbol,
		alignText,
		addImage,
		removeObject,
		removeSelectedObject,
	};

	return (
		<div className="h-[100vh] flex flex-col">
			<Header product={productInfo} price={productPriceString} orderProgress={orderProgress} downloadPDF={makeOrder} loading={loading} disabled={loading || orderProgress} draftProgress={draftProgress} saveDraft={saveDraft} />
			<div className="flex flex-row flex-1" ref={canvasContainerRef}>
				<ConfigurationSideBar functions={functions} selectedObject={selectedObject} renderAll={renderAll} textAlignment={textAlignment} />
				<ConfiguratorBody onReady={onReady} loading={loading} bringBackward={bringBackward} bringToBack={bringToBack} bringForward={bringForward} bringToFront={bringToFront} showAdditionalButtons={!!selectedObject} />
			</div>
			<input type="file" accept="image/*" ref={fileInputRef} onChange={onImageUpload} className="hidden" />
		</div>
	);
};

export default Configurator;
