import { useRef, useEffect, useState, useMemo } from 'react';
import TechnicalSpecs from './TechnicalSpecs';
import FooterBanner from '../../common/FooterBanner';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { getProductById, getProductByOptions } from '../../server/server';
import ScrollingBanner from '../../common/ScrollingBanner';
import ProductConfiguration from '../configurator/ProductConfiguration';
import Loading from '../../common/Loading';
import { useCart } from '../../hooks/useCart';
import ExploreSingleProduct from './ExploreSingleProduct';
import AddedToCartModal from '../configurator/AddedToCartModal';
import SEO from '../seo/seo';

const SingleProduct = () => {
	const { addToCart, updateCart, cart } = useCart();
	const navigate = useNavigate();
	const containerRef = useRef<any>(null);
	const productConfigurationRef = useRef<any>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [orderProgress, setOrderInProcess] = useState(false);
	const [showCartModal, setShowCartModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const standAlone = !!searchParams.get('standalone');
	const draftId = searchParams.get('draftId') ?? '';
	const editCart = searchParams.get('editCart') ?? '';
	// const productId = searchParams.get('name') ?? '';
	const { productName } = useParams();
	const productId = productName?.split('--')[1];
	const urlSlug = productName?.split('--')[0];
	const productIds = productId?.split(',');

	const isDraft = !!draftId;
	const isEditCart = !!editCart;
	const cartIndex = isEditCart ? parseInt(editCart) : null;

	const cartElement = useMemo(() => (!!editCart ? cart[editCart] : null), [editCart]);
	const [state, setState] = useState(isDraft || isEditCart ? 'configure' : 'explore');

	const [productInfo, setProductInfo] = useState<any>({});
	const [groups, setGroups] = useState<any[]>([]);

	const isDigitalProduct = productInfo?.options?.allowPersonalization === 'Digital product';

	useEffect(() => {
		setLoading(true);
		document?.getElementById('scroll-div')?.scroll(0, 0);
		loadProduct();
	}, [productId]);

	const loadProduct = async () => {
		if (productId === '') navigate('/');
		else loadProductOptions(productIds);
	};

	const loadProductOptions = async (ids: any[] = []) => {
		const selectedAttribute = standAlone ? ids[0] : ids;
		const selectedFunction = standAlone ? getProductById : getProductByOptions;
		const resp = await selectedFunction(selectedAttribute);
		if (resp.status === 200) {
			console.log(resp.data);
			setProductInfo({ ...resp.data.product, urlSlug });
			if (!standAlone) setGroups(resp.data.groups);
			setLoading(false);
		}
	};

	const addItemToCart = async () => {
		setOrderInProcess(true);
		const order = await productConfigurationRef.current.addToCart();
		if (!isEditCart) addToCart(order);
		else updateCart(order, cartIndex);
		setOrderInProcess(false);
		// alert('Item has been added to cart');
		setShowCartModal(true);
	};

	const openCartModal = () => {
		setShowCartModal(true);
	};

	const configurationBack = () => {
		if (!isDraft && !isEditCart) setState('explore');
		else navigate(-1);
	};

	const exploreMoreProducts = () => {
		setShowCartModal(false);
		navigate('/');
		document?.getElementById('scroll-div')?.scroll(0, 0);
	};

	const goToCart = () => {
		document?.getElementById('scroll-div')?.scroll(0, 0);
		setShowCartModal(false);
		navigate('/cart');
	};

	return (
		<>
			<div className="mx-[auto] ">
				<div className="flex flex-col xl:gap-[20px]  md:flex-row relative items-start min-h-[506px]" ref={containerRef}>
					{state === 'explore' && (
						<ExploreSingleProduct openCartModal={openCartModal} isDigitalProduct={isDigitalProduct} groups={groups} loading={loading} images={productInfo.files ?? []} openConfiguration={() => setState('configure')} product={productInfo} productIds={productIds} standAlone={standAlone} />
					)}
					{state === 'configure' && (
						<ProductConfiguration ref={productConfigurationRef} standAlone={standAlone} goBack={configurationBack} productId={productIds} productInfo={productInfo} addItemToCart={addItemToCart} editCartMode={isEditCart} cartElement={cartElement} loading={loading} />
					)}
					{loading && <AbsoluteCenterLoading />}
				</div>
				<div className="mt-[90px] mb-[120px]">
					<TechnicalSpecs specs={productInfo?.commercialOptions?.specificationList} />
				</div>
			</div>
			<ScrollingBanner />
			<FooterBanner />
			<AddedToCartModal modalIsOpen={showCartModal} exploreProducts={exploreMoreProducts} goToCart={goToCart} />
		</>
	);
};

const AbsoluteCenterLoading = () => (
	<div className="absolute top-0 left-0 w-full h-full z-[2] bg-[#FFFFFF4D] flex items-center justify-center">
		<Loading />
	</div>
);

export default SingleProduct;
