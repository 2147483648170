import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR, DEFAULT_FONT_SIZE, DEFAULT_NEW_TEXT, FONTS, TEXT_COLORS } from '../../Constants';
import '../../assets/style/Sidebar.css';
import InputArea from '../../common/InputArea';
import { ReactComponent as TextLeft } from '../../assets/icons/textLeft.svg';
import { ReactComponent as TextCenter } from '../../assets/icons/textCenter.svg';
import { ReactComponent as TextRight } from '../../assets/icons/textRight.svg';

type AddTextType = {
	onAddText: ({ text }: { text: string }) => void;
	selectedObject?: any;
	renderAll?: () => void;
	fitText?: (value: any) => void;
	alignText?: (type: string) => void;
	alignedText?: string;
	removeObject?: (object: any) => void;
	removeSelectedObject?: () => void;
};

const AddText = ({ onAddText, selectedObject, renderAll = () => {}, fitText = () => {}, alignText = () => {}, alignedText = '', removeSelectedObject = () => {} }: AddTextType) => {
	const [text, setText] = useState<string>('');
	const [selectedFont, setSelectedFont] = useState(FONTS[0]);
	const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR);
	const [fontSize, setFontSize] = useState<any>(DEFAULT_FONT_SIZE);
	const [inputInFocus, setInputInFocus] = useState(false);

	useEffect(() => {
		if (selectedObject) {
			setText(selectedObject?.text ?? '');
			setSelectedFont(selectedObject?.fontFamily ?? FONTS[0]);
			setSelectedColor(selectedObject?.fill ?? TEXT_COLORS[0]);
			setFontSize(selectedObject?.fontSize ?? DEFAULT_FONT_SIZE);
		}
	}, [selectedObject]);

	const changeText = (text: string) => {
		selectedObject?.set({ text });
		setText(text);
		fitText(selectedObject);
		renderAll();
	};

	const changeFont = (fontFamily: string) => {
		selectedObject?.set({ fontFamily });
		setSelectedFont(fontFamily);
		fitText(selectedObject);
		renderAll();
	};

	const changeColor = (color: string) => {
		selectedObject?.set({ fill: color });
		setSelectedColor(color);
		renderAll();
	};
	const changeFontSize = (size: number) => {
		selectedObject?.set({ fontSize: size });
		fitText(selectedObject);
		setFontSize(size);
		renderAll();
	};

	document.onkeydown = function (e) {
		if (selectedObject)
			switch (e.key) {
				case 'Backspace': // delete
					if (!inputInFocus) removeSelectedObject();
			}
	};

	return (
		<div className="px-[13px]">
			<div className="mt-[40px]">
				<InputArea onTextChange={changeText} text={text} onFocus={() => setInputInFocus(true)} isError={text?.length === 0} onBlur={() => setInputInFocus(false)} />
			</div>
			<div className="border-b border-b-[#D4C4F6] my-[30px]" />
			<div className="">
				<label htmlFor="font">Font</label>
				<div className="flex flex-row flex-wrap gap-[12px] mt-[16px] mb-[40px]">
					{FONTS.map((font) => (
						<FontButton key={font} fontFamily={font} selected={selectedFont === font} onSelect={changeFont} />
					))}
				</div>
				<label htmlFor="text color">TEXT COLOR</label>
				<div className="flex flex-row flex-wrap gap-[10px] mt-[16px] mb-[40px] ">
					{TEXT_COLORS.map((color) => (
						<ColorButton color={color} selected={selectedColor === color} onSelect={changeColor} key={color} />
					))}
				</div>
				<div className="flex flex-row justify-between">
					<label htmlFor="font size">Font Size</label>
					<span>{fontSize}</span>
				</div>
				<div className="mb-[40px]">
					<input className="slider" type="range" min="10" max="70" value={fontSize} onChange={(e) => changeFontSize(parseInt(e.target.value))} />
				</div>
				<label htmlFor="text color">TEXT ALIGNMENT</label>
				<div className="flex flex-row gap-[20px] pt-[16px]">
					<button onClick={() => alignText('left')}>
						<TextLeft stroke={alignedText === 'left' ? '#0F2765' : '#D4C4F6'} />
					</button>
					<button onClick={() => alignText('center')}>
						<TextCenter stroke={alignedText === 'center' ? '#0F2765' : '#D4C4F6'} />
					</button>
					<button onClick={() => alignText('right')}>
						<TextRight stroke={alignedText === 'right' ? '#0F2765' : '#D4C4F6'} />
					</button>
				</div>
			</div>
		</div>
	);
};

const FontButton = ({ selected, fontFamily = FONTS[0], onSelect }: { selected?: boolean; fontFamily: string; onSelect: (font: string) => void }) => {
	return (
		<button className={`font-button ${selected ? 'selected' : ''}`} style={{ fontFamily }} onClick={() => onSelect(fontFamily)}>
			Aa
		</button>
	);
};

const ColorButton = ({ selected, color = TEXT_COLORS[0], onSelect }: { selected?: boolean; color: string; onSelect: (color: string) => void }) => {
	return (
		<button className={`color-button-ring ${selected ? 'selected' : ''}`} onClick={() => onSelect(color)}>
			<div
				className="color-button"
				style={{
					backgroundColor: color,
					borderColor: color === '#FFFFFF' ? '#ECECEC' : color,
				}}
			></div>
		</button>
	);
};

export default AddText;
