import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaLock, FaUserAlt, FaUserEdit, FaUserLock, FaUserPlus, FaUsers, FaUserTie } from 'react-icons/fa';
import { login, signUp, whoAmI } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import { ReactComponent as Grb } from '../../assets/svg/srbija-grb.svg';
import { dividerClasses } from '@mui/material';
const theme = createTheme({
	typography: { fontFamily: 'Fira Sans Condensed' },
	palette: {
		background: {
			default: '#f1f1fe',
		},
	},
});

export default function SignUp() {
	let navigate = useNavigate();
	const { setUser } = useUser();

	const [errorMessage, setErrorMessage] = React.useState('');
	const [datas, setDatas] = React.useState<any>(null);
	const [screen, setScreen] = React.useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const resp = await signUp({
			email: data.get('email') as string,
			password: data.get('password') as string,
			lastName: data.get('lastName') as string,
			firstName: data.get('firstName') as string,
		});
		if (resp?.status === 201) {
			const data = resp.data;
			await localStorage.setItem('accessToken', data['accessToken']);

			const response = await whoAmI();
			if (response.status === 200) {
				await localStorage.setItem('user', JSON.stringify({ ...response.data }));
				setUser(response.data);
			} else {
				console.log('who am i failed ', response.status);
			}
		} else {
			setErrorMessage(resp?.data?.message);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 22,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						background: '#f1f1fe',
					}}
				>
					<div className="flex"></div>

					<div>
						<h1 className="text-2xl text-[#f89dbd] text-center font-bold">Sign Up</h1>

						<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
							<TextField margin="normal" required fullWidth id="firstName" label="First name" name="firstName" autoComplete="firstName" autoFocus />
							<TextField margin="normal" required fullWidth id="lastName" label="Last name" name="lastName" autoComplete="lastName" />
							<TextField margin="normal" required fullWidth id="email" label="Email" name="email" autoComplete="email" />
							<TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />

							<Typography component="p" sx={{ color: 'error.main' }}>
								{errorMessage}
							</Typography>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									backgroundColor: '#21187f',
								}}
							>
								Sign Up
							</Button>
							<button onClick={() => navigate('/login', { replace: true })}>Already have the account</button>
						</Box>
					</div>
				</Box>
			</Container>
		</ThemeProvider>
	);
}
