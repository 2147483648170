import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import NavBar from '../NavBar';
import Footer from '../Footer';
import CountryScreen from './CountryScreen';
import { useAppContext } from '../../hooks/useAppContext';

function Layout() {
	let navigate = useNavigate();

	const { zone } = useAppContext();
	if (!zone?.id) return <CountryScreen />;
	return (
		<div className="home h-screen overflow-y-auto flex flex-col" id="scroll-div">
			<div className="home-container w-full flex-grow flex flex-col">
				<NavBar />
				<div className=" outlet-container ">
					<div className="w-full overflow-y-hidden flex-grow">
						<Outlet />
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Layout;
