import React from 'react';
import { ReactComponent as ChevronDown } from '../../assets/icons/ChevronDown.svg';

function FAQHome() {
	return (
		<div className="py-[120px] w-full flex items-center justify-center   bg-white px-[20px] xl:px-0">
			<div className="max-w-[1280px] grid grid-cols-5 gap-[73px]">
				<div className="col-span-5 md:col-span-2 flex flex-col gap-[30px]">
					<p className="text-[46px] font-bold text-black leading-[54px] ">You ask us this most often</p>
					<p className="text-[14px] font-normal text-black leading-[18px] ">Explore topics that ignite curiosity and address the questions we are most frequently asked, and we'll make every effort to provide answers to any uncertainties you may have.</p>
				</div>
				<div className="col-span-5 md:col-span-3 flex flex-col h-[394px] overflow-auto no-scroll ">
					{['Difference between our templates and make your own?', 'Can I add extra text box?', 'Can I combine text and photo?', 'Can I save my design for later?', 'How do I know if the photo is of the high enough quaility?', 'Can I use special characters?'].map(
						(item: string, index: number) => (
							<div className="w-full flex flex-row justify-between gap-5 pb-5 border-b border-b-[#D4C4F6] mb-5 cursor-pointer" key={index}>
								<p className="text-[20px] font-medium text-black leading-[28px]">{item}</p>
								<div>
									<ChevronDown />
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
}

export default FAQHome;
